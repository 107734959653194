/* eslint max-len: ["error", 500] */
/* eslint import/no-commonjs: [0] */
import adminRollouts from './adminRollouts/en';
import returnPolicies from './return_policies/en';
import payoutMethod from './payout_method/en';
import packlink from './packlink/en';
import billingMethod from './billing_method/en';
import cart from './cart/en';
import checkout from './checkout/en';
import creditCardWallet from './credit_card_wallet/en';
import conversations from './conversations/en';
import shopSettings from './shop_settings/en';
import productFamilyQuicklinks from './product_family/quicklinks_en';
import paymentMethodSelection from './payment_method_selection/en';
import expressSale from './express_sale/en';
import priceGuideTool from './price_guide_tool/en';
import shopCard from './shop_card/en';
import paymentMethodIcons from './payment_method_icons/en';
import klarnaCallout from './klarnaCallout/en';
import taxProfile from './tax_profile/en';
import taxonomy from './taxonomy/en';
import payStatement from './pay_statement/en';
import internationalTaxIds from './international_tax_ids/en';
import internationalTaxProfile from './international_tax_profile/en';
import productReviews from './product_reviews/en';
import addressSuggestion from './address_suggestion/en';
import listingsManagement from './listings_management/en';
import feedbackMessages from './feedback_messages/en';
import shopCoupons from './shop_coupons/en';
import accountDeletion from './accountDeletion/en';
import discover from './discover/en';

export default {
  adminRollouts,
  billingMethod,
  cart,
  checkout,
  creditCardWallet,
  conversations,
  expressSale,
  klarnaCallout,
  internationalTaxIds,
  internationalTaxProfile,
  feedbackMessages,
  packlink,
  paymentMethodIcons,
  paymentMethodSelection,
  payoutMethod,
  payStatement,
  priceGuideTool,
  productReviews,
  addressSuggestion,
  shopCard,
  shopSettings,
  shopCoupons,
  taxonomy,
  taxProfile,
  listingsManagement,
  accountDeletion,
  discover,
  wait: 'Please wait...',
  free: 'Free',
  auction: 'Live Auction',
  errorAltText: 'Error Illustration',
  grammar: {
    truncate: '{{text}}…',
  },
  auctions: {
    searchBar: {
      collection: {
        one: '{{num}} Auction',
        other: '{{num}} Auctions',
      },
    },
    filters: {
      userBidStatus: {
        all: 'All',
      },
    },
    empty: 'No Auctions Found',
  },
  auctionLpListingCard: {
    increaseBid: 'Increase Bid',
    soldBy: 'Seller Info',
    shipsFrom: 'ships from {{locality}}, {{region}}, {{countryCode}}',
    auctionStatus: {
      winning: "You're winning this auction!",
      losing: "You're being outbid!",
      won: 'You won this auction!',
      lost: 'You were outbid. Better luck next time!',
    },
    bidPriceText: {
      winning: 'Current Bid',
      losing: 'Current Bid',
      won: 'Your Winning Bid',
      lost: 'Winning Bid',
    },
    numberOfBids: {
      one: '{{num}} bid',
      other: '{{num}} bids',
    },
    endDate: {
      winning: 'Auction Ends In',
      losing: 'Auction Ends In',
      won: 'Auction Ended',
      lost: 'Auction Ended',
    },
    yourMaxBid: 'Your Max Bid',
    searchAuctions: 'Search auctions',
  },
  favorites: {
    title: 'Favorites',
    header: 'Favorites',
    seeMore: 'See More',
    saveSearchCallout: 'Save this search to be the first to know when new gear is added to Reverb.',
    confirmModalTitle: 'Add to your Favorites',
    confirmationModal: {
      updateTitle: 'Add to your Feed',
      subtitle: 'Would you also like to add this {{title}} search to your Feed?',
      submit: 'Save & Continue',
      save: 'Save',
      cancel: 'Cancel',
      remove: 'Remove',
      filters: 'Filters',
      noFilters: 'No Filters',
      savedSearchFeed: 'Saved Search Feed',
      addToFeed: 'Add this search to your Feed',
      addToDailyEmail: 'Add to my Daily Feed email',
      saveTitle: 'Save to Favorites',
      firstToKnowSubtitle: 'You\'ll be the first to know when new listings for {{title}} are added to Reverb.',
      addMatchingItemsToFeed: 'Add matching items to feed',
      addToYourDailyEmail: 'Add to your Daily Feed Email',
    },
    confirmationModalExp: {
      updateTitle: 'Add to your Feed',
      subtitle: 'Would you also like to add this {{title}} search to your Feed?',
      submit: 'Save & Continue',
      save: 'Done',
      cancel: 'Cancel',
      remove: 'Remove',
      filters: 'Here are the filters you used',
      noFilters: 'No Filters',
      savedSearchFeed: 'Never miss a deal',
      savedSearchFeedSubtitle: 'Your Feed keeps you up-to-date on everything you\'ve saved to your Favorites - including the latest price drops.',
      addToFeed: 'Add this search to your Feed',
      addToDailyEmail: 'Add to my Daily Feed email',
      saveTitle: 'Saved to your Favorites',
      firstToKnowSubtitle: 'Nice! As new listings for "{{title}}" are added to Reverb, you\'ll find them in the "searches" section of your Favorites.',
      addMatchingItemsToFeed: 'Add to your Feed',
      addToYourDailyEmail: 'Add to your daily Feed Email',
      newlyListedTitle: 'Be the first to know about newly listed gear',
      newlyListedSubtitle: 'Add this to your daily Feed email and we\'ll let you know when matches are listed on Reverb.',
      disclaimer: 'By adding to your daily Feed Email, you are opting-in to receive Feed emails from Reverb. To unsubscribe from these emails, go to your email preferences.',
    },
    banner: {
      saved: {
        brand: 'You\'ve Saved this Brand',
        category: 'You\'ve Saved this Category',
        shop: 'You\'ve Saved this Shop',
        collection: 'You\'ve Saved this Collection',
        product: 'You\'ve Saved this Product',
        search: 'You\'ve Saved this Search',
      },
      unsaved: 'Let the Gear Come to You',
    },
    deleteConfirmationModal: {
      title: 'Remove this {{favoriteType}}?',
      content: '{{title}} will be removed from your Favorites and your feed.',
      confirmButtonText: 'Remove',
      search: {
        title: 'Remove this search?',
      },
    },
    favoriteType: {
      search: 'Search',
    },
    watchlist: {
      share: 'Share',
      sortBy: 'Sort by',
      sort: 'Sort',
      sorts: {
        mostRecent: 'Recently Added',
        priceAsc: 'Price Low to High',
        priceDesc: 'Price High to Low',
      },
      header: 'Watch List',
      liveListings: 'Live Listings (%{total})',
      endedListings: 'Ended Listings (%{total})',
      used: 'Used – {{condition}}',
      live: 'Live (%{total})',
      ended: 'Ended (%{total})',
      shipping: '+ {{price}} Shipping',
      freeShipping: 'Free Shipping',
      freeExpeditedShipping: 'Free 2-Day Shipping',
      a11yPriceDescription: {
        withoutRibbon: 'Originally %{originalPrice}, now %{buyerPrice}',
        withRibbon: 'Originally %{originalPrice}, now %{buyerPrice} (%{ribbon})',
      },
      empty: {
        headerText: 'Your Watch List is empty',
        description: 'Keep track of your favorite gear here. You’ll get notified when it goes on sale.',
      },
    },
    shops: {
      header: 'Saved Shops',
      save: 'Save Shop',
      unsave: 'Unsave Shop',
      empty: {
        recommendedContentHeader: 'Recommended Shops',
        headerText: 'You have no saved shops',
        description: 'Save shops to come back to them any time (and see new gear they’ve added).',
      },
    },
    tabs: {
      watchlist: 'Watch List',
      searches: 'Searches',
      shops: 'Shops',
      feed: 'Feed',
      discover: 'Discover',
    },
    searches: {
      header: 'Saved Searches',
      yourFeed: 'Your Feed',
      viewSearch: 'View Search',
      seeMore: 'See More',
      unsave: 'Unsave Search',
      empty: {
        recommendedContentHeader: 'Trending Searches',
        headerText: 'You have no saved searches',
        description: 'Save your favorite searches to easily repeat them.',
      },
    },
    feed: {
      header: 'Your Feed',
      empty: {
        headerText: 'Welcome to your Feed, home for your favorite searches, shops, and listings',
        description: 'Save searches to your feed and we’ll notify you when new gear is added',
        recentSearchesCallout: 'Tap the heart on your recent searches to get started.',
        trendingSearchesCallout: 'Tap the heart on these trending searches to get started.',
        tooltip: 'Where did my Feed go?',
        tooltipHover: 'To keep your Feed fresh, old listings are hidden. If you have existing Searches, there may not be any matches (yet).',
      },
    },
    recommendedFavoriteRow: {
      empty: 'No searches found.',
    },
    favoriteButton: {
      saved: 'Saved',
      save: {
        brand: 'Save Brand',
        category: 'Save Category',
        collection: 'Save Collection',
        product: 'Save Search',
        search: 'Save Search',
        shop: 'Save Search',
      },
      tooltipText: 'Filter this search further to save it.',
    },
    cta: {
      saveShop: 'Save Shop',
      savedShop: 'Saved',
    },
    toast: {
      added: 'Added to Favorites',
      removed: 'Removed from Favorites',
      undo: 'Undo',
      error: 'Error occurred',
    },
    callouts: {
      educationBanner: {
        title: 'Introducing: Favorites',
        subtitle: 'Get price drops and special offers on items in your Watch List. Plus, explore your Feed, saved searches, and saved shops.',
      },
      customizeFeedEducation: {
        title: 'Customize your Feed',
        subtitle: 'Add or remove saved searches to see the gear you want in your Feed.',
      },
      saveShopEducation: {
        title: 'Save it for later',
        subtitle: 'Come back to this shop later in your Favorites.',
      },
      saveSearchEducation: {
        title: 'Save this search',
        subtitle: 'Add it to your Feed or pick up where you left off in your Favorites.',
      },
    },
    discover: {
      header: 'Discover',
    },
  },
  sellerDashboard: {
    header: 'Advertising Dashboard',
    cspHeaders: {
      listings: 'Live Listings',
      'listing-price': 'Lowest Listing Price',
      orders: 'Orders',
      'order-price': 'Average Order Price',
      'total-value': 'Total Order Value',
      impressions: 'Impressions',
      price: 'Price',
    },
    notAvailable: 'N/A',
    interactionsStats: {
      bumpInteractionsTitle: 'Bump Interactions',
      subtitle: 'This includes incremental listing engagement from buyers with Bump listings.',
      clicks: 'Listing Clicks',
      addToCart: 'Add-To-Cart',
      addToWatchlist: 'Add-to-Watchlist',
      offersMade: 'Offers Made',
    },
    bumpClicksTitle: 'Bump Clicks',
    revenueTitle: 'Sales of Bump Listings',
    revenueSubtitle: 'This is the amount earned from Bump listings that have sold across {{totalOrders}} orders.',
    adSpendTitle: 'Advertising Spend',
    adSpendSubtitle: 'This is the amount spent on Bump listings across {{totalOrders}} orders.',
    bumpSales: 'Total Bump Sales',
    adSpendTotal: 'Total Ad Spend',
    totalOrders: 'Total Orders',
    empty: {
      title: 'There is no data available during this time period.',
      message: 'Try searching during a different time period.',
    },
    listings: {
      title: 'Your Listings',
      productName: 'Listing Title',
      bumpBid: 'Bump Bid',
      recommendedBid: 'Recommended Bid',
      bumpImpressions: 'Bump Impressions',
      bumpClicks: 'Bump Clicks',
      bumpRevenue: 'Bump Revenue',
      totalInteractions: 'Total Interactions',
    },
    lockedModeBanner: {
      title: 'Locked Mode is enabled for your shop',
      description: 'We need a few more details to verify your business. Reverb Support will be in contact with you via email to unlock your account.',
      helpCenterLinkText: 'Visit our help center for more info',
    },
    informActBanner: {
      phase0: {
        title: 'Congrats! You\'re a high-volume seller',
        description: 'We\'ll need to collect and verify some information from you to comply with the INFORM Consumers Act. {{children}}',
      },
      phase1: {
        title: 'Important: Your account is at risk of being locked',
        description: 'Provide and verify your tax ID and bank account to complete your INFORM Consumers Act verification and continue selling on Reverb. {{children}}',
      },
      phase2: {
        title: 'Important: Your account is at risk of being locked in {{timeRemainingBeforeLock}}',
        description: 'Provide and verify your tax ID and bank account to continue selling on Reverb. {{children}}',
      },
      helpCenterLinkText: 'Learn more',
      buttonText: 'Start verification',
    },
  },
  featureAnnouncement: {
    header: 'Stay Informed. Grow Your Sales.',
    subHeader: 'The new Advertising Dashboard gives you more control than ever before over how Bump works for you. Check it out.',
  },
  datePicker: {
    cancel: 'Cancel',
    apply: 'Apply',
    dateRange: '{{startDate}} - {{endDate}}',
    dateRanges: {
      placeholder: 'Select A Date Range',
      custom: 'Custom',
      lastSevenDays: 'Last 7 Days',
      lastThirtyDays: 'Last 30 Days',
      lastNinetyDays: 'Last 90 Days',
      lastMonth: 'Last Month',
      lastYear: 'Last Year',
      weekToDate: 'Week To Date',
      monthToDate: 'Month To Date',
      quarterToDate: 'Quarter To Date',
      yearToDate: 'Year To Date',
      quarter: 'Quarter',
    },
  },
  dailySnapshot: {
    dailySnapshot: 'Bump Daily Snapshot',
    bumpInteractionsTitle: 'Bump Placement Interactions',
    bumpOrdersTitle: 'Bump Orders',
    bumpOrderTotalTitle: 'Bump Sales',
  },
  offers: {
    action: {
      create: 'Make an Offer',
      counter: 'Counter',
      accept: 'Accept',
      reject: 'Decline',
      acceptAndCheckOut: {
        button: 'Accept and Check Out',
        error: 'There was an error accepting the offer. Try again.',
      },
    },
    headerTitle: {
      create: 'Make an Offer',
      counter: 'Make a Counter Offer',
      accept: 'Accept Offer',
      reject: 'Decline Offer',
      review: 'Review',
    },
    submitButton: {
      counter: 'Send Counter',
      review: 'Review Details',
    },
    cancelButton: {
      back: 'Back',
      cancel: 'Cancel',
    },
    counterOptions: {
      title: 'Your Counter',
      standard: {
        label: 'Counter with a different price and 3 days to check out.',
      },
      conditional: {
        label: 'Counter with a {{hours}}-hour payment window at <strong>{{price}}</strong>.',
      },
    },
    buyerConditionalOfferAlert: {
      title: 'The seller accepted your price!',
      body: {
        one: 'Just one more thing: You need to agree to pay for the item within one hour.',
        other: 'Just one more thing: You need to agree to pay for the item within {{count}} hours.',
      },
    },
    confirmation: {
      modalHeader: {
        accepted: 'You accepted the offer',
        acceptedWithImmediatePayment: 'We\'re processing the order',
        countered: 'Your counter offer was submitted',
      },
      accepted: {
        standard: {
          seller: {
            header: 'What happens next',
            line1: 'We\'ve emailed the buyer. They have <strong>3 days</strong> to check out.',
            line2: 'You\'ll receive an order confirmation when they complete the purchase.',
          },
          buyer: {
            header: 'It\'s a deal',
            line1: 'This item was added to your cart. {{linkOpen}}Check out now{{linkClose}} to secure your deal.',
          },
        },
        immediatePayment: {
          seller: {
            header: 'It\'s a deal',
            line1: 'You\'ll receive an order confirmation email in a few minutes.',
            line2: '{{linkOpen}}Check out our shipping resources{{linkClose}}—including boxes, discounted labels, and tips for how to pack your gear.',
          },
        },
      },
      countered: {
        standard: {
          seller: {
            header: 'What happens next',
            line1: 'The buyer has <strong>24 hours</strong> to respond. We\'ll email you with updates.',
            line2: 'If the buyer accepts the counter offer, they\'ll have <strong>3 days</strong> to check out. You\'ll receive an order confirmation when they complete the purchase.',
          },
          buyer: {
            header: 'What happens next',
            line1: 'The seller has <strong>24 hours</strong> to respond. We\'ll email you with updates.',
            line2: 'If your Offer is accepted, we\'ll add it to your cart and you\'ll have <strong>3 days</strong> to check out.',
          },
        },
        immediatePayment: {
          buyer: {
            header: 'What happens next',
            line1: 'The seller has <strong>24 hours</strong> to respond. We\'ll email you with updates.',
            line2: 'If your Offer is accepted, the payment method you selected will be charged and we\'ll send you an order confirmation.',
          },
        },
        conditionalAcceptance: {
          seller: {
            header: 'What happens next',
            line1: {
              one: 'If the buyer accepts the counter offer, they\'ll have <strong>one hour</strong> to check out.',
              other: 'If the buyer accepts the counter offer, they\'ll have <strong>{{count}} hours</strong> to check out.',
            },
            line2: 'You\'ll receive an order confirmation when they complete the purchase.',
          },
        },
      },
      buttonText: 'Got it',
    },
    seller: 'seller',
    buyer: 'buyer',
    messageLabel: 'Message',
    messageToBuyerCheckboxLabel: 'Share a message with the buyer',
    messageToSellerCheckboxLabel: 'Share a message with the seller',
    optional: 'Optional',
    label: {
      adjustCounterOfferShippingPrice: 'Adjust shipping price',
    },
    counterOfferTotal: 'Total',
    cancel: 'Cancel',
    convertedFrom: 'Converted from {{price}} + {{shipping}} shipping',
    belowLowballThreshold: 'The seller doesn\'t accept offers this low.',
    currencyMayVary: 'What you pay may vary based on exchange rates, import duties, and taxes.',
    updateSubmissionSuccess: 'Updating...',
    error: "We're sorry, something went wrong. Please reload your browser window and try again.",
    summaryWithQuantity: 'For {{quantity}} items',
    buyerWillPayImmediately: '<strong>Get paid faster</strong> if you accept this offer. The buyer will be charged today.',
    summary: {
      counterOfferTotal: 'Your Counter Offer Total',
      showBreakdown: 'show breakdown',
      hideBreakdown: 'hide breakdown',
      subtotalTitle: 'Offer Subtotal',
      inventorySubtotal: '{{subtotal}} ({{itemPrice}} x {{quantity}})',
      quantity: 'Quantity',
      shippingCost: 'Shipping Cost',
      ofBuyerOffer: '{{openTag}}Buyer\'s Offer: {{offerAmount}}{{closeTag}} + {{shippingPrice}} shipping to {{shippingLocation}}',
      ofBuyerOfferWithoutShippingLocation: '{{openTag}}Buyer\'s Offer: {{offerAmount}}{{closeTag}} + {{shippingPrice}} shipping',
      ofBuyerOfferLocalPickupOnly: '{{openTag}}Buyer\'s Offer: {{offerAmount}}{{closeTag}} Local Pickup',
      ofSellerOffer: '{{openTag}}Seller\'s Offer: {{offerAmount}}{{closeTag}} + {{shippingPrice}} shipping to {{shippingLocation}}',
      ofSellerOfferWithoutShippingLocation: '{{openTag}}Seller\'s Offer: {{offerAmount}}{{closeTag}} + {{shippingPrice}} shipping',
      ofSellerOfferLocalPickupOnly: '{{openTag}}Seller\'s Offer: {{offerAmount}}{{closeTag}} Local Pickup',
    },
    toast: {
      rejected: 'You declined the offer.',
    },
    autoOffers: {
      autoOffersEnable: 'Enable Direct Offers',
      autoOffersDisable: 'Disable Direct Offers',
      createForm: {
        enable: 'Enable Direct Offers for this listing',
        message: 'Message To Buyer',
        price: 'Offer Price',
        priceWithTaxHint: 'Offer Price ({{hint}})',
        priceHelper: 'Set {{percentDiscount}}% off',
        shippingInfo: "Direct Offer pricing doesn't include shipping. The customer pays the shipping cost you set for their region",
        saleInfo: "In the event that this listing's price falls below the Direct Offer price, Direct Offers will no longer be sent",
        submit: 'Submit',
        save: 'Save',
        cancel: 'Cancel',
        loading: 'Loading...',
        alertTitle: 'Heads Up',
        alertCopy: 'Save your changes to disable Direct Offers for this listing',
        invalidAmountError: 'Please enter a valid offer amount',
        submitFailedMessage: 'Something went wrong. Please try again.',
        percentageLabel: 'Percent Off',
        calculatedPrice: '{{tagOpen}}Direct Offer Price:{{tagClose}} {{currencySymbol}}{{price}}',
        calculatedPriceWithTaxHint: '{{tagOpen}}Direct Offer Price:{{tagClose}} {{currencySymbol}}{{price}} ({{hint}})',
        percentageHelpText: 'Must be at least {{minimum}}% off the asking price',
        minimumPercentageError: 'Percentage must be at least {{minimum}}%',
        maximumPercentageError: 'Percentage must be less than 100%',
        offerTimingSectionLabel: 'When should newly interested buyers get the Direct Offer?',
        selectAnOption: 'Select an option',
        regularOffersOptionLabel: 'Send Direct Offers in 24 hours',
        instantOffersOptionLabel: 'Send Direct Offers in 1 hour',
        instantOffersOptionSublabel: 'Give interested buyers a deal while it\'s fresh',
        includeOfferMessage: 'Include an optional message to the buyer',
        generalInformation: {
          header: 'Buyers get Direct Offers when they:',
          line1: 'Watch the listing',
          line2: 'Add the listing to their cart without checking out',
          learnMore: 'Learn more about Direct Offers',
        },
        carrierCalculated: {
          alertText: 'Listings sold in the Continental US with Direct Offers enabled must include flat rate shipping.',
          inputLabel: 'Shipping Rate for the Continental US',
        },
      },
      listingSidebar: {
        price: 'Price',
        condition: 'Condition',
        watchCount: 'Watch Count',
        listingLink: 'View Listing On Reverb',
      },
    },
    actionsExplanation: {
      headline: 'Ways to respond to offers',
      seller: {
        accept: {
          title: 'Accept',
          body: 'By default buyers have 3 days to pay when you accept, or they can opt to be automatically charged same-day.',
        },
        counter: {
          title: 'Counter',
          body: 'Counter with a different price.',
          conditionalAcceptance: {
            getPaidFaster: 'Get Paid Faster',
            body: {
              one: 'Or accept the price and counter with a shortened one hour payment window.',
              other: 'Or accept the price and counter with a shortened {{count}}-hour payment window.',
            },
          },
        },
        decline: {
          title: 'Decline',
          body: 'Not interested? Decline the offer.',
        },
        setupOfferAutomation: 'You can also {{linkOpen}}set up automated responses to offers{{linkClose}}.',
      },
    },
  },
  protectionPlans: {
    title: 'Add {{durationYears}} year protection plan',
    learnMore: 'Learn More',
    highlights: 'Plan Highlights',
    poweredBy: 'powered by',
    changePlan: 'Change Plan Length',
    updatePlan: 'Update Plan',
    listItem0: 'Coverage for manufacturing defects or accidental damage, and fast repairs or replacements if there\'s an issue',
    listItem1: 'No deductibles, no fees, and an easy online claim process with 24-hour response window',
    listItem2: 'Coverage extends to new and used gear',
    itemPage: {
      title: 'Add a {{durationYears}}-year protection plan for {{amount}}',
      bodyMain: 'Enjoy worry-free music making. ',
      bodySecondary: 'Get coverage for accidental damage and so much more.',
      changePlan: "See what's covered",
    },
    buyItNow: {
      bodyMain: 'Protect your gear',
      bodySecondary: 'Get coverage for accidental damage and more with a protection plan from Mulberry.',
      poweredBy: 'Powered by Mulberry',
      planOption: '%{normalTagOpen}Add%{spanTagClose} %{boldPurpleTagOpen}{{durationYears}} year%{spanTagClose} %{normalTagOpen}protection plan for%{spanTagClose} %{boldPurpleTagOpen}{{amount}}%{spanTagClose}',
      learnMore: 'Learn more',
    },
  },
  gearCollections: {
    shared: {
      addPastPurchasesToGearCollection: {
        wantToQuicklyAdd: 'Quickly add from your past purchases',
        useAddButtons: 'Find more "Add to My Collection" buttons on your {{linkOpen}}Purchases page{{linkClose}}.',
        quickAddCard: {
          unableToAddTryAgain: 'Unable to add, please try again',
          added: 'Added',
          purchasedAtDate: 'Purchased {{date}}',
          addToCollection: 'Add to Collection',
        },
      },
      priceEstimatesInternationalCallout: 'Note: Estimate based on US sales prices for recent similar items on Reverb. Non-US regional data coming soon!',
    },
    breadcrumbs: {
      myCollection: 'My Collection',
      addANewItem: 'Add a New Item',
      finishAdding: 'Final Step',
      item: 'Your Item',
      editItem: 'Edit',
    },
    index: {
      pageTitle: 'My Collection',
      myCollection: {
        zero: 'My Collection',
        one: 'My Collection ({{count}})',
        other: 'My Collection ({{count}})',
      },
      readyToStart: 'Ready to Start Your Collection?',
      collectionFeatureInfo: "Your Collection lets you keep all of your gear information in one place. You can track the value of what you own over time, add notes for specific items, and soon you'll be able to share your collection with others!",
      gearCollectionSettings: {
        title: 'Collection Settings',
        addToYourCollection: 'Reverb can add any new gear you buy to your Collection.',
        stayUpToDate: "Stay up-to-date on your Collection's value and keep track of mods and more by allowing Reverb to automatically add your purchases to your Collection.",
        autoAddOrdersLabel: 'Auto-add purchases to Collection',
        errorFetchingSettings: 'Error fetching Collection Settings. Please try again.',
        update: {
          success: 'Collection Settings updated!',
          error: 'Error updating Collection Settings. Please try again.',
        },
      },
      exportToCSV: 'Export to CSV',
      addItem: 'Add an Item',
      searchForGearOnReverb: 'Search for gear on Reverb',
      maxedOutCollection: 'You can currently only add a max of {{count}} items to your collection, but we are working to remove this limit very soon. Stay tuned!',
      announcementAlert: {
        title: 'Your Collection: All Your Gear in One Place.',
        subtitle: 'Our newest feature lets you store everything about your gear in one place, including its value over time.',
      },
      postSubmitAlert: 'Updates to your total collection estimate may take a minute to populate. Check back soon!',
      searchSummaryText: 'Showing {{showing}} of {{totalOnPage}} items',
      collection: {
        totalValue: {
          title: 'Estimated Collection Value',
          someItemsMissingEstimate: 'Some items are missing estimates',
          totalsUseYourEstimatesAndReverbs: 'Collection estimate totals use your estimates whenever provided, and Reverb\'s estimates when yours are not.',
          pending: "We're crunching new data for your estimate. This usually only takes a few seconds, so check back soon!",
        },
        card: {
          listForSale: 'List for Sale',
          viewListing: 'View Listing',
          deleteButton: 'Delete',
          deleteModalConfirmation: {
            title: 'Delete collection item?',
            description: 'Deleting this item will be remove it from your collection.',
          },
          deleteSuccess: 'The item was deleted from your collection.',
          deleteError: 'Something went wrong. Please try again.',
          gearImageAlt: 'Gear',
          myEstimate: 'My Estimate',
          reverbsEstimate: 'Reverb\'s Estimate',
          estimateOnReverb: ' on Reverb',
          edit: 'Edit',
          seeThisGearOnReverb: 'See Similar Gear',
          addMyOwnEstimate: 'Add Your Own Estimate',
          estimatedValueNotAvailable: {
            display: 'Estimated value unavailable for this item',
            tooltip: 'There isn’t enough recent sales data on Reverb to provide an estimate, but you can still add your own.',
          },
        },
      },
    },
    show: {
      pageTitle: '{{itemTitle}} | My Collection',
      collectionItem: 'Collection Item',
      backToCollection: 'Back to Collection',
      editDetails: 'Edit Details',
      cancelChanges: 'Cancel & Discard Changes',
      nothingAdded: 'Nothing added yet',
      description: 'Description',
      originalDescription: 'NOTE: Below is the description from original listing.',
      tags: 'Tags',
      details: {
        label: 'Details',
        category: 'Category',
        make: 'Brand',
        model: 'Model',
        finish: 'Finish',
        year: 'Year',
        condition: 'Condition',
      },
      notes: {
        label: 'Notes to Self',
        serialNumber: 'Serial Number',
        notes: 'Misc Notes',
        helpText: 'Empty...',
      },
      estimatedValue: {
        reverbEstimatedValue: 'Reverb Estimated Value',
        myEstimatedValue: 'My Estimated Value',
        sincePurchased: 'Since purchased ({{amount}})',
        youPaid: 'You paid {{amount}}',
        notAvailable: 'Estimate from Reverb not available',
        sinceDate: 'Since {{date}}',
      },
      sellYours: {
        label: 'Want to Sell Yours?',
        helpText: 'Anyone can sell on Reverb. Get this in front of thousands of eyes easily. ',
        learnMore: 'Learn more on the Seller Hub.',
        button: 'List for Sale',
      },
    },
    cspSearch: {
      pageTitle: 'Add a New Item | My Collection',
      cspSearchPageTitle: 'Find your gear',
      label: 'Brand and model',
      placeholder: 'e.g. Fender Player Stratocaster',
      helpText: 'Search for the brand and model so we can add product info and display the estimated value for you.',
      cardButtonText: 'Add to Collection',
      search: 'Search',
      clear: 'Clear',
      addFromScratchText: "Can't find your gear?",
      addFromScratchLink: 'Add an item from scratch',
      manuallyAddLink: 'Or manually add product info',
    },
    cpPicker: {
      searchModuleTitle: 'Which one do you have?',
      searchModuleSubtitle: "Finding your item makes it easier to determine your collection's value",
      searchAgain: 'Search Again',
      label: 'Model and finish',
      buttonText: 'Continue',
      bailLink: "Don't see yours? {{linkOpen}}Continue without it.{{linkClose}}",
    },
    form: {
      newPageTitle: 'Final Step | Add a New Item | My Collection',
      editPageTitle: 'Edit | {{itemTitle}} | My Collection',
      backToCollection: 'My Collection',
      gearCollectionItemTitle: 'Document your gear',
      genericError: 'Something went wrong. Please try again',
      documentYourGear: 'Document your gear',
      edit: {
        backToShow: 'Back to your item',
      },
      title: {
        label: 'What do you have?',
        helpText: 'Example: “Epiphone Les Paul Standard”',
      },
      sections: {
        description: {
          header: 'What makes it special?',
          subheader: 'How does it look, sound, and feel? Every piece of gear has its own story. You can also document your item’s condition and note any modifications or specs.',
        },
        tags: {
          header: 'Your tags',
        },
        photos: {
          header: 'What does yours look like?',
          subheader: 'Stock images are no fun. Add your own photos!',
          addNew: 'Upload Your Photo',
          tipText: 'You can drag your images to reorder them - the upper left image will be the primary photo.',
          dragOverText: 'Drop file to start uploading it',
          showPageLabel: 'Photos',
          originalListing: {
            label: 'Show photos from the original listing',
            helpText: 'The original listing photos will not be included when creating a listing from your collection item.',
          },
        },
        pricing: {
          currentEstimatedValue: 'Current estimated value',
          estimateBasis: 'Estimate based on recent US sales prices for similar items in {{conditionName}} condition.',
        },
      },
      fields: {
        description: {
          label: 'Description',
          showPageLabel: 'Description',
          originalListing: {
            helpText: 'The original description should not be used for creating a listing from your collection item, but it’s good inspiration.',
            label: 'Show description from the original listing',
          },
        },
        condition: {
          label: 'Current condition',
        },
        notes: {
          label: 'Other notes',
          helpText: 'If you decide to sell it, we’ll keep these notes private.',
        },
        estimates: {
          label: 'Estimated value',
          helpText: 'How do you want to calculate your Collection’s total estimated value?',
          useReverbEstimate: "Use Reverb's Estimate",
          notEnoughSalesForReverbEstimate: 'Not enough recent sales data is available on Reverb to calculate this estimate.',
          userEstimateLabel: 'Use my own estimate',
          userEstimateNote: 'We’ll use your estimate to calculate your collection’s total value.',
          reverbEstimateNote: 'Estimate based on US sales prices for recent similar items on Reverb.',
          toBeCalculated: 'Estimated values will appear here after the item is saved to your collection.',
          internationalCallout: 'Note: Non-US regional data coming soon!',
        },
        tags: {
          label: 'Add tags',
          nothingAdded: 'No tags added',
          suggestionLabel: 'Suggested tags',
          inputButton: 'Create tag',
          suggestions: {
            favorites: 'Favorites',
            modded: 'Modded',
            mightSell: 'Might Sell',
            needsRepair: 'Needs Repair',
          },
        },
      },
      make: {
        label: 'Brand',
      },
      model: {
        label: 'Model',
      },
      categories: {
        label: 'Category',
        subcategoryLabel: 'Subcategory',
        subcategory2Label: 'Additional Subcategory',
      },
      finish: {
        label: 'Finish',
      },
      year: {
        label: 'Year',
      },
      price: {
        label: 'My Estimated Value',
        helpText: 'About how much do you think someone would buy this for? Not sure? {{linkOpen}}Try searching Reverb{{linkClose}} to compare.',
      },
      ownerCost: {
        label: 'What you paid',
        helpText: 'Record your original cost to help estimate potential net earnings on Reverb',
      },
      notesToSelf: {
        notesToSelf: 'Notes to Self',
        miscellaneous: 'Miscellaneous',
        serialNumber: {
          label: 'Serial number',
          helpText: 'For insurance and record-keeping purposes',
        },
      },
      matchedPreview: {
        unmatchButton: 'Edit these details',
        headsUp: 'Heads up!',
        willRemovePricingEligibility: "Editing these details <strong>will remove this item's eligibility to receive Reverb's real-time pricing estimates</strong> as this information becomes available.<br><br>You can still add your own estimate, but we won't be able to update you with Reverb's estimates in the future.",
        willRemovePricingEstimates: "<strong>Editing these details will remove Reverb's real-time pricing estimates for this item</strong>.<br><br>You'll still be able to add your own estimate, but you won't receive updates if the value of your item changes on Reverb.",
        modalAccept: 'Continue',
      },
      generalInfo: 'General product info',
      itemDetailsExplainer: 'You can add additional details about your item here. It’s all optional and may help provide better price estimates for your gear.',
      submit: 'Add',
      saveChanges: 'Save',
      cancel: 'Cancel',
      startOver: 'Start Over',
      deleteButtonText: 'Delete Collection Item',
      deleteConfirmationText: 'Are you sure you want to delete your item?',
    },
  },
  brandMakeInput: {
    warning: "We don't recognize this brand. Please check your spelling.",
    openForm: 'Submit a new brand request',
    modalHeader: 'Request a new brand',
    saveButtonText: 'Submit request',
    backButtonText: 'Close',
    brandNameLabel: 'Brand name',
    brandDescriptionLabel: 'Description',
    brandWebsiteLabel: 'Brand website',
    successMessage: 'Thank you for submitting your request. You can expect to hear from us within two business days.',
    backendErrorsExplainer: 'There was an issue requesting a new brand:',
    genericError: 'Something went wrong. Please try again later.',
  },
  sellerListingsRow: {
    header: {
      drafts: 'Finish your drafts',
      collectionItems: 'Sell from your collection',
      pastPurchases: 'Sell something you bought',
    },
    seeMore: 'See more',
  },
  sellForm: {
    setupPage: {
      pageTitle: 'Seller Onboarding',
      pageHeader: 'Start selling',
      cardTitle: 'Confirm a few details before you start',
      cardSubtitle: 'Before you start selling, we’ll need a few details. You can change these any time in your shop settings.',
      alreadyOnboarded: "You're ready to sell on Reverb! You can review your selling preferences on your shop settings page.",
      shopName: {
        title: 'How would you like to be known on our site?',
        explainer: 'We use this information to help buyers connect with you and find you on Reverb again in the future.',
        altText: 'User icon',
      },
      shopAddress: {
        title: 'Where are you located?',
        explainer: 'Your address helps us get your listings in front of the right buyers and keeps our marketplace safe and secure.',
        altText: 'Globe icon',
      },
      yourNameLabel: 'Your name',
      shopNameLabel: 'Shop name on Reverb',
      shopNameHelpText: 'Helps return buyers find you again',
      shopLocale: {
        title: 'Language & currency preferences',
        explainer: 'Helps us show you the correct cost and payout information, and communicate with you in your language.',
        altText: 'Money icon',
      },
      localeCodeLabel: 'Language',
      currencyCodeLabel: 'Currency',
      suggestedShopName: "{{firstName}}'s Gear Outlet",
      submitButtonText: 'Continue',
    },
    pageAlert: {
      genericError: 'Something went wrong. Please try again later.',
      cloneAlert: {
        youAreCloning: 'You are cloning this item from another listing.',
        viewOriginal: 'View the original.',
      },
      saveToast: {
        draft: 'Draft Saved!',
        live: 'Changes Published!',
        other: 'Changes Saved!',
        viewListing: 'View Listing',
      },
    },
    pageTitle: 'Sell on Reverb | Sell used guitars, pedals, amps and more',
    section: {
      continue: {
        continue: 'Continue',
        live: 'Publish changes',
        saveChanges: 'Save changes',
        saveAndContinue: 'Save and continue',
      },
      publish: 'Publish',
      discard: 'Discard changes',
      back: 'Back',
    },
    breadcrumbs: {
      yourListings: 'Your Listings',
    },
    navigationLabels: {
      info: 'Product Info',
      description: 'Photos & Description',
      shipping: 'Shipping',
      pricing: 'Pricing',
      optionalSellingTools: 'Optional selling tools',
      review: 'Review',
    },
    sellFormLayoutSwitcher: {
      layout: 'Layout:',
      modeOptions: {
        multiPage: 'Guided view',
        singlePage: 'Advanced view',
      },
      modeHelpText: {
        multiPage: 'Best for new sellers. Presents the experience in multiple guided steps.',
        singlePage: 'Best for frequent sellers. Combines the experience into a single page.',
      },
      abandonModal: {
        header: 'Switch Listing Creation Layout',
        areYouSure: 'Are you sure you want to switch to guided view?',
        alertText: "Any unsaved changes on your listing won't be retained.",
        saveButtonText: 'Switch to guided view',
        backButtonText: 'Cancel',
      },
      switchModes: 'Switch modes',
      fullPageActive: "You're using the Advanced Listing Mode",
      fullPageInActive: "You're using the Guided Experience Mode",
      activateFullPage: 'Use Advanced Listing Mode',
    },
    navigationError: {
      one: '1 issue',
      other: '{{count}} issues',
    },
    productInfoSection: {
      searchAgain: "Not what you're looking for? {{linkOpen}}Search again{{linkClose}}",
      sectionHeader: 'Tell us about your gear',
      requestListingCorrection: {
        lockExplainer: "We automatically matched your listing's brand/category to help it sell faster.",
        requestCorrection: 'Request correction',
        modalHeader: 'Request listing correction',
        saveButtonText: 'Submit request',
        backButtonText: 'Close',
        textAreaLabel: 'Describe the issue for our editors to review',
        successMessage: 'Thank you for alerting us! We will review this listing and correct any issues.',
        genericError: 'Something went wrong. Please try again later.',
      },
    },
    vatInclusiveListingPrice: {
      helpTextHtml: 'You setup a policy to list with VAT inclusive pricing. {{linkOpen}}Learn more{{linkClose}}',
    },
    pricingSection: {
      pricingGuidance: {
        title: 'From the Price Guide',
        graphIcon: 'Price Guide graph icon',
        description: '<b>{{modelName}}</b> in <b>{{conditionName}}</b> condition sells fastest at or below the competitive price.',
        competitivePrice: 'Competitive Price',
        estimatedPriceRange: 'Estimated Price Range',
        viewLiveAndSoldPrices: 'View Live and Sold Prices',
        competitivePriceSellsFaster: 'Competitively priced gear tends to sell quickly on Reverb.',
        noLiveListings: 'There are currently no live listings to display.',
        noSoldTransactions: 'There are currently no sold listings to display.',
      },
      greatValue: {
        title: 'Buyer sees',
        greatValueText: 'Great Value',
        belowAverageText: 'Below average price for this condition',
        greatValueIconAlt: 'Gem icon',
      },
      priceSignals: {
        iconAlts: {
          warning: 'Warning icon',
          spark: '',
          gem: 'Gem icon',
        },
        likelyToSell: {
          title: 'Likely to sell quickly',
          text: 'Buyer sees: Great Value',
        },
        aboveAverage: {
          title: 'Priced above average',
          text: 'Lower your price to sell faster',
        },
        lessLikelyToSell: {
          title: 'Less likely to sell',
          text: 'Consider lowering your price',
        },
        unlikelyToSell: {
          title: 'Unlikely to sell',
          text: 'Consider lowering your price',
        },
        higherThanNewPrice: {
          title: 'Probably will not sell',
          text: 'Typical new price: {{newPrice}}',
        },
      },
      priceBreakdown: {
        sellingFee: {
          feeWaived: 'Your first time selling fee is on us!',
          label: 'Selling fee {{specifier}}',
          tooltip: {
            youGet: 'For a {{feePercent}}% selling fee, you get:',
            access: 'Access to millions of 5-star buyers',
            expertSupport: 'Expert support and security from a gear-focused team',
            marketing: 'Marketing on your behalf to help drive more buyers to your listings',
            pricing: 'Pricing and negotiating tools to help make your sale',
            waiver: 'Redeemed sell fee waivers will be applied to your bill once item has sold',
          },
        },
        processingFee: {
          label: 'Processing fee {{specifier}}',
          tooltip: {
            header: '{{feePercent}}% + {{processingFlatFeeDisplayAmount}}',
            explainer: 'When a buyer submits payment for an order on Reverb, the payment processor facilitates the transaction of funds and verifies the transaction is secure.',
          },
        },
        estimatedEarnings: {
          label: 'Estimated Earnings {{specifier}}',
          specifier: '(Incl. VAT)',
          tooltip: 'This estimate is intended to help you price your gear. It might not include all potential fees or charges, such as shipping costs, value added taxes (VAT) or cross border fees when you sell to a buyer in another country.',
        },
        vatIdAlert: 'Have a VAT or GST ID? Reverb can keep it on file for you. {{linkOpen}}How to update your VAT or GST ID.{{linkClose}}',
      },
      pricingTables: {
        listings: {
          header: 'Listing Prices',
          tabs: {
            soldListingPrices: 'Sold Listing Prices',
            liveListingPrices: 'Live Listing Prices',
          },
        },
        recentlySoldListings: {
          buttonText: 'Recently Sold Listings',
          modalHeader: 'Recently sold items like yours',
          dateHeader: 'Date Sold',
        },
        liveListings: {
          buttonText: 'Current Live Listings',
          modalHeader: 'Live listings for items like yours',
          dateHeader: 'Date Published',
          priceHeader: 'Listing Price',
        },
        nav: 'Table Page Navigation',
        next: 'Next Page',
        previous: 'Previous Page',
        close: 'Close',
        disclaimer: 'Prices reflect sales of item in used condition. Prices are based in USD and exclude shipping and tax/VAT/GST. Excludes Brand New, B Stock, Fair, Poor, and Non-functioning conditions.',
      },
    },
    photosOverlay: {
      title: 'Tips for top-notch photos',
      trigger: 'Tips for top-notch photos',
      allSides: {
        title: 'Tip #1: Photograph from all sides',
        paragraph1: 'Reverb allows up to 25 images per listing, which leaves plenty of room to include shots of every part of the guitar.',
        paragraph2: 'If you only include one or two photos of your guitar, buyers will either look past your listing or contact you for more information, which can delay or dissuade a purchase from happening.',
        paragraph3: 'As a good baseline, we recommend the following sequence of guitar shots:',
        listItem1: 'Close-up on the front of the body',
        listItem2: 'Full length of the body',
        listItem3: 'Close-up of back',
        listItem4: 'Full length of back',
        listItem5: 'The guitar at an angle shows the depth of the body',
        listItem6: 'Various close-ups or angles of different portions of the guitar',
        closeupFrontCaption: 'Close-up of front',
        fullFrontCaption: 'Full length of front',
        closeupBackCaption: 'Close-up of back',
        fullBackCaption: 'Full length of back',
        headstockCloseCaption: 'Close-up of headstock',
        headstockFullCaption: 'Full length of headstock',
        bodyDepthCaption: 'Angle to show depth of body',
        variousCloseupsCaption: 'Various close-ups',
        imperfectionsCaption: 'An example of a photo calling out an imperfection',
        paragraph4: 'It\'s also essential to include close-ups of any imperfections on your guitar, no matter how small. To eliminate confusion about the imperfection, call it out in the photo.',
      },
      backgrounds: {
        title: 'Tip #2: Use consistent backgrounds',
        paragraph1: 'If you’re a regular seller on Reverb, using a consistent background helps to establish your brand.',
        paragraph2: 'Using a neutral or blank background is a safe choice. A solid color wall or sheet works, too. Since you’re aiming for consistency, make sure the background is something you’ll have access to every time you want to list something.',
        paragraph3: 'If you can\'t find or create a solid blank background, or you prefer something with more style, listings with cohesive background themes are the next best choice. Just be sure to avoid using distracting patterns or anything that will take the focus off the guitar itself.',
        imageCaption: 'A distracting background takes the focus off the guitar.',
      },
      lighting: {
        title: 'Tip #3: Keep Lighting Even',
        paragraph1: 'Natural daylight is probably the best substitute for a professional lighting rig. Shoot by a window on a sunny day to get good, natural light or even take the camera and guitar outside. Overhead noon sun will minimize shadows. This is easy, free, and available on a daily basis.',
        paragraph2: 'If you don\'t have good window space, or simply can\'t shoot during the day, you can angle multiple indoor lamps to illuminate your guitar. Use one lamp on one side and a piece of white paper on the other to reflect light, and avoid detail-obscuring shadows.',
        paragraph3: 'Dealing with shadows is inevitable – just make sure that they aren’t too harsh or awkward. This is often the dividing line between photos that look polished and professional, versus ones that look amateur.',
        imageCaption: 'Avoid harsh light behind your guitar to keep it out of the shadows.',
        imageAlt: 'An image of a guitar with bright sunlight coming in from windows behind the guitar that makes it hard to make out details on the guitar itself',
      },
      smartphone: {
        title: 'Tip #4: Use your smartphone or tablet',
        paragraph1: 'Photos taken with an expensive DSLR or mirrorless camera are not necessarily going to help a listing sell faster than one with well-lit, focused photos taken with a smartphone or tablet.',
        paragraph2: "We suggest you buy a tripod for your iPhone or Android. These generally cost about $15-$20. It’s definitely worth it, considering that any little shake or blur can lower the quality of your pictures. Tripods can help your phone's ability to focus.",
        paragraph3: 'Be sure you’re not making the guitar look like something it’s not. If it’s a Fiesta Red Fender, it should still look like a Fiesta Red Fender. Make sure your edits aren’t altering the color of your guitar.',
        editingAppsHTML: 'Editing your photo quality can also help your photos stand out. Most smartphones come with this capability, along with free apps like %{vscoLink} and ColorStory.',
      },
      crop: {
        title: 'Tip #5: Square crop your photos',
        paragraph1: 'All photos on Reverb get square-cropped anyway – on the web, app and when they get pushed to online search engines. If you want your pictures to show up the way you intended, keep them square (equal height and width) from the start.',
        paragraph2: 'Almost all smartphones allow you to crop your photos after taking them with a built-in editor. You can also use a simple default program on your computer like Preview (for Apple) to crop photos on your desktop. Hold the "Shift" key while dragging your crop box and it will keep it square as you drag.',
      },
    },
    conditionsOverlay: {
      title: 'How to determine the condition',
      trigger: 'How to determine the condition',
      retailerConditions: 'Retailer Conditions',
      close: 'Close',
      conditions: {
        mint: {
          title: 'Mint',
          description: 'Mint condition items are completely blemish-free and have not been modified in any way. If there are any blemishes and/or modifications, the item needs to be listed as a condition less than mint.',
          imageCaption: 'This Gibson sunburst has no blemishes and the nice hardshell case shows it has been well taken care of.',
        },
        excellent: {
          title: 'Excellent',
          description: 'When an item has been used, but only has minor cosmetic defects, this can be considered Excellent. Minor surface level scratches, such as pick marks from regular use, would qualify.',
          imageCaption: 'This snare drum demonstrates standard minor surface level cosmetic defects on an Excellent condition item.',
        },
        veryGood: {
          title: 'Very Good',
          description: 'Very Good describes items with more cosmetic imperfections than Excellent, but are still in great condition overall. There may be some scratches, finish dings or buckle rash, but nothing that affects the playability or function of the gear.',
          imageCaption: 'Very Good items can have more minor cosmetic imperfections, like this bass.',
        },
        good: {
          title: 'Good',
          description: 'Good condition includes items that are in fine working order, but have significant cosmetic imperfections. A pedal with scratches and dents on its chassis, a guitar with some cracks in the binding, or an old snare drum with some corrosion on the lugs would all be classified as Good. Items with dents, cracks or corrosion qualify as Good as long as they are still playable and fully functional.',
          imageCaption: 'This Stratocaster has more severe blemishes, but is still completely functional.',
        },
        fair: {
          title: 'Fair',
          description: 'Fair condition items have various imperfections that impact the function of the item. Fair items aren\'t broken, but have some minor functional issues. Examples of items in Fair condition are scratchy pots on an amp, or a playable guitar with dead spots in the upper register.',
          imageCaption: 'This guitar has a noticeable top crack, which impacts its tonal and projection functionality.',
        },
        poor: {
          title: 'Poor',
          description: 'Poor condition items have clear functional problems that will generally require repair. Examples of items in Poor condition include a guitar in which the action is too high to be playable and a keyboard with lots of broken keys.',
          imageCaption: 'This amp head shows clear functional issues with missing knobs, a stripped down non-original circuit and is overall in poor cosmetic condition.',
        },
        nonFunctioning: {
          title: 'Non-Functioning',
          description: 'Non-Functioning condition items include instruments and other gear that simply do not work. It could be a guitar with an entirely broken neck, or a mixing board that won\'t power up. There are still plenty of buyers out there who are in the market for project guitars and other \'fixer-upper\' items, just be sure all of the specific issues are disclosed and addressed in the listing.',
          imageCaption: 'This guitar has zero playability to it, making it Non-Functioning.',
        },
        brandNew: {
          title: 'Brand New',
          description: 'Products listed by authorized dealers that are unopened, new in box and are covered under the manufacturer\'s original limited warranty.',
          infoBox: 'If you are not an authorized dealer, you cannot list brand new unless you handmade the item yourself. If you are reselling a piece of gear that you bought brand new from a retailer—even if it has never been used—it automatically falls under the Mint condition since it may no longer be covered by the manufacturer\'s original warranty.',
        },
        bStock: {
          title: 'B-Stock',
          description: 'Items that were opened and returned, have a cosmetic imperfection or were used as demo units in a shop. Many B-Stock items come with a limited manufacturer\'s warranty and ship direct from a brand or distributor who sells on Reverb.',
        },
      },
    },
    descriptionSection: {
      clear: 'Clear',
      photos: {
        sectionHeader: 'Upload photos of your gear',
        explainerText: {
          originalPhotos: 'Use original photos for used gear.',
          screenshots: 'Screenshots or pictures from other sites, sellers or manufacturers are not allowed.',
          squarePhotos: 'Square photos are best, and they should be 620x620px or bigger.',
        },
        examples: {
          examples: 'Examples',
          show: 'Show',
          hide: 'Hide',
          primary: 'Primary',
          everyAngle: 'Every Angle',
          details: 'Details',
          closeUps: 'Close-ups',
          inUse: 'In use',
          sizeScale: 'Size and scale',
          imperfections: 'Imperfections',
        },
        requiredErrorText: 'Please add at least 1 photo.',
        crossPlatformBanner: {
          header: 'Upload photos directly from your mobile device',
          subHeader: 'Avoid the hassle of transferring photos with this option.',
          buttonText: 'Try it',
        },
      },
      description: {
        requiredErrorText: 'Please add a description',
      },
      noReturnsAllowed: 'Your Shop Policy states that you don\'t allow returns. You are still obligated to write accurate and detailed descriptions, provide good photos, and deliver these items to the buyer in the described condition.',
      condition: {
        sectionHeader: 'Select a condition and describe the item',
      },
      toggledSectionTitle: {
        skuInventoryPreorders: 'SKU, Inventory & Pre-orders',
        skuPreorders: 'SKU & Pre-orders',
        skuInventory: 'SKU & Inventory',
        sku: 'SKU',
      },
      localizedContent: {
        title: 'Add additional descriptions in your supported languages',
        subtitle: 'You can update your supported listing languages in your shop settings',
        option: 'Add {{localeName}} description',
        productDescription: 'Describe this item in {{localeName}}',
      },
      crossPlatformModal: {
        header: {
          standby: 'Connect your mobile device',
          active: "You're connected to your mobile device",
          completed: 'Upload successful',
          error: 'Upload failed',
        },
        subheader: {
          standby: 'Scan QR code',
          active: 'You are currently active on your mobile device',
          completed: 'Success!',
          error: "Something's not right",
        },
        bodyText: {
          standby: "Scan this QR code to connect to your mobile device. When you're done uploading photos of your gear, they'll appear here and you can continue your listing.",
          active: "When you're done uploading photos of your gear, they'll appear here and you can continue your listing.",
          completed: 'Allow us a moment to finish syncing with your mobile device...',
          error: 'Please close out of any other active tabs/devices you may have open, or try again later.',
        },
        bodyActions: {
          standby: 'Cancel Photo Upload',
          active: {
            disconnectInitial: 'Disconnect and cancel Photo Upload',
            disconnectConfirmExplain: 'Are you sure? You will lose any progress.',
            disconnectConfirmYes: 'Cancel Photo Upload',
            disconnectConfirmNo: 'Stay connected',
          },
          error: 'Return to listing',
        },
      },
    },
    shippingSection: {
      deliveryOptionsHeader: 'How will you deliver the item to the buyer?',
      deliveryOptions: {
        shipping: 'Shipping',
        shippingHighlight: 'Items sell faster',
        localPickup: 'Local pickup',
      },
      costsPerRegion: 'Shipping costs per region',
      rateType: {
        rate: 'Default standard rate:',
        incrementalRate: 'Combine & Save rate:',
        expeditedRate: '2-day rate:',
        regionalRate: 'Regional standard rate:',
        regionalExpeditedRate: 'Regional 2-day rate:',
      },
      carrierCalculatedSummary: 'Reverb calculates shipping cost',
      packageLengthSummary: 'Package dimensions:',
      packageWeightSummary: 'Package weight:',
      packageCarrier: 'Carrier:',
      profileCard: {
        header: 'Would you like to use a shipping profile?',
        select: 'Select a shipping profile',
        selectNone: 'None selected',
        suggestedText: "You have shipping profiles that match this listing's product information:",
      },
      sameDayCard: {
        header: 'Offer same-day shipping for this item?',
        subheader: 'Based on your shop settings, this item can ship same-day. You can opt this item out of same-day shipping.',
        label: 'Item can ship same-day.',
      },
      requiredErrorText: 'Please complete your shipping options below before continuing.',
      carrierHighlights: {
        upsDescription: 'Most items over 10 lbs ship via UPS',
        uspsDescription: 'Most items under 10 lbs ship via USPS',
      },
      domesticShippingCard: {
        header: 'How will you handle shipping costs in {{regionName}}?',
        theContinentalUs: 'the Continental U.S.',
        missingDomesticWarning: '{{children}} for your home region ({{regionName}})',
        missingDomesticButtonText: 'Set a shipping cost',
        shopAddressSummary: '{{regionName}}, {{postalCode}}',
        carrierCalculatedWarnings: {
          exceedsActualWeightLimit: 'Packages heavier than {{maxWeightPounds}}lb are ineligible for calculated shipping. Please {{children}}.',
          exceedsLengthLimit: 'Packages longer than {{maxLengthInches}}in in any dimension are ineligible for calculated shipping. Please {{children}}.',
          exceedsDimensionalWeightLimit: 'Large packages (greater than {{maxCubicInches}} cubic inches) are ineligible for calculated shipping. Please {{children}}.',
          exceedsLargePackageLimitUps: 'Large packages are ineligible for calculated shipping. Please {{children}}.',
          setCustomRate: 'set a custom shipping rate',
        },
        carrierCalculatedIndicator: {
          shipsWithUps: '<strong>Ships with UPS.</strong> UPS provides the best rates for packages like this. We’ll email you next steps about your label when this listing sells.',
          shipsWithUsps: '<strong>Ships with USPS.</strong> USPS provides the best rates for packages like this. We’ll email you next steps about your label when this listing sells.',
        },
        highlights: {
          recommended: 'recommended',
        },
        reverbPackageDataToggle: {
          label: 'Use Reverb package data',
          subLabel: 'Dimensions: {{lengthWidthHeightSummary}}, Weight: {{displayWeight}}',
          description: 'No need for measuring tape - we have automatically estimated the weight and dimension of your shipment with historical data.',
        },
        radios: {
          label: {
            custom: 'Set a flat shipping rate for this region',
            carrierCalculated: 'Have Reverb calculate the shipping cost for you',
            free: 'Offer free shipping',
          },
          subLabel: {
            custom: 'Determine one cost that all buyers in this entire region will pay.',
            carrierCalculated: "We'll use our discounts to calculate the shipping cost from you <strong>({{shopAddressSummary}})</strong> to the buyer. They'll pay the full cost of shipping at the time of purchase.",
            free: 'Attract more buyers by offering to cover shipping! You can adjust the listing’s price to make up for the cost.',
          },
          carrierCalcTerms: 'View terms',
        },
      },
      shippingOptionsCard: {
        subheader: 'You can select both options.',
      },
      prefersReverbShippingLabelCard: {
        header: 'Save money with a Shipping Label on Reverb?',
        subheader: 'After your gear sells, choose your carrier and print the label at home.',
        checkboxLabel: 'Yes, I’ll get a Shipping Label on Reverb',
        checkboxTag: 'RECOMMENDED',
        valueProp: {
          iconAlt: 'Shipping box',
          save: 'Save up to 50% on carrier rates.',
          safe: 'Safe Shipping available for protection.',
          qr: 'No printer? You can show a QR code to the carrier (USPS only).',
        },
      },
      internationalShippingCard: {
        header: 'Where else in the world will you ship?',
        suggestedRegions: 'Sellers in your region who ship to: {{children}} get their items in front of more buyers.',
        offerFree: 'Offer free shipping in this region',
        iconAlt: 'Graph icon',
      },
      invalidRates: 'Invalid shipping prices.',
      invalidPackage: 'Invalid package dimensions.',
    },
    traits: {
      mainLabel: 'Improve your listing with product specs',
      accordionHeader: 'Advanced tech specs',
    },
    canonicalProductCard: {
      edit: 'Edit',
      finish: 'Finish',
      year: 'Year',
      manufacturedIn: 'Manufactured In',
      selectModelVersion: 'Select Model Version',
      category: {
        one: 'Category',
        other: 'Categories',
      },
    },
    videos: {
      byChannelTitle: 'by {{channel_title}}',
      chooseAVideo: 'Choose a Video',
      linkToVideo: 'Paste a link to a YouTube video',
      noVideosMatch: 'No videos found. Check spelling or try a different search.',
      removeSelected: 'Remove selected video',
      searchYouTubeLabel: 'Search for a YouTube video',
      searchYouTubePlaceholder: 'Search',
      title: 'Embed a YouTube video on your listing',
      videoTitle: '{{video_title}}',
      youtubeLinkPlaceholder: 'YouTube link',
      youtubeSearchError: 'YouTube search isn\'t working at the moment. Please try again in a few minutes, or visit YouTube to look for a video.',
    },
    review: {
      yourGear: 'Your Gear',
      photosOfYourGear: 'Photos of Your Gear',
      conditionDescription: 'Condition & Description',
      additionalLocaleContent: 'Additional language descriptions',
      primaryPhoto: 'Primary',
      checkboxField: {
        yes: 'Yes',
        no: 'No',
        offersEnabled: {
          yes: 'Accept offers',
          no: 'Do not accept offers',
        },
      },
      noImages: 'No images uploaded yet',
      uploadedImageAlt: 'Uploaded Image {{number}}',
      shippingProfiles: 'Shipping profiles',
      profileSelected: 'Profile selected',
      bump: 'Bump',
      bumpRate: 'Bump Rate',
      notBumped: 'Not Bumped',
      editLabels: {
        info: 'Edit info section',
        description: 'Edit description section',
        shipping: 'Edit shipping section',
        pricing: 'Edit pricing section',
      },
      scheduledPriceDropCaveat: 'on {{date}} if unsold',
    },
    fields: {
      title: {
        label: 'Listing Title',
      },
      localizedContents: {
        label: 'Content in other languages',
      },
      make: {
        label: 'Brand',
      },
      model: {
        label: 'Model',
      },
      finish: {
        label: 'Finish',
      },
      conditionSlug: {
        label: 'Condition',
      },
      description: {
        inputLabel: 'Describe this item and its condition',
        label: 'Description',
      },
      videoLinkUrl: {
        errors: {
          invalid: 'Please enter a valid YouTube link.',
        },
        label: 'Youtube Video',
      },
      year: {
        label: 'Year',
        helpText: 'If you don\'t know the exact year, use a fuzzy date like "mid-90s" or "1953-1957."',
      },
      categoryRootUuid: {
        label: 'Category',
      },
      categoryUuids: {
        label: {
          one: 'Subcategory',
          other: 'Subcategories',
        },
      },
      countryOfOrigin: {
        label: "Manufacturer's Country",
        selectNone: 'None',
      },
      sku: {
        label: 'SKU',
        helpText: 'Your unique identifier for this listing',
      },
      handmade: {
        formLabel: 'Is this handmade?',
        checkboxLabel: 'I handmade this item',
        label: 'Handmade?',
      },
      price: {
        label: 'Listing price',
        errors: {
          belowMinimum: 'This listing is below the minimum allowed price',
          requiredForScheduledPriceDrop: 'A price is required when setting a scheduled price drop',
        },
      },
      sellerCost: {
        descriptionHeading: 'What you paid for the item',
        descriptionContent: 'Keep track of what you paid for an item versus what it sells for. This info is for your benefit only.',
        label: 'What you paid',
        helpText: 'Not shown publicly.',
      },
      sellYourGearFaster: {
        heading: 'Sell your gear even faster',
        description: 'Competitively priced gear tends to sell quickly. Increase your chances of selling with price drops and offers.',
        increaseChancesOfSelling: 'Increase your chances of selling with price drops and offers.',
      },
      scheduledPriceDrops: {
        label: 'Scheduled price drop',
        draftCheckboxLabel: 'Drop the price in 2 weeks',
        draftCheckboxSublabel: "We'll only drop the price if the item hasn't sold. Watchers are notified about price drops of 10% or more.",
        liveCheckboxLabel: 'Price drop scheduled',
        liveCheckboxDisabledLabel: 'Price drop disabled',
        liveCheckboxSublabel: "We'll only drop the price if the item hasn't sold. You can disable your scheduled price drop, or edit the new price.",
        dropDateText: 'Price will drop on <strong>{{date}}</strong>',
        priceLabel: 'New price after price drop',
        percentDropText: '{{percent}}% price drop',
        dropTooLowText: 'Price drops of 10% or higher are more attractive to buyers.',
        errors: {
          invalid: 'Must be lower than listing price',
        },
      },
      sellerReportedMap: {
        label: 'Manufacturer\'s MAP',
        helpText: 'We\'ll use this to calculate discounts if you drop the price or put the item on sale.',
      },
      offersEnabled: {
        descriptionHeading: 'Would you like to accept offers?',
        descriptionContent: 'This allows you to negotiate a final price with buyers. ',
        lowballText: 'You\'ve set up rules to automatically decline low offers. Review them in your shop policies.',
        autoAcceptText: 'You\'ve set up rules to automatically accept high offers. Review them in your shop policies.',
        lowballAndAutoAcceptText: 'You\'ve set up rules to automatically accept and decline offers. Review them in your shop policies.',
        label: 'Offers',
        checkboxLabel: 'Yes, accept offers',
        checkboxLabelForScheduledPriceDropExperiment: 'Allow buyers to make offers',
        checkboxSublabel: 'This allows you to negotiate a final price with buyers. ',
      },
      taxExempt: {
        checkboxTitle: 'Tax Exempt',
        checkboxLabel: 'VAT does not apply',
        label: 'Tax Exempt?',
      },
      soldAsIs: {
        label: 'Sold "As-Described"?',
        checkboxLabel: 'This item is sold "As-Described"',
        checkboxSublabel: 'Items sold "As-Described" are excluded from your shop return policy. You are still obligated to write accurate and detailed descriptions, provide good photos, and deliver these items to the buyer in the described condition.',
      },
      state: {
        label: 'Listing State',
      },
      inventory: {
        label: 'Number in stock',
        errors: {
          invalid: 'Please set a valid quantity',
        },
      },
      hasInventory: {
        label: 'Inventory',
        options: {
          yes: 'I have more than 1',
          no: 'This is a unique item',
        },
      },
      upcDoesNotApply: {
        label: 'Do you have a universal product code (UPC)?',
        tooltip: 'UPC, also referred to as EAN or GTIN, is the 12-digit bar code used widely for retail packaging in the United States.',
        options: {
          yes: 'Exempt from UPC or EAN',
          no: 'This item has a UPC or EAN',
        },
      },
      upc: {
        label: 'UPC or EAN code',
        errors: {
          invalid: 'Code must be between 8-14 digits',
        },
      },
      shipDate: {
        label: 'Pre-order Ship Date',
      },
      leadTimeDays: {
        label: 'Pre-order Lead Time',
        value: '{{number}} days',
      },
      preorderInfo: {
        errors: {
          inPast: 'Ship date must be in the future',
          tooFarInFuture: 'Ship date must be no more than 3 years from today',
          invalidLeadTime: 'Please set a valid lead time',
        },
        timeType: {
          label: 'Make this a Pre-order Listing',
          options: {
            shipDate: {
              label: 'Ship date',
              sublabel: 'The date the item will be available to ship',
            },
            leadTime: {
              label: 'Lead time',
              sublabel: 'The amount of time after a buyer places an order before the item will be ready to ship',
            },
          },
        },
        leadTimeDays: {
          label: 'Lead Time',
        },
        leadTimeUnit: {
          label: 'Units',
          options: {
            days: 'Days',
            weeks: 'Weeks',
          },
        },
        shipDate: {
          label: 'Ship Date',
        },
      },
    },
    publishPage: {
      header: 'Heads up!',
      errorWarning: 'There were some issues publishing your listing.',
      helpfulLinks: 'Helpful links:',
      creditCardNeeded: 'A credit card needs to be added to your account before proceeding.',
      aboutListingReview: 'About listing review',
      reEnterBilling: 'Re-enter billing',
      backToSellForm: 'Review or update your listing',
      sellerDashboard: 'Seller dashboard',
      contactSupport: 'Contact support',
      shopSettings: 'Shop settings',
    },
    optIn: {
      title: "You're using the classic listing form",
      CTA: 'Try the new listing experience',
    },
    optOut: {
      trigger: "You're using the new listing experience",
      CTA: 'Go back to the classic listing experience',
      warning: 'You may lose any unsaved data by taking this action',
    },
  },
  crossPlatformMobilePhotoUploader: {
    alert: {
      header: {
        qrExpired: 'QR code has expired',
        readyToBegin: "You're connected to your desktop listing",
        success: "You're all set!",
        failure: 'Upload failed',
        confirmCancellation: 'Cancel and disconnect',
        extendTime: 'Are you still there?',
        sessionExpired: 'Session has expired',
      },
      bodyText: {
        qrExpired: 'Please restart the upload process from your desktop.',
        readyToBegin: "Any photos you upload here will appear on your desktop listing when you're finished.",
        success: 'You can return to your desktop to complete your listing.',
        failure: 'Something interrupted the upload process, please try again later.',
        confirmCancellation: 'Would you like to cancel your photo upload and return to your desktop listing? You will lose any progress.',
        extendTime: 'Your session will be canceled after sitting idle for too long.',
        sessionExpired: 'Please restart the upload process from your desktop.',
      },
      submitButtonText: {
        qrExpired: 'Exit Photo Upload',
        readyToBegin: 'Start Photo Upload',
        success: "I'm done",
        failure: 'Exit Photo Upload',
        confirmCancellation: 'Cancel Photo Upload',
        extendTime: 'Stay connected',
        sessionExpired: 'Exit Photo Upload',
      },
      backButtonText: {
        confirmCancellation: 'Stay connected',
        extendTime: 'Dismiss',
      },
      mWebCameraWarning: "If you encounter camera issues, check your device's permission settings.",
    },
    submitButtonText: 'Finish',
    cancelButtonText: 'Cancel',
  },
  cardNudge: {
    more: '+ {{amount}} more',
    multipleFinishes: 'Multiple Finishes',
    priceRecommendation: {
      graphIconAlt: 'Price Guide graph icon',
      tooltipLabel: 'Price Guide:',
      tooltipText: 'Use recent sales to ensure you\'re getting a fair price and to find the right price for a buyer. Competitively priced gear tends to sell within a week.',
    },
  },
  sellFlow: {
    header: {
      searchToSell: 'Search for something to sell',
      title: 'Millions of musicians are waiting to buy your gear',
      copy:
        "Get your gear in front of millions of musicians, gear fanatics, and collectors for a low fee of 3.5% once it sells. It's always free to create a listing.",
    },
    v3_experiment: {
      header: {
        title: 'Start your listing',
      },
      search: {
        step1: 'Step 1 of 2',
        step2: 'Step 2 of 2',
        prompt: 'Tell us what you\'re selling to jumpstart your listing.',
        tipTitle: 'Pro Tip',
        tip: 'When you start your listing this way, we\'re able to use our extensive gear knowledge to autofill product details, help price your gear, and market your listing even better.',
        label: 'Brand and model name',
        placeholder: 'e.g. Fender Player Telecaster',
        bailButton1: 'Skip this step and ',
        bailButton2: 'start your listing from scratch.',
        searchButton: 'Start Your Listing',
        resultHeader: 'Select the product you\'re selling',
        goBack: 'Back to search',
        back: 'Back',
      },
      bailCard: {
        prompt: "Can't find what you're selling?",
        link: 'Start your listing from scratch',
      },
    },
    unmatchedSearchExperiment: {
      fuzzySearch: {
        label: 'Fuzzy search?',
      },
      search: {
        label: 'Brand and model',
      },
      prompt: 'What are you selling?',
      bailCard: {
        prompt: 'Not finding what you\'re looking for?',
      },
      matchedProductsGrid: {
        title: '%{count} Products',
        tipText: 'Which one is yours?',
        loadMore: 'Load More Products',
      },
      unmatchedProductsGrid: {
        title: 'Similar Sold Listings',
        tipText: 'Use a sold listing to kickstart your own',
        loadMore: 'Load more sold listings',
        listingPriceTooltipText: 'Latest public facing listing price. Does not account for listings sold via offer.',
      },
    },
    search: {
      cantFindIt: "Can't find it?",
      bailButton: 'Start a listing from scratch',
      bailButton2: 'Start your listing from scratch',
      queryTip: '<strong>Tip</strong>: Enter the Brand and Model',
      label: 'What Would You Like to Sell?',
      placeholder: 'e.g. Sunburst Fender Player Telecaster',
      emptyMessage:
        'Sorry, we didn\'t find anything matching your term "<strong>{{query}}<strong>"',
      emptyImageAlt: "Sorry, we didn't find anything matching your term",
      resultsFor: 'Results for "{{query}}"',
      tip: 'Tip: Try searching for the brand and model name, like "TC Electronic Ditto Looper"',
      mobileOr: '- Or -',
      searchButton: 'Search',
      clearSearchQuery: 'Clear search query',
      sellV2: 'Try the new sell form experience',
      loadMore: 'Load more results',
      resultsCount: '{{count}} results',
      backToTop: 'Back to top',
    },
    sellV2Toggle: 'Use the new sell form experience',
    autocomplete: {
      productsLabel: 'Products',
    },
    tiles: {
      recommended: 'Recommended based on your search',
      sellButton: 'Sell One Like This',
    },
    marketing: {
      sellYourGear: 'Sell Your Gear',
      moreMoney: 'Keep More of Your Money',
      popular:
        'As the world’s most popular gear website, our sellers gain access to a huge network of musicians and collectors through a broad variety of tools to help them sell, all for the same low fee of 3.5% once it sells.',
      listInMinutes: 'List for Free in Minutes',
      lowFees: 'Low Selling Fees',
      lowFeesBody: "List as many items as you want for free. If they sell, Reverb's selling fee is only 3.5%.",
      reverbProtection: 'Reverb Protection',
      reverbProtectionBody: 'Protect your sale if anything is lost or damaged during shipping.',
      powered: 'Powered by Musicians',
      poweredBody: 'Work 1:1 with one of our resident musicians to help resolve any issues.',
      sellingFee: '{{fee}}% Selling Fee',
      perItem: 'per item',
      feeDescription:
        "List as many items as you want for free. If they sell, Reverb's selling fee is only 3.5% - no matter what.",
      reverbPayments: 'Reverb Payments',
      reverbPaymentsFee: '{{fee}}% + {{currencySymbol}}0.25',
      reverbPaymentsFeeNew: '{{fee}}% + {{currencySymbol}}0.49',
      paymentProcessingFee: 'Payment Processing Fee',
      totalFees: 'Total Fees',
      perTransaction: 'per transaction',
      or: 'or',
      paypal: 'Paypal',
      defaultPaypalFee: 'Up to 4.1% + fixed fee*',
      USDPaypalFee: '2.9% + $0.30*',
      CADPaypalFee: 'Up to 3.49% + $0.49 CAD*',
      EURPaypalFee: 'Up to 3.49% + 0.49€*',
      GBPPaypalFee: 'Up to 3.49% + 49p*',
      AUDPaypalFee: 'Up to 3.6% + fixed fee*',
      NZDPaypalFee: 'Up to 3.9% + fixed fee*',
      MXNPaypalFee: 'Up to 3.95% + fixed commission + 0.50*',
      JPYPaypalFee: 'Up to 4.1% + 40 yen*',
      transactionFee: 'Transaction Fee',
      transactionFeeDescription:
        'Reverb Payments minimizes transaction fees from banks and PayPal along with additional protection other processors don’t provide.',
      transactionFeeAsterisk:
        "*PayPal fees can vary by location. See {{linkOpen}}PayPal's fees page{{linkClose}} for more info.",
      paypalFeeDescription:
        'Every transaction is safe, secure, and supported by real human beings.',
      sellAndShip: 'Reverb Protection',
      sellAndShipDescription:
        "Purchase Reverb Safe Shipping for a fraction of your gear's sale price. If anything is lost or damaged during shipping, our responsive and friendly support team will help resolve the issue quickly.",
      helpCenterLink: {
        href: 'https://help.reverb.com/hc/en-us',
      },
      getStarted: 'Ready to get started?',
      startSelling: 'Start Selling',
      sellerHub: 'Seller Hub',
      sellerHubDescription: 'Your guide to all the ins and outs of selling on Reverb.',
      helpCenter: 'Help Center',
      helpCenterDescription: 'Contact Reverb, or browse our FAQ',
      learnMore: 'Learn more',
      imageAltText: '{{text}} Image',
      illustrationAltText: '{{text}} Illustration',
      whyReverb: {
        title: 'Why should you sell on Reverb?',
        sellingGearTitle: 'Built for selling gear',
        sellingGearDescription: 'Created by musicians, Reverb’s seller tools are designed specifically to make selling instruments seamless.',
        communityTitle: '5-star buyer community',
        communityDescription: 'Millions of high-quality prospective buyers from all over the world search for gear on Reverb.',
        transactionsTitle: 'Secure transactions & safe shipping',
        transactionsDescription: 'You can feel confident selling on a trusted platform with hassle-free shipping options.',
        supportTitle: 'Live musician support',
        supportDescription: 'Our team of gear experts is ready and available to help you make a sale.',
      },
      pricing: {
        title: "Reverb's pricing is simple and straightforward",
        description: 'Listing your gear for sale is free and easy. When your instrument sells, we deduct selling and payment processing fees from the final selling price and you keep the rest.',
        descriptionNew: 'Listing your gear is always free on Reverb. When your instrument sells, you earn 91.8% of the selling price. That’s the final sale price minus selling and payment processing fees.',
        freeListings: 'Unlimited Free Listings & Transparent Pricing',
        feeChangeAnnouncement: 'On {{date}}, the payment processing fee for Reverb Payments is changing.',
        feeChangeLink: 'Learn more about the upcoming change here.',
      },
      promotionalCodes: {
        promoCodeEligible: 'Promo Code Eligible',
        promoCodeNotEligible: 'Promo code could not be applied',
        promoCodeVerifiedMessage: 'Create a listing to waive your first 3 selling fees!',
        promoCodeTerms: "Offer valid for first-time sales through Reverb.com only. No minimum sale required. The discount will apply to the first 3 items in your shop that sell before the date of expiration. The discount only covers the selling fee described in Reverb's Billing Policy and does not cover any additional fees, including Reverb Bump or payment processing fees.",
      },
      protect: {
        title: 'How does Reverb protect sellers?',
        description: 'Whether you’re listing your first instrument for sale or or you’ve already sold your hundredth rig, Reverb has you covered every step of the way.',
        listTitle: 'When you list',
        listTips: 'Reverb’s {{linkOpen}}selling guide{{linkClose}} includes pro tips to help you get the most money for your gear.',
        listPricing: 'Our pricing history data helps you make informed decisions about your asking price when you list your gear for sale.',
        sellTitle: 'When you sell',
        sellCommunity: 'You can feel confident selling to Reverb’s high-quality community of music makers. Our buyers have a 4.99/5 star average rating.',
        sellPayments: 'Through {{linkOpen}}Reverb Payments{{linkClose}}**, we securely process payments for you.',
        shipTitle: 'When you ship',
        shipLabels: 'With {{linkOpen}}shipping labels on Reverb{{linkClose}}**, you’re protected in the unlikely event that your shipment is lost, stolen, or damaged.',
        shipSupport: 'Our global support team of gear experts is ready to jump in and assist with your sale if you have a question or issue.',
      },
      learn: {
        title: 'Learn more about selling on Reverb',
        sellerHubTitle: 'Seller Hub',
        sellerHubDescription: 'Your starting point to learn about selling on Reverb, including information on seller tools, shipping guides, and how-tos.',
        howToTitle: 'How to Sell on Reverb',
        howToDescription: 'This step-by-step guide walks you through listing an instrument for sale, pricing it, and photographing it so that you get more money for your gear.',
        pricingTitle: 'Transparent Pricing',
        pricingDescription: 'Listing an instrument for sale is free, and with our clear and simple pricing, you’ll always know how much of the final sale price you’ll keep and when you’ll get paid.',
        helpCenterTitle: 'Seller Help Center',
        helpCenterDescription: 'Check out our comprehensive Seller Help Center for additional resources on how to sell your gear quickly and at a fair price.',
      },
      disclaimer: {
        eligibleCountries: 'In eligible countries',
        salesTax: "Due to recent changes in the law, online purchases are now subject to sales tax. This includes both new and used items. These are not taxes imposed by Reverb – they're the result of legislation enacted by each individual state. If you still have questions, you can learn more in our Help Center",
      },
    },
    priceRecommendation: {
      graphIconAlt: 'Price Guide graph icon',
      tooltipLabel: 'Price Guide:',
      tooltipText: 'Use recent sales to ensure you\'re getting a fair price and to find the right price for a buyer. Competitively priced gear tends to sell within a week.',
    },
    sellOptions: {
      sell: {
        cta: 'Sell it yourself',
        valueProps: ['Get the most money for your gear', 'Tools to help you list & ship with ease'],
        popoverLabel: 'Reverb Price Guide',
        popoverText: 'This is the price range of sales in Good to Excellent condition. {{linkOpen}}Learn more{{linkClose}}',
      },
      tradeIn: {
        cta: 'Trade in locally',
        valueProps: ['No listing, no shipping, no fees', 'Put money in your pocket faster'],
        popoverLabel: 'Trade-in offer',
        popoverText: 'This is the price range for trade in items in Very Good to Mint condition. {{linkOpen}}Learn more{{linkClose}}',
      },
    },
  },
  listingShippingRateRecommendation: {
    recommendText: 'Current average: {{recommendedAmountDisplay}}',
    basedOn: '{{listingsCountDisplay}} recent listings for this and similar items show an average shipping rate within the Continental U.S. of {{recommendedAmountDisplay}}. What would you like to charge?',
  },
  taxPoliciesForm: {
    calculationTypes: {
      auto: 'Auto',
      custom: 'Custom',
    },
    includeShipping: 'Also apply tax to shipping',
    province: 'Province',
    state: 'State',
    taxRate: 'Tax Rate',
    rate: 'Rate',
  },
  homepage: {
    recentArticles: 'Recent Articles',
    recentlyViewed: 'Recently Viewed',
    recentlyViewedAndMore: 'Recently Viewed & More',
    basedOnRecentlyViewed: 'Based on your recently viewed',
    staffPicksCSPs: 'Reverb Staff Picks',
    justListed: 'Just listed',
    justListedForYou: 'Just listed {{categoryName}}',
    dealsAndStealsForYou: 'Deals & Steals',
    fenderCertifiedPreOwned: 'Fender Certified Pre-Owned',
    fenderCertifiedPreOwnedTooltip: 'Save with confidence when you buy Certified Pre-Owned on Reverb. All gear is professionally inspected, backed by its manufacturer, and—if necessary—refurbished by experts.',
    newListingsYouMightLike: 'New Listings You Might Like',
    fromYourRecentSearch: 'From Your Recent Search \"{{recentSearch}}\"',
    zeroPercentFinancingAvailable: '0% Financing Available',
    mostWatched: 'Most Watched in {{categoryName}}',
    mostWatchedMusicGear: 'Most Watched music gear',
    bestSellingBrands: 'Best-selling brands',
    sellMarketingCallout: {
      title: 'Sell Your Gear on Reverb',
      secureTransactions: 'Secure Transactions & Safe Shipping',
      listFree: 'List for Free in Minutes',
      buyerCommunity: 'Extensive Buyer Community',
      action: 'Sell Your Gear',
    },
    sellVisibilityV2: {
      anyOneCanSell: 'Anyone Can Sell On Reverb',
      sellYourGear: 'Sell Your Gear',
      listItAlt: 'Number one',
      listIt: 'List It',
      dataFromPastSales: 'Data from past sales makes it easy to list your item at the right price.',
      sellItAlt: 'Number two',
      sellIt: 'Sell It',
      millionsOfHighQuality: 'Millions of high-quality buyers from all over the world search for gear on Reverb.',
      shipItAlt: 'Number three',
      shipIt: 'Ship It',
      fromBoxes: 'From boxes & discounted labels to packing guides, we make it easy to ship gear.',
    },
    searchHeader: {
      title: 'Find Your Dream Gear',
      subtitle: 'Join the millions of music lovers who use Reverb to find everything they need to make music',
    },
    loggedOutNew: {
      shopByCategory: 'Shop By Category',
      shopByInstrument: 'Shop By Instrument',
      browseByCategory: 'Browse by category',
      trendingNow: 'Trending Now',
      explore: 'Explore the latest news, gear demos, how-tos, and more',
      gearWeLove: 'Shop gear we love',
      collections: 'Get started by browsing rare, unique, and timeless gear collections',
      latestGear: 'New gear added every minute. What will you find?',
      listYourGear: 'List Your Gear for Free',
      demos: 'Gear Demos, Lessons, Tips & Tricks and More',
      handpicked: 'Handpicked by Reverb',
      latestArticles: 'Latest',
      trendingArticles: 'Trending',
      dealsAndSteals: 'Blink-and-you’ll-miss-it deals. This gear won’t last long.',
    },
    loggedOutEducation: {
      buy: 'Buy',
      buySubheading: 'Buy new, used, and vintage gear from musicians and shops around the world.',
      sell: 'Sell',
      sellSubheading: 'Sell your gear to an audience of over 10 million musicians and collectors.',
      learn: 'Learn',
      learnSubheading:
        'Explore thousands of articles and videos created to help you grow as a player.',
    },
    buyingGuides: {
      buyingGuidesHeading: 'Buying Guides',
      seeMore: 'See More',
    },
    buyProtection: {
      buyWithProtection: 'Buy with Confidence',
      buySubheading:
        'Reverb Protection has you covered. We provide a safe, secure community for finding the gear you want.',
      learnMore: 'Learn More',
      alt: 'Buy Protection Icon',
    },
    sellOnReverb: {
      sellYourGear: 'Sell Your Gear',
      sellGearSubheading:
        'List as many items as you want for free. When an item sells, pay only a 3.5% fee — one of the lowest around.',
      startAListing: 'Make a Listing',
      alt: 'Sell on Reverb Icon',
    },
    brands: {
      brandsHeading: 'Top Brands',
      brandsLinkText: 'See More Top Brands',
    },
    feedCallout: {
      seeMore: 'Your Feed',
    },
    newestListings: {
      title: 'Newest Listings',
    },
    categories: {
      heading: 'Categories',
    },
    personalizedCSPs: {
      title: 'Recommended For You',
    },
    explore: {
      news: 'News',
      priceGuide: 'Price Guide',
      topShops: 'Top Shops',
      brands: 'Brands',
      software: 'Software',
      priceDrops: 'Price Drops',
      giftCards: 'Gift Cards',
      handpickedCollections: 'Handpicked Collections',
      newsAlt: 'News Icon',
      priceGuideAlt: 'Price Guide Icon',
      shopsAlt: 'Shops Icon',
      brandsAlt: 'Browse Brands Icon',
      softwareAlt: 'Software Icon',
      priceDropsAlt: 'Price Drops Icon',
      giftCardsAlt: 'Gift Cards Icon',
      handpickedAlt: 'Handpicked Collections Icon',
    },
    learn: {
      learnHeading: 'Learn',
      learnSubheading: 'Learn to Play',
      mayfieldTitle: 'Riffs in the Key of Curtis Mayfield',
      bowieTitle: 'David Bowie\'s "Life on Mars" on Piano',
      jordanTitle: 'The Meters\' "Cissy Strut" Drum Lesson',
      tommyTitle: 'Tommy Emmanuel Teaches 4 Fingerstyle Steps',
      jacoTitle: 'Riffs in the Key of Jaco Pastorius',
      georgeTitle: 'Riffs in the Key of George Harrison',
      alt: 'Learn on Reverb',
    },
    localeSpecificCallout: {
      moreMusicalWorld: 'Connect with Millions of Musicians Around the Globe',
      affirm: 'Play Now, Pay Later',
      financing: 'Financing from 0% APR',
      learnMore: 'Learn More',
    },
    trendingSearches: {
      trendingIn: 'in {{productCategory}}',
      loggedIn: {
        title: 'Trending Searches for You',
      },
      loggedOut: {
        title: 'Trending Searches',
      },
    },
    categoryUnderPrice: {
      title: '{{productType}} under {{price}}',
    },
    searchRecommendations: {
      totalText: {
        one: '{{formattedCount}} item',
        other: '{{formattedCount}} items',
      },
      recentSearches: {
        title: 'Your recent searches',
      },
      trendingSearches: {
        title: 'Trending searches',
      },
    },
    appCallout: {
      title: 'Never miss a deal with the Reverb app',
      subtitle: 'Be the first to know about price drops on the gear you want.',
      qrCodeHelperText: 'Scan with your camera app to download',
    },
    valueProps: {
      greatDeals: {
        title: 'The gear you love for less',
        subtitle: 'Save an average of 30% when you buy used, B-Stock, and refurbished music gear.',
        ctaText: 'Find a deal',
      },
      shops: {
        title: "If it's music gear, it's here",
        subtitle: 'Find the gear you want from our trusted community of over 200,000 verified sellers.',
        ctaText: 'Explore Top Shops',
      },
      buyerProtection: {
        title: "We've got your back",
        subtitle: "If you run into an issue, we'll help make it right as part of Reverb Buyer Protection.",
        ctaText: 'Shop with confidence',
      },
    },
  },
  watchRow: {
    title: 'From Your Watch List',
    cta: 'Your Watch List',
  },
  popularProductsHeading: 'Popular Products on Reverb',
  autocomplete: {
    recentSearches: 'Recent Searches',
  },
  cspRowTitle: 'Popular Products',
  compareCspRowTitle: 'Compare Prices',
  newCSPRowTitle: 'New Products',
  priceGuideTimeline: {
    soldPrice: 'Sold Price',
    chartHeader: 'Price History',
    chartDescription:
      'Prices reflect monthly average for sales of item in used condition. Prices exclude shipping and tax/VAT/GST.',
  },
  transactionHistory: {
    signUpToSee: 'Create a Reverb account to get free access to our Price Guide:',
    signUp: 'Sign Up Now',
    chartLoader: 'Price History Chart',
    chartDescription:
      'Prices reflect monthly average for sales of item in used condition. Prices exclude shipping and tax/VAT/GST.',
    emptyMessage: 'Sorry, there are not enough sales to display a price history chart.',
    noRecords: 'We don\'t have enough transaction history for this time period to display pricing data.',
    used: 'Used',
    new: 'New',
    next: 'Next',
    prev: 'Previous',
    chartHeader: 'Price History Chart',
    error: 'There was an error while loading history for this item. Check back later!',
    tableHeader: 'Transaction History',
    date: 'Date',
    source: 'Source',
    condition: 'Condition',
    soldPrice: 'Sold Price',
    loading: 'Loading...',
    priceRangeHeader: 'Reverb Estimated Used Price Range',
    excludesVat: 'Prices exclude shipping and tax/VAT/GST.',
  },
  siteSearch: {
    placeholder: 'Shop for used & new music gear...',
    ariaLabel: 'Search',
    cspCompletionCard: {
      availableFrom: '{{count}} available from ',
    },
  },
  checkoutHeader: {
    reverb: 'Reverb',
    viewCart: 'View Cart',
  },
  secureCheckoutMessage: {
    secureCheckout: 'Secure Checkout',
    securityInfo: 'Security Information',
    howDoesReverbSecureInfo: 'At Reverb, your safety and security is our priority',
    cardsUseTLS: 'All credit card information is transferred to Reverb using Transport Layer Security technology (TLS). Reverb does not store full payment card information, only tokens returned to us by our payment processors.',
    stillHaveQuestions: 'Still Have Questions? {{linkOpen}}Contact Reverb Support{{linkClose}}.',
  },
  reverbHeader: {
    myReverb: 'My Reverb',
    navigationRole: 'Secondary',
    reverbLogo: 'Reverb Logo',
    sellCTAFull: 'Sell Your Gear',
    sellCTAMobile: 'Sell',
    watchList: 'Watch List',
    watchListTooltip: 'View all watched gear',
    myFeed: 'My Feed',
    favorites: 'Favorites',
    notifications: 'Notifications',
    notificationCount: {
      one: '1 Notification',
      other: '{{count}} Notifications',
    },
    signUp: 'Sign Up',
    logIn: 'Log In',
    cart: {
      badge: 'Cart',
      viewCart: 'View Cart',
      saleReminderText: '{{discount_value}} off until {{date}} at {{time}}',
      credits: 'Reverb Credits',
      badgeCount: {
        one: '1 item in your cart',
        other: '{{count}} items in your cart',
      },
    },
    userMenu: {
      badge: 'Menu',
      sellYourGear: 'Sell Your Gear',
      myShop: 'My Shop',
      shopSettings: 'Shop Settings',
      selling: 'Selling',
      listings: 'Listings',
      orders: 'Orders',
      dashboard: 'Dashboard',
      sellNow: 'Sell Now',
      buying: 'Buying',
      giftCards: 'Gift Cards',
      purchases: 'Purchases',
      finances: 'Finances',
      earnings: 'Earnings',
      myBill: 'My Bill',
      myAccount: 'My Account',
      myCollection: 'My Collection',
      new: 'New',
      recentlyViewed: 'Recently Viewed',
      messages: 'Messages',
      myProfile: 'My Profile',
      helpCenter: 'Help Center',
      logOut: 'Log Out',
      reverbCredits: 'Reverb Credits',
      payoutBalance: 'Payout Balance',
      userAvatarAlt: 'User Avatar',
      secondaryUser: {
        header: 'Professional Profile',
        logOut: 'Log Out',
        logIn: 'Log In',
      },
      theme: 'Theme',
      tradeIns: 'Trade Ins',
    },
    mobileMenu: {
      badge: 'Menu',
      close: 'Close',
      explore: 'Explore',
      newAndPopular: 'New & Popular',
      dealsAndSteals: 'Deals & Steals',
      priceGuide: 'Price Guide',
      watchList: 'Watch List',
      myFeed: 'My Feed',
      news: 'News',
      joinReverb: 'Join Reverb',
      favorites: 'Favorites',
    },
    exploreMenu: {
      new: 'New',
    },
    skipLink: 'Skip to main content',
  },
  secondaryNagBanner: {
    content: "We'll be doing some scheduled site maintenance on Friday, April 4, from 12:30a.m.-2:00a.m. CT.",
    cta: 'Shop Now',
    dismissButton: 'Dismiss Banner',
  },
  searchBar: {
    placeholders: {
      listings: 'Shop for used & new music gear...',
      news: 'Search for articles...',
      priceGuides: 'Search for pricing info...',
      shops: 'Search shop names...',
    },
    context: {
      listings: 'Gear',
      news: 'Articles',
      priceGuides: 'Price Guide',
      shops: 'Shops',
      handpickedCollections: 'Handpicked Collections',
    },
    in: 'in',
    inputTitle: 'Search Reverb for used & new music gear',
    replaceSearchTerm: 'Replace search term with "{{term}}"',
  },
  next: 'Next',
  paginationGap: 'Pagination Gap',
  previous: 'Previous',
  ellipsis: '...',
  recentListings: 'Recent Listings',
  freeExpeditedShipping: 'Free 2-Day Shipping',
  phoneNumberInput: {
    phoneMinimumDigitsWarning: 'Phone numbers must be at least %{minimumDigits} digits',
  },
  kycNameInput: {
    validationMessage: 'Name cannot contain numbers',
  },
  emailEmbed: {
    subscribe: 'Subscribe',
  },
  articleEmbed: {
    header: 'Related Article',
    readmore: 'Read More',
  },
  calloutEmbed: {
    shopNow: 'Shop now on Reverb',
  },
  categoryNavBar: {
    navigationRole: 'Main',
    theReverbOutlet: 'The Reverb Outlet',
    brands: {
      title: 'Brands',
      guitars: 'Guitars',
      effects: 'Effects & Amps',
      keyboards: 'Keyboards & Synths',
      recording: 'Recording Gear',
      drums: 'Drums',
      dj: 'DJ Gear',
      akai: 'Akai',
      alesis: 'Alesis',
      arturia: 'Arturia',
      behringer: 'Behringer',
      boss: 'Boss',
      dbx: 'dbx',
      dw: 'DW',
      'earthquaker-devices': 'Earthquaker Devices',
      'electro-harmonix': 'Electro-Harmonix',
      elektron: 'Elektron',
      epiphone: 'Epiphone',
      esp: 'ESP',
      fender: 'Fender',
      focusrite: 'Focusrite',
      gibson: 'Gibson',
      gretsch: 'Gretsch',
      ibanez: 'Ibanez',
      jhs: 'JHS',
      keeley: 'Keeley',
      korg: 'Korg',
      ludwig: 'Ludwig',
      'make-noise': 'Make Noise',
      marshall: 'Marshall',
      martin: 'Martin',
      meinl: 'Meinl',
      moog: 'Moog',
      'mutable-instruments': 'Mutable Instruments',
      'native-instruments': 'Native Instruments',
      novation: 'Novation',
      orange: 'Orange',
      paiste: 'Paiste',
      prs: 'PRS',
      pearl: 'Pearl',
      pioneer: 'Pioneer',
      presonus: 'Presonus',
      radial: 'Radial',
      roland: 'Roland',
      sabian: 'Sabian',
      sennheiser: 'Sennheiser',
      shure: 'Shure',
      squier: 'Squier',
      tama: 'Tama',
      tascam: 'Tascam',
      'taylor-guitars': 'Taylor',
      'tc-electronic': 'TC Electronic',
      'teenage-engineering': 'Teenage Engineering',
      'universal-audio': 'Universal Audio',
      vox: 'Vox',
      yamaha: 'Yamaha',
      zildjian: 'Zildjian',
    },
    shops: 'Shops',
    exploreLinks: {
      zeroPercentFinancing: '0% APR Financing Available',
      twoDay: 'Free 2-Day Shipping',
      buyingGuides: 'Buying Guides',
      deals: 'Deals & Steals',
      exclusives: 'Exclusives and Makers',
      priceDrops: 'Price Drops',
      priceGuide: 'Price Guide',
      sellerHub: 'Seller Hub',
      topSellers: 'Top Sellers & Recent Releases',
      helpCenter: 'Help Center',
      pedalMovie: 'The Pedal Movie',
      tradeIn: 'Trade In For Cash',
      warehouse: 'The Warehouse',
    },
    // supercategories for nav header
    guitars: {
      title: 'Guitars',
      archtop: 'Archtop',
      baritone: 'Baritone',
      electric: 'Electric Guitars',
      hollowBody: 'Hollow Body',
      lapSteel: 'Lap Steel',
      leftHanded: 'Left-Handed',
      pedalSteel: 'Pedal Steel',
      semiHollow: 'Semi-Hollow',
      solidBody: 'Solid Body',
      tenor: 'Tenor',
      mini: 'Mini/Travel',
      twelveString: '12-String',
      resonator: 'Resonator',
      jumbo: 'Jumbo',
      concert: 'Concert',
      vintageAcoustic: 'Vintage Acoustic Guitars',
      vintageElectric: 'Vintage Electric Guitars',
      fourString: '4-String',
      fiveString: '5-String or More',
      shortScale: 'Short Scale',
      fretless: 'Fretless',
      acousticBass: 'Acoustic Bass',
      vintageBass: 'Vintage Bass Guitars',
      dreadnought: 'Dreadnought',
      classical: 'Classical',
      omAndAuditorium: 'OM and Auditorium',
      parlor: 'Parlor',
      guitarPickups: 'Guitar Pickups',
      guitarBodies: 'Guitar Bodies',
      necks: 'Guitar Necks',
      bridges: 'Guitar Bridges',
      pickguards: 'Pickguards',
      tuningHeads: 'Tuning Heads',
      knobs: 'Knobs',
      bassGuitarParts: 'Bass Guitar Parts',
      bassPickups: 'Bass Pickups',
      acousticPickups: 'Acoustic Pickups',
      bridgePins: 'Bridge Pins',
      strings: 'Strings',
      accessories: 'Accessories',
      bassGuitars: 'Bass Guitars',
      acousticGuitars: 'Acoustic Guitars',
      guitarParts: 'Guitar Parts and Accessories',
      playerGradeVintage: 'Player Grade Vintage Guitars',
      fenderBassGuitars: 'Fender Bass Guitars',
      artisanalAcoustics: 'Artisanal Acoustics',
      collectorGradeVintage: 'Collector Grade Vintage Guitars',
      priceDrops: 'Price Drops',
      dealsAndSteals: 'Deals and Steals',
      selling: 'Selling Resources',
      popularProducts: 'Popular Products',
      fenderStratocaster: 'Fender Stratocaster',
      fenderTelecaster: 'Fender Telecaster',
      gibsonLesPaul: 'Gibson Les Paul',
      epiphoneLesPaul: 'Epiphone Les Paul',
      gibsonSg: 'Gibson SG',
      fenderMustang: 'Fender Mustang',
      fenderJaguar: 'Fender Jaguar',
      fenderJazzmaster: 'Fender Jazzmaster',
      prsSilverSky: 'Paul Reed Smith Silver Sky',
    },
    pedalsAmps: {
      title: 'Pedals and Amplifiers',
      effectsAndPedals: 'Effects and Pedals',
      overdriveAndBoost: 'Overdrive and Boost',
      fuzz: 'Fuzz',
      delay: 'Delay',
      reverb: 'Reverb',
      multiEffectUnit: 'Multi-Effects Pedals and Processors',
      distortion: 'Distortion',
      loopPedalsAndSamplers: 'Loop Pedals and Samplers',
      chorusAndVibrato: 'Chorus and Vibrato',
      compressionAndSustain: 'Compressor and Sustain',
      octaveAndPitch: 'Octave and Pitch',
      tremolo: 'Tremolo',
      flanger: 'Flanger',
      phaseShifters: 'Phase Shifters',
      wahsAndFilters: 'Wahs and Filters',
      pedalUtilities: 'Pedal Utilities',
      pedalboardPowerSupplies: 'Pedalboards and Power Supplies',
      tuningPedals: 'Tuning Pedals',
      eq: 'EQ',
      controllersVolumeAndExpression: 'Controllers, Volume, and Expression',
      ampModeling: 'Amp Modeling',
      cabSims: 'Cab Sims',
      amps: 'Amplifiers',
      guitarCombos: 'Guitar Combos',
      guitarHeads: 'Guitar Heads',
      modelingAmps: 'Modeling Amps',
      smallAmps: 'Small Amps',
      bassHeads: 'Bass Heads',
      guitarCabinets: 'Guitar Cabinets',
      bassCombos: 'Bass Combos',
      attenuators: 'Attenuators',
      bassCabinets: 'Bass Cabinets',
      acousticAmps: 'Acoustic Amps',
      vintageAmps: 'Vintage Amps',
      playerGradeVintage: 'Player Grade Vintage Amps',
      tubeScreamers: 'Tube Screamers',
      priceDrops: 'Price Drops',
      dealsAndSteals: 'Deals and Steals',
      selling: 'Selling Resources',
      pedalMovie: 'The Pedal Movie',
      popularProducts: 'Popular Products',
      ehxBigmuff: 'Electro-Harmonix Big Muff',
      ibanezTubescreamer: 'Ibanez Tube Screamer',
      jhsMorningGlory: 'JHS Morning Glory',
      dittoLooper: 'TC Electronic Ditto Looper',
    },
    synths: {
      title: 'Keyboards and Synths',
      keyboards: 'Keyboards',
      controllers: 'MIDI Controllers',
      digitalPianos: 'Digital Pianos',
      workstations: 'Workstation Keyboards',
      acousticPianos: 'Acoustic Pianos',
      organs: 'Organs',
      electricPianos: 'Electric Pianos',
      synths: 'Synths',
      analog: 'Analog',
      digital: 'Digital',
      eurorack: 'Eurorack',
      modular: 'Modular',
      rackmount: 'Rackmount',
      vintageSynths: 'Vintage Synths',
      miniSynths: 'Mini Synths',
      beatProduction: 'Beat Production',
      drumMachines: 'Drum Machines',
      samplers: 'Samplers',
      padControllers: 'Pad Controllers',
      software: 'Software',
      modernProductionGear: 'Modern Electronic Production Gear',
      priceDrops: 'Price Drops',
      dealsAndSteals: 'Deals and Steals',
      selling: 'Selling Resources',
      popularProducts: 'Popular Products',
      akaiMpc: 'Akai MPC',
      rolandJuno: 'Roland Juno',
      sequencers: 'Sequencers',
      desktopSynths: 'Desktop Synths',
      keyboardSynths: 'Keyboard Synths',
      grooveboxes: 'Grooveboxes',
    },
    recording: {
      title: 'Recording Gear',
      proAudio: 'Pro Audio',
      microphones: 'Microphones',
      interfaces: 'Audio Interfaces',
      '500Series': '500 Series',
      boxedSoftware: 'Boxed Software',
      recordingEquipment: 'Recording Equipment',
      mixers: 'Audio Mixers',
      portableRecorders: 'Portable Recorders',
      diBoxes: 'DI Boxes',
      studioFurniture: 'Studio Furniture',
      patchbays: 'Patchbays',
      software: 'Software',
      outboardGear: 'Outboard Gear',
      compressorsAndLimiters: 'Compressors and Limiters',
      microphonePreamps: 'Microphone Preamps',
      equalizers: 'Equalizers',
      multiEffect: 'Multi-Effects Processors',
      channelStrips: 'Channel Strips',
      adDaConverters: 'AD/DA Converters',
      gatesAndExpanders: 'Gates and Expanders',
      reverbUnits: 'Reverb Units',
      summing: 'Summing',
      delay: 'Delay',
      utility: 'Utility',
      speakers: 'Speakers',
      studioMonitors: 'Studio Monitors',
      poweredSpeakers: 'Powered Speakers',
      passiveSpeakers: 'Passive Speakers',
      studioGradeGear: 'Studio Grade Gear',
      topShelfStudioMicrophones: 'Top Shelf Studio Microphones',
      priceDrops: 'Price Drops',
      dealsAndSteals: 'Deals and Steals',
      selling: 'Selling Resources',
    },
    drums: {
      title: 'Drums',
      acousticDrums: 'Acoustic Drums',
      fullAcousticKits: 'Full Acoustic Kits',
      snares: 'Snares',
      bassDrums: 'Bass Drums',
      toms: 'Toms',
      vintageDrums: 'Vintage Drums',
      electronicDrums: 'Electronic Drums',
      fullElectronicKits: 'Full Electronic Kits',
      modules: 'Modules',
      cymbals: 'Cymbals',
      crash: 'Crash',
      ride: 'Ride',
      hiHats: 'Hi-Hats',
      splashChinaEffects: 'Splash, China, Effects',
      cymbalPacks: 'Cymbal Packs',
      vintageCymbals: 'Vintage Cymbals',
      partsAndAccessories: 'Drum Parts and Accessories',
      heads: 'Heads',
      drumSticksAndMallets: 'Drum Sticks and Mallets',
      stands: 'Stands',
      pedals: 'Pedals',
      casesAndBags: 'Cases and Bags',
      thrones: 'Thrones',
      drumParts: 'Drum Parts',
      mounts: 'Mounts',
      drumKeysAndTuners: 'Drum Keys and Tuners',
      auxiliaryPercussion: 'Auxiliary Percussion',
      handDrums: 'Hand Drums',
      concertPercussion: 'Concert Percussion',
      concertCymbals: 'Concert Cymbals',
      gongs: 'Gongs',
      concertSnareDrums: 'Concert Snare Drums',
      timpani: 'Timpani',
      concertBassDrums: 'Concert Bass Drums',
      practicePads: 'Practice Pads',
      malletPercussion: 'Mallet Percussion',
      bellsAndGlockenspiels: 'Bells and Glockenspiels',
      xylophones: 'Xylophones',
      marimbas: 'Marimbas',
      marchingBassDrums: 'Marching Bass Drums',
      marchingTomsAndTenors: 'Marching Toms and Tenors',
      marchingCymbals: 'Marching Cymbals',
      playerGradeVintage: 'Player Grade Vintage Drums',
      priceDrops: 'Price Drops',
      dealsAndSteals: 'Deals and Steals',
      selling: 'Selling Resources',
    },
    dj: {
      title: 'DJ and Audio Gear',
      beatProduction: 'Beat Production',
      drumMachines: 'Drum Machines',
      samplers: 'Samplers',
      padControllers: 'Pad Controllers',
      djEquipment: 'DJ Equipment',
      lighting: 'Lighting',
      djControllers: 'DJ Controllers',
      mixers: 'Mixers',
      allInOneDJSystems: 'All-in-One DJ Systems',
      portablePA: 'Portable PA',
      homeAudio: 'Home Audio',
      speakers: 'All Speakers',
      wirelessSpeakers: 'Wireless Speakers',
      headphones: 'All Headphones',
      noiseCancelingHeadphones: 'Noise Canceling Headphones',
      preamps: 'Preamps',
      amplifiers: 'Amplifiers',
      turntables: 'Turntables',
      modernProduction: 'Modern Electronic Production Gear',
      priceDrops: 'Price Drops',
      dealsAndSteals: 'Deals and Steals',
      selling: 'Selling Resources',
    },
    moreCategories: {
      title: 'More Categories',
      folkInstruments: 'Folk Instruments',
      ukuleles: 'Ukuleles',
      harmonicas: 'Harmonicas',
      banjos: 'Banjos',
      mandolins: 'Mandolins',
      accordions: 'Accordions',
      sitars: 'Sitars',
      ouds: 'Ouds',
      bandAndOrchestra: 'Band & Orchestra',
      woodwind: 'Woodwind',
      saxophones: 'Saxophones',
      flutes: 'Flutes',
      brass: 'Brass',
      trumpets: 'Trumpets',
      trombones: 'Trombones',
      string: 'String',
      violins: 'Violins',
      uprightBass: 'Upright Bass',
      software: 'Software',
      daws: 'DAWs',
      samplesAndLoops: 'Samples and Loops',
      reverbDrumMachines: 'Reverb Drum Machines',
      virtualInstruments: 'Virtual Instruments',
      artisanalAcoustics: 'Artisanal Acoustics',
      hifiHeyday: 'Hi-Fi Heyday',
      priceDrops: 'Price Drops',
      dealsAndSteals: 'Deals and Steals',
      selling: 'Selling Resources',
    },

    viewAll: 'View All',
    explore: {
      deals: 'Deals & Steals',
      priceDrops: 'Price Drops',
    },
    curatedByReverb: 'Curated by Reverb',
  },
  resultsCount: {
    countListings: {
      one: '{{num}} listing',
      other: '{{num}} listings',
    },
    countListingsMatching: {
      one: '{{num}} listing matching',
      other: '{{num}} listings matching',
    },
  },
  csp2Filters: {
    expand: 'Show {{count}} More',
    collapse: 'Collapse',
    showAll: 'Show All',
    finish: 'Finish',
    selectFinish: 'Select a finish',
    fretboardMaterial: 'Fretboard Material',
  },
  neoMarketplace: {
    buyNew: 'Buy New',
  },
  marketplace: {
    searchResults: {
      resultsFormatted: 'Showing results for {{children}}.',
      sorryFormatted: 'Sorry, we couldn\'t find any results for {{children}}.',
      tryAnother: 'Try another search or explore the related items below.',
      relatedItems: 'Related Items to Explore',
      youMayAlsoBeInterested: 'You may also be interested in these other items:',
      resultsForQuery: '{{count}} Results for "{{subquery}}"',
    },
    searchFeedback: {
      question: 'Was this search helpful?',
      thanks: 'Thanks for your input',
    },
    querySuggestions: {
      relatedSearches: 'Related searches',
    },
  },
  csp2: {
    notFound: {
      heading: "We don't have any listings for this product right now.",
      feedPrompt: 'Be the first to know when one is listed:',
    },
    sellYours: 'Sell Yours',
    admin: {
      editButton: 'Edit',
      blockReview: 'Block Review',
      reviewIsBlocked: 'This review has been blocked.',
    },
    buyUsed: 'Buy Used',
    buyNew: 'Buy New',
    used: 'Used',
    new: 'New',
    compareListings: {
      one: 'Compare {{count}} Listing',
      other: 'Compare {{count}} Listings',
    },
    featuredListing: 'Featured Listing',
    viewAllUsed: 'View All Used Listings',
    viewAllNew: 'View All New Listings',
    topUsedListings: 'Top Used Listings',
    browseUsed: 'Browse Used',
    featuredUsedListings: 'Featured Used Listings',
    productOverview: 'Product Overview',
    listingSold: 'That listing sold. Check out the similar listings below.',
    feedCallout: 'Follow this product to see new listings in your Feed!',
    moreNewCallout: 'View other sellers',
    usedListingsHeader: 'Buy Used',
    priceGuide: {
      title: 'From the Price Guide',
    },
    viewingFallbackListings: "We couldn't find any listings from sellers in your country. Showing results from everywhere else.",
    allFretboardMaterials: 'All Fretboard Materials',
  },
  comparisonShoppingPages: {
    exploreTitle: 'Explore {{title}}',
    bodyTitle: 'More Information',
    featuredBrand: 'Featured Products',
    searchMarketplaceBrand: 'See more {{brand}}',
    seeMore: 'See Reverb for more',
    viewAll: 'View the Entire Catalog',
    priceFrom: 'from {{price}}',
    noneListed: 'None listed',
    shortPriceFromWithCount: {
      one: '{{count}} from {{tagOpen}}{{price}}{{tagClose}}',
      other: '{{count}} from {{tagOpen}}{{price}}{{tagClose}}',
    },
    priceFromWithHighlightedCount: {
      one: '{{count}} available from {{tagOpen}}{{price}}{{tagClose}}',
      other: '{{count}} available from {{tagOpen}}{{price}}{{tagClose}}',
    },
    priceFromWithCount: {
      one: '{{count}} available from {{price}}',
      other: '{{count}} available from {{price}}',
    },
    priceForFreeCount: {
      one: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
      other: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
    },
    priceForFreeHighlightedCount: {
      one: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
      other: '{{count}} available for {{tagOpen}}{{price}}{{tagClose}}',
    },
    shopNow: 'Shop Now',
    similarCsps: 'Similar Products',
    viewMore: 'View More',
    viewListing: 'View Listing',
    featuredListing: 'Featured Listing',
    readMore: 'Read More ...',
    imageAlt: '{{title}} image {{index}}',
    productDetails: 'Product Details',
    jumpTo: 'Jump To',
    jumpLinks: {
      placeholder: 'Section',
      overview: 'Overview',
      video: 'Video',
      listings: 'Listings',
      gallery: 'Gallery',
      specs: 'Product Specs',
      'similar-products': 'Similar Products',
      'price-guide': 'Price Guide',
      'reviews-section': 'Reviews',
      blurb: 'More Information',
      'product-details': 'Product Details',
    },
  },
  noResults: {
    nothingHere: "Sorry, there's nothing here at the moment.",
    removeShippingRegionFilter: 'Try removing your shipping region filter',
    contactToArrangeShipping:
      "If a seller hasn't set up shipping for your region, you can always contact them to arrange rates.",
    shipsTo: 'Ships to {{shipsTo}}',
    didYouMean: 'Did you mean:',
    fallbackStrategies: {
      fallback_to_or: 'Items related to <strong>{{query}}</strong>:',
    },
    showingNoResultsFor: 'Showing no results for {{children}}',
    trySearchingForMoreResults: 'Try searching {{children}} for more results.',
    searchReturningLowResults: "Your search isn't returning very many results.",
    tryOtherSearches: 'Try some of these other searches for more results.',
    noResultsTryOtherSearches: 'No results found, try some of these other searches for more results.',
  },
  regionalListings: {
    title: 'Listings in {{country}}',
    titleWithThe: 'Listings in the {{country}}',
    subtitle: 'Discover the latest listings in your country',
    saleSubtitle: 'Discover listings on sale in your country',
    viewMore: 'View More',
  },
  cspMiniGridEmbed: {
    seeMore: 'See More',
  },
  listingsRow: {
    seeMore: 'See More',
    moreToExplore: 'More To Explore',
    similarListings: 'Similar Listings',
    combineAndSaveTitle: 'Combine & Save',
    combineAndSaveLedeHtml: '{{tagOpen}}These items ship for free or reduced rates when purchased with other items from {{shopName}}.{{tagClose}}',
    viewAll: 'View all from this shop',
  },
  filterView: {
    all: 'All',
    used: 'Used',
    new: 'Brand New',
  },
  featuredListing: {
    handpickedByReverb: 'Handpicked by Reverb',
    viewNow: 'View Now',
    readMore: 'Read More',
  },
  giftCardCallout: {
    title: 'Give the gift of gear',
    subtitle: 'with a Reverb Gift Card',
    action: 'Shop Now',
  },
  errorDisplay: {
    message: 'Oops, Something went wrong. Please try again.',
    looking: "We've been notified and are looking into it.",
    homepage: 'Reverb Homepage',
    contact: 'Contact Support',
  },
  shop: {
    tabs: {
      listings: 'Listings',
      feedback: 'Feedback',
      info: 'Info & Policies',
    },
    saleRow: {
      subtitle: '{{discountValue}} off until {{date}}',
    },
    feedback: {
      byline: '{{name}} – {{date}}',
      for: 'Feedback for {{name}}',
      buyer_count: 'Buyer ({{count}})',
      seller_count: 'Seller ({{count}})',
      all_count: 'All ({{count}})',
      seeMore: 'See More',
      all: 'All (0)',
      buyer: 'Buyer (0)',
      seller: 'Seller (0)',
      noFeedbackIcon: 'No feedback icon',
      noFeedbackHere: "There's no feedback here yet.",
    },
    feedbackSummary: {
      title: 'Feedback Summary for Selling & Buying',
      positive: 'Positive',
      negative: 'Negative',
      count: 'Count',
      sixMonths: '6 Mo.',
      twelveMonths: '12 Mo.',
      lifetime: 'Lifetime',
    },
    listingModule: {
      ratings: {
        one: '1 Rating',
        other: '{{count}} Ratings',
      },
      joined: 'Joined {{date}}',
      sellerAchievements: 'Seller Achievements',
    },
  },
  shopHeader: {
    message: 'Send Message',
    promoteShop: 'Promote Shop',
    editShop: 'Edit Shop',
    freeShipping: '{{tagOpen}}Free Shipping{{tagClose}} - Spend {{displayAmount}} or more at {{shopName}} and get free Standard shipping at checkout',
  },
  shopInfo: {
    about: 'About {{name}}',
    shippingPolicy: 'Shipping Policy',
    returnPolicy: 'Return Policy',
    shopWebsite: 'Shop Website',
    taxPolicy: 'Tax Policies',
    manufacturersSafetyInformation: 'Manufacturers and Safety Information',
  },
  shopReturnPolicies: {
    new: 'New Items',
    used: 'Used & Vintage Items',
    returnWindow: 'Returnable within {{days}} days of delivery.',
    restockingFee: 'Restocking Fee: {{amount}}%',
    generalTerms: 'General Terms',
    generalTermsDescription: 'Items must be returned in original, as-shipped condition with all original packaging and no signs of use. Buyer assumes responsibility for all return shipping costs unless the item was not received as described.',
    refundTextWithoutRestockingFee: 'Buyer receives full refund in their original payment method less any shipping costs.',
    refundTextWithRestockingFee: 'Buyer receives a full refund in their original payment method less any shipping costs and a {{restockingFee}}% restocking fee.',
    refunds: 'Refunds',
    special: 'Special Conditions',
    finePrint: 'Make sure to check the return policy on each individual listing as some items may be sold "As-Described" and not eligible for a return.',
    noPolicies: 'This shop has not set a return policy.',
    noPoliciesMessage: 'You can send them a message to ask about their return policy.',
    notReturnable: 'Not Returnable',
    specialConditions: 'Special Conditions',
  },
  shopTaxPolicies: {
    noPolicies: 'This seller doesn\'t charge tax on their sales.',
    calculatedAtCheckout: 'Calculated at checkout',
    regionCode: 'United States - {{code}}',
    taxPolicies: 'Tax Policies',
    region: 'Region',
    rate: 'Rate',
    marketplaceRegion: '*Marketplace Facilitator State. Reverb collects tax on the seller\'s behalf.',
    learnMore: 'Learn More',
  },
  emptyShop: {
    vacation: {
      title: '{{name}} is currently away',
      subtitle: 'When they get back, you\'ll be able to browse & buy their listings.',
      action: 'In the meantime, you can follow them to see their new listings in your Feed:',
    },
    shopOnVacation: 'Heads up! This shop is currently away.',
    seeTopShops: 'See Top Shops on Reverb',
    nothingInShopMatchingSearch: "This shop doesn't have any listings matching that search.",
    expandSearchToAllShops: 'Expand your search to all Reverb shops for more results',
    shopHasNoListings: "This shop doesn't have any listings right now.",
    yourShopIsEmpty: 'Your shop is empty right now. Why not list some gear?',
    listAnItem: 'List an Item',
    noListings: 'No Listings',
  },
  curation: {
    addToSet: 'Add to Curated Sets',
    curateButton: 'Curate',
    bumpButton: 'Bump',
    bumped: 'Bumped',
    doneCurating: 'Done',
    error: 'Error',
    feature: 'Feature in Set',
    featuredInSet: 'Featured',
    newSet: 'New Set',
  },
  bumpsView: {
    header: 'Reverb Bump',
    promotedListings: 'Promoted Listings',
    learnAboutBump: 'Learn About Reverb Bump',
    whatIsBump: 'What is Reverb Bump?',
    findOutHow: 'Find out how you can "Bump" your listings.',
  },
  saleInformation: {
    discount_without_code: '<strong>Save {{discountValue}} at Checkout</strong>',
    sold: 'Sold',
    createdBy: 'Created By ',
    saleBegins: 'Sale Begins {{dateTime}}',
    saleEnds: 'Sale Ends {{dateTime}}',
    saleEnded: 'Sale Ended {{dateTime}}',
    action: 'Shop {{count}} Listings',
    searchPrompt: 'Search in this sale...',
    ineligible: {
      ended: {
        title: 'Sorry, this sale has ended',
      },
      loginRequired: {
        title: 'Log in to view the stellar deals',
        description1: "If you don't have an account, you can easily ",
        link: 'create one',
        description2: ' to access this sale.',
        callToAction: 'Log In to Your Account',
      },
      mustBeFirstPurchase: {
        title: 'Sorry, this sale is just for your first purchase',
        description:
          "Looks like you've made a purchase on Reverb in the past, but don't worry. There are still tons of gear deals every day at Reverb.",
      },
      mustBeOnMobile: {
        title: 'Sorry, this sale can only be accessed from the Reverb App',
        description:
          "Check out the sale on your Reverb App. If you don't already have the app, download it and log in. This sale is worth it!",
      },
      mustBeActivated: {
        title: 'Please confirm your email to view this sale',
        description: "You're almost there! Just click the confirm button in the email we sent you.",
        callToAction: 'Show me how',
      },
      mustBeStudent: {
        title: 'You must have a student (.edu) email address to view this sale',
      },
      notFirstSalePurchase: {
        title: 'Sorry, you can only purchase one item from this sale',
        description:
          "Looks like you've made a purchase from this sale, but don't worry. There are still tons of gear deals every day at Reverb.",
      },
      timeExpired: {
        title: 'Sorry, access to this sale has ended',
      },
      default: {
        title: "You can't buy from this sale right now.",
        description:
          "Either you don't meet the requirements or this sale hasn't started yet. Don't worry though, we've still got tons of gear deals for you.",
        callToAction: 'See All Gear Deals',
      },
    },
  },
  ribbonView: {
    bidNow: 'Bid Now',
    percentOff: '{{percentage}} Off',
  },
  addFollowToFeed: {
    readableTitle:
      'Items matching {{strongOpen}}{{titleText}}{{strongClose}} will be added to your Feed as they are listed.',
    chooseCategory: 'Choose a category for this saved search:',
    registrationPrompt: 'add this page to your Feed',
    addToMyFeed: 'Add to Feed',
    followThis: 'Follow this {{type}}',
    followType: {
      brand: 'Brand',
      category: 'Category',
      collection: 'Collection',
      product: 'Product',
      search: 'Search',
      shop: 'Shop',
    },
    followModalTitle: 'Add this {{type}} to your Feed',
    followers: 'Followers',
    save: 'Save & Continue',
    addToMyFeedResponsive: '{{desktopOnly}}Add to My {{endDesktopOnly}} Feed',
    followSearch: 'View all matching listings',
    hasAddedToMyFeedResponsive: 'Added{{desktopOnly}} to My Feed{{endDesktopOnly}}',
    hasAddedToFeed: 'Added to Feed',
    tooltipText: 'Filter this search further to add it to your Feed.',
    profileDisabledTooltipText: 'No need to follow yourself.',
    addToGearAlerts: 'Also add this to my Daily Feed email',
    removeFromGearAlerts: 'Remove from my Daily Feed email',
    newFollowHeader: 'Add this search to your Feed',
    newProfileFollowHeader: "Add this person's picks and listings to your Feed",
    filterByPrice: 'Filter listings by price',
    priceMinLabel: 'From',
    priceMaxLabel: 'To',
    usedOnly: 'Only show me used listings',
    letGearComeToYou: 'Let the Gear Come to You',
    followForFeedUpdatesHtml: 'Follow this {{type}} to get new listing updates in %{linkOpen}your Feed%{linkClose}.',
    youAreFollowingHtml: "You're Following this {{spanOpen}}{{type}}{{spanClose}}",
    itemsMatchingHtml: 'Items matching this {{type}} will be added to %{linkOpen}your Feed%{linkClose} as they are listed .',
    followBannerType: {
      brand: 'brand',
      category: 'category',
      shop: 'shop',
      collection: 'collection',
      search: 'search',
      product: 'product',
    },
    create: {
      header: 'Add to my Daily Feed',
      description: 'Get emails when new listings match this search.',
      submit: 'Yes, add this to my Daily Feed',
    },
    remove: {
      removeFromFeed: 'Remove from Feed',
      cancel: 'No',
      confirm: 'Yes',
      removeHint: "Matching items will be removed from your Feed and we won't add any new ones.",
      description: "You'll get an email when new items are added.",
      areYouSure: 'Are you sure?',
      header: 'Success!',
      submit: 'Remove from my Daily Feed',
    },
    update: {
      header: 'Saved Search Options',
      submit: 'Save Changes',
    },
  },
  comparisonShoppingHeader: {
    comparePrices: 'Compare Prices',
    conditions: {
      new: 'new',
      used: 'used',
    },
    from: 'from',
  },
  sidebar: {
    anywhere: 'Anywhere',
    eu: 'European Union',
  },
  sidebarAds: {
    giftCardsEasier: 'Gift Cards are Easier to Wrap',
    shopGiftCards: 'Shop Gift Cards on Reverb »',
    sellSafely: 'Sell Safely On Reverb',
    sellerProtectionAndDedicatedTeam: 'Seller Protection and a Dedicated Team of Experts',
    explore: 'Explore »',
    shippingResources: 'Shipping Resources',
    shippingResourcesSubhead: 'Shipping materials, discounted labels, and tips from our team of musicians.',
    learnMore: 'Learn More',
  },
  searchContextBar: {
    query: ' for "{{query}}"',
  },
  searchFilterPresets: {
    header: 'Explore More',
  },
  articleSearchPrompt: {
    buttonText: 'Search in Reverb News ({{numberOfArticles}})',
  },
  cspMiniGrid: {
    explore: 'Explore this collection',
  },
  feed: {
    title: 'The Latest from Your Feed',
    shortTitle: 'From Your Feed',
    seeMoreCTA: 'See More',
    seeMore: 'Load More',
    seePrev: 'Show Newer Items',
    noFilteredResults: 'Sorry, nothing in your Feed matches that search at the moment.',
    viewMarketplaceSearch: 'View Search on Reverb',
    remove: 'Remove',
    removeTooltip: 'Hide this listing from your Feed',
    tabs: {
      feed: 'My Feed',
      watchList: 'Watch List',
      wishList: 'Wish List',
      recentlyViewed: 'Recently Viewed',
      customize: 'Customize Feed',
      recommended: 'Recommended',
    },
    management: {
      button: 'Manage My Feed',
    },
    onboarding: {
      newFeed: 'Check Out Your New Feed',
      intro: 'Choose one or more of our most popular handpicked collections to get started.',
      title: 'Start adding gear to your Feed!',
      description:
        'When you find a category or search result that you like, simply hit the “Follow” button in the upper right-hand corner and those results will appear here. ',
      learnMore: 'Learn More',
      addGear: 'Start Adding Gear',
      alreadyHaveAccount: 'Already have an account?',
      login: 'Log In',
    },
    empty: {
      title: 'Hang on, still sound checking',
      description:
        "When new items come in, they'll show up here.  If you're following rare gear, you'll see it here as soon as it's listed.",
      wantMore: 'Want more in your Feed? ',
      addMore: 'Add Deals and Steals to your Feed!',
    },
  },
  feedCustomization: {
    savedSearches: "Searches you're following",
    shops: "Shops you're following",
    profiles: "People you're following",
    collections: "Collections you're following",
    articles: 'Reverb News',
    articleSaveNotice: 'Your Feed preferences have been updated',
    savedSearchLink: 'Explore Listings',
    savedSearchCallout: 'Follow searches on our marketplace to save time finding the perfect gear.',
    collectionLink: 'Explore Collections',
    collectionCallout: 'Follow Handpicked Collections to see the latest listings selected by our expert staff.',
    shopLink: 'Explore Shops',
    shopCallout: 'Follow your favorite Shops on Reverb to quickly find their newest items.',
    articlesCallout: 'Choose the categories of articles you\'d like to see in your Feed:',
    articlesLink: 'Reverb News',
    articlesButton: 'Customize Categories',
    articlesModal: {
      header: 'Customize Reverb News Categories',
      description: "Choose what you'd like to see in your Feed:",
    },
    header: {
      title: 'Make your Feed yours.',
      description: "Manage searches, brands, shops, collections, and news topics you're following.",
      altTitle: 'Set up your Feed',
      altDescription: 'Get a personalized collection of just what you want to see.',
      addToFeed:
        'Add searches, categories, handpicked collections, article types, and shops that interest you using the “Follow” button.',
      feedRefreshed:
        'Your Feed will update with hits matching your chosen searches. Delete old searches and add new ones at any time.',
      dailyFeedEmail:
        'Want to be notified when new listings are added to your Feed? Add a search to your Daily Feed Email to get a summary of your latest finds.',
      addToFeedAlt: 'Add Searches',
      feedRefreshedAlt: 'Feed Refreshed',
      dailyFeedEmailAlt: 'Get a Daily Recap',
    },
  },
  recentlyViewed: {
    title: 'Recently Viewed Listings',
    seeMore: 'View All',
    clearBrowsingHistory: 'Clear Recently Viewed',
    emptyState: {
      noRecentlyViewed: 'What are you waiting for? Start browsing!',
      noRecentlyViewedMessage: 'It looks like you haven’t viewed any items recently. Start exploring Reverb now and your viewing history will appear here.',
      viewCategories: 'View Categories',
      viewHandpicked: 'View Handpicked',
      flashSuccess: 'Your browsing history has been deleted.',
    },
  },
  getTheApp: {
    header: 'Download the Reverb App',
    subtitle: 'Buy, sell, and discover wherever you go.',
    lead: 'A World of Gear Wherever You Go',
    iosAlt: 'iOS app store button',
    androidAlt: 'Android play store button',
  },
  homepageCuratedSetRow: {
    edit: 'Edit',
    title: 'Handpicked Collections',
    cta: 'See More Handpicked Collections',
  },
  newsDropdown: {
    newsAndReviews: 'News & Reviews',
    tips: 'Tips & How-Tos',
    interviews: 'Interviews',
    gearHistory: 'Gear History',
    demos: 'Demos',
    videos: 'Videos',
    viewAll: 'View All Articles',
  },
  newsletterSignup: {
    header: 'Have you tuned in to Reverb yet?',
    title: 'Get the Best of Reverb in Your Inbox',
    done: 'Done! Stay tuned for the latest from Reverb.',
    tagline: 'Subscribe to get top gear picks and original articles sent straight to your inbox.',
    button: 'Subscribe',
    placeholder: 'Email address',
    alt1: 'Newsletter 1',
    alt2: 'Newsletter 2',
    invalid: 'Please enter a valid email address.',
    error: 'That did not work. Please try again.',
    emailTooLong: 'That email address is too long. Please enter a valid email address.',
  },
  searchSignup: {
    header: 'Let the listings come to you!',
    description_before: 'Get a daily email with new listings matching the search ',
    description_after: '.',
    emailPlaceholder: 'Your Email',
    newsletterSubscribe: "Email me Reverb's newsletter with exclusive deals",
    submit: 'Send Me Listings',
    confirm: 'Got it! Keep an eye out for your fresh finds.',
    error: 'Oops! We encountered an error. Please try again.',
    loginMessage: 'add this search to your Feed',
    fullLoginMessage: 'Create a Reverb account or sign in to get started on your Feed!',
  },
  homepageLinks: {
    priceGuide: 'Price Guide',
    sync: 'Reverb SYNC',
    news: 'News',
    priceDrops: 'Price Drops',
    topShops: 'Top Shops',
    giftCards: 'Gift Cards',
    brands: 'Browse Brands',
    handpickedCollections: 'Handpicked Collections',
  },
  curatedSetRow: {
    header: 'Handpicked by Reverb',
  },
  curatedSetButton: {
    addTooltip: 'Add this to your {{linkStart}}picks{{linkEnd}} to share your taste.',
    dismiss: 'Ok, dismiss',
    addedTooltip: 'Added to your {{linkStart}}picks{{linkEnd}}.',
    addToHandpicked: 'Add to Picks',
    addedToHandpicked: 'Added to Picks',
    sharedToFollowers: {
      one: "Shared to {{count}} follower's Feed.",
      other: "Shared to {{count}} follower's Feeds.",
    },
  },
  curatedSetTooltip: {
    yourNotes: 'Your Notes',
    placeholder: 'What makes this interesting? Why do you like it?',
    save: 'Save',
    charactersRemaining: '{{count}} characters left',
  },
  userCuratedSet: {
    curatedBy: 'Curated by',
  },
  itemShow: {
    endedListingExplore: 'Explore available listings',
    similarCsps: 'Similar Products',
    similarListings: 'Similar Listings',
    promotedSimilarListings: 'Promoted Similar Listings',
    relatedArticles: 'Reverb News',
  },
  categoryCard: {
    countProducts: {
      one: '{{num}} product',
      other: '{{num}} products',
    },
  },
  holidaySale: {
    searchInSale: 'Search in this sale...',
    saleHeadline: '15% Off or More, Instant Discount at Checkout',
    brandsInSale: 'Shop This Sale by Brand',
    viewAll: 'View All',
    pedalSale: 'Pedal Brands On Sale',
  },
  brandCards: {
    relatedBrands: 'Related Brands',
  },
  brandPage: {
    moreCategories: 'More from {{brandName}}',
    seeMoreProducts: 'See More',
    articlesHeader: '{{brandName}} in Reverb News',
    priceDrops: 'Price Drops',
    seeMorePriceDrops: 'More Price Drops',
    recentListings: 'Recent Listings',
    seeMoreRecentListings: 'More Recent Listings',
    featuredProducts: 'Popular Products',
    expandedCategoryHeader: '{{categoryName}}',
    handpickedCollections: '{{brandName}} in Handpicked Collections',
    cspGridTitle: 'More {{category}} from {{brand}}',
    cspGridTitleSmallBrand: 'More from {{brand}}',
    otherListings: 'Other {{category}}',
    moreOtherListings: 'See more',
    searchBoxPlaceholder: 'Search in this brand',
    stepOneAltText: 'step one',
    stepTwoAltText: 'step two',
    stepThreeAltText: 'step three',
  },
  onboardingAddressForm: {
    streetName: 'Street Name',
    houseNumber: 'House / Building Number',
    region: 'State / Province / Region',
    select: 'Select...',
  },
  selectableCreditCardList: {
    processedWithEncryption: 'Securely processed with encryption',
    creditCard: 'Credit Card',
    addNewCard: 'Add New Card',
    cannotAddNewCard: 'Looking to add a card? You have reached the 5 card limit. To add another card, please delete one of the saved cards above.',
    chooseACard: 'Choose A Card',
    expiresOn: 'Expires %{date}',
    edit: 'Edit',
    change: 'Change',
  },
  creditCardForm: {
    nameOnCard: 'Name on Card',
    nameOnCardPlaceholder: 'First and Last name',
    cardNumber: 'Card Number',
    expiration: 'Expiration',
    cvv: 'CVV/CVC',
    securityCode: 'Security Code',
    expirationMonthPlaceholder: 'MM',
    expirationYearPlaceholder: 'YY',
    country: 'Country',
    postalCode: 'Postal Code',
    storedSecurely: 'Stored securely with encryption',
    encryptedCardNumberError: 'Invalid card number',
    encryptedExpiryDateError: 'Invalid expiration date',
    encryptedSecurityCodeError: 'Invalid security code',
    pleaseReverifyCvv: 'Please re-enter the CVV on this card',
    cvvLocation: 'This is the 3 or 4 digit security code usually found on the back of the card.',
    errorVerifying: 'Error verifying CVV, please try again',
    verifyCvvButton: 'Verify CVV',
    verifyCvvForAdditionalSecurity: 'This is an additional security step we require when you ship to a new address for the first time or have recently edited your shipping address.',
    cvvDefaultLocation: '3 digits on back of card',
    cvvDefaultAlt: 'Security code location diagram',
    cvvAmexLocation: '4 digits on front of card',
    cvvAmexAlt: 'Amex security code location diagram',
  },
  identityVerification: {
    updatePaymentInformation: 'Update your payment information',
    adyenLinkExplanation: 'This button will take you to Adyen, our provider who verifies your information to ensure you are paid for sales securely.',
    passport: 'passport',
    license: 'license',
    title: 'Payments Verification',
    subtitle: "Here's what we have, and what we may need",
    taskListErrorTitle: 'Action Needed',
    taskListErrorSubtitle: 'We need some info in order for you to keep selling.',
    taskListVerifiedTitle: "You're Verified!",
    taskListVerifiedSubtitle: 'Selling a lot? We may be asking you for more information later on.',
    updateTip: 'By providing updated documentation, you will restart this step of the verification process',
    updateInformation: 'Update your information',
    photoIdDescription: {
      required: 'A photo ID proves who you are as a Reverb.com Seller.',
      error:
        'Your photo ID verification was unsuccessful. Please check that your photo meets the minimum requirements and try again.',
      default:
        "We're required to ask for photo ID when you reach %{amount} in sales or if we can't verify your personal information.",
      update: 'Upload a new ID',
    },
    personalInformation: {
      title: 'Personal Information',
      description:
        'This includes your name, date of birth, phone number, and address.',
      updateData: 'Update Personal Information',
      uploadPhotoID: 'Upload Photo ID',
      needToFixInfo: 'I need to fix some information above',
      dataShouldMatchGovDocs:
        'Make sure the shop owner data matches what would be written on government issued documents.',
      uploadPhotoIdToVerify:
        'If the information above is accurate we need you to upload a photo ID to finish verifying your identity.',
      correctMyInfo: 'Correct my information',
      everythingIsCorrect: 'Everything above is correct',
    },
    requirements: {
      title: 'Photo Requirements',
      size: 'No larger than 4MB',
      format: 'Must be a jpeg, jpg, pdf, or png file',
      expiration: 'Cannot be expired',
    },
    companyInformation: 'Company Information',
    companyInformationDescription:
      'This includes your legal business name, VAT number or Tax ID, business registration number, business phone number, and business address.',
    verifyMyIdentity: 'Verify My Identity',
    notRequired: 'Not Required',
    processing: 'Processing...',
    complete: 'Complete',
    uploaded: 'Uploaded',
    dataProvided: 'Data Provided',
    accountAddressForm: {
      title: 'Business Address',
    },
    verifyAction: {
      company: 'Verify Company Data',
      identity: 'Verify Personal Data',
      bankAccount: 'Upload Bank Document',
      photoId: 'Upload Photo ID',
    },
    updateCompany: {
      title: 'Company Verification',
      explanation:
        'We need a company registration document issued by a reliable, independent source such as the national commercial register of the country where the business is registered in.',
      inputLabel: 'Company Registration Document',
      backToInput: 'Back to Company Information',
      shopOwner: 'Shop Owner',
      subtitle: 'Please correct any inaccuracies in your business or shareholder data',
      uploadRegistrationPrompt: 'Need to upload a company registration document for your verification? Click below to upload.',
      uploadCompanyRegistrationDocument: 'Upload Company Registration Document',
      uploadSubtitle: 'Verifying your company information ensures the money you earn stays safe.',
      formSubmit: 'Upload Company Registration Document',
      uploadGuidelines: {
        currentDates: {
          title: 'Current Dates',
          description: "Make sure the registration document you're uploading is no older than 1 year, or contains a signature and a state of affairs with the date not older than one year.",
        },
        clearImages: {
          title: 'Clear Images',
          description:
            'Try and avoid blurry images. If they’re too blurry we may ask you to reupload.',
        },
        imageRequirements: {
          title: 'Image Requirements',
          description:
            'Images must be larger than 100KB and smaller than 4MB and be either a: png, jpg, jpeg, or pdf file.',
        },
      },
    },
    bankAccount: {
      title: 'Bank Account Verification',
      subtitle: 'Verifying your bank account ensures the money you earn stays safe.',
      uploadFailedSubtitle:
        'Your bank statement verification was unsuccessful. Please check that your photo meets the minimum requirements and try again.',
      explanation:
        'We need a %{tagOpen}voided check%{tagClose} or a %{tagOpen}bank statement%{tagClose} to prove you are the owner of the bank account on file.',
      inputLabel: 'Bank Document',
      formSubmit: 'Upload Bank Details',
      updateBankInfo: 'Update Bank Account Information',
      likelyBankStatement:
        "If everything above looks correct, it's likely we were unable to verify your bank statement.",
      currentAndVisible:
        'Banks statements must be current and all information must be clearly visible.',
      uploadNewStatement: 'Upload New Bank Statement',
      default: "We're required to ask for bank documentation when you reach %{amount} in sales.",
      bankInfo: 'Bank Information',
      accountNumberLastFour: 'Account Number: %{lastFourOnAccount}',
    },
    bankStatement: {
      uploadGuidelines: {
        accountNumbers: {
          title: 'Account Numbers',
          description: 'Bank account numbers must be visible on the document.',
        },
        currentDates: {
          title: 'Current Dates',
          description: "Make sure the bank document you're uploading is no older than 3 months.",
        },
        bankDetails: {
          title: 'Bank details',
          description: 'Must show bank name and logo on document.',
        },
        clearImages: {
          title: 'Clear Images',
          description:
            'Try and avoid blurry images. If they’re too blurry we may ask you to reupload.',
        },
        imageRequirements: {
          title: 'Image Requirements',
          description:
            'Images must be larger than 100KB and smaller than 4MB and be either a: png, jpg, jpeg, or pdf file.',
        },
        matchingNames: {
          title: 'Matching Names',
          description: 'The name on your ID should match your shop owner name.',
        },
      },
    },
    photoID: {
      title: 'Photo ID',
      photoIDVerification: 'Photo ID Verification',
      subtitle: 'A photo ID proves who you are as a Reverb.com seller.',
      idTypeInput: {
        label: 'Choose ID Type',
        passport: 'Passport (recommended)',
        idCard: 'Government issued ID',
        drivingLicense: 'Driving License (only US or UK)',
      },
      photoIdInput: {
        front: 'ID Front',
        back: 'ID Back',
      },
      licenseInput: {
        front: 'License Front',
        back: 'License Back',
      },
      passportInput: {
        front: 'Photo Page',
      },
      formSubmit: 'Upload Photo ID',
      guidelines:
        'Your Photo ID must be a valid passport, government ID card, or driving license (United States and United Kingdom only).',
      uploadNew: 'Upload New Photo ID',
      uploadGuidelines: {
        title: 'Photo Upload Guidelines',
        currentDates: {
          title: 'Current Dates',
          description: 'Make sure the ID you’re uploading is not expired.',
        },
        clearImages: {
          title: 'Clear Images',
          description:
            'Try and avoid blurry images. If they’re too blurry we may ask you to reupload.',
        },
        nameAndFace: {
          title: 'Name and Face',
          description: 'Photo IDs must clearly show your name and face.',
        },
        frontAndBack: {
          title: 'Front & Back',
          description:
            'Government-issued ID uploads must show front and back of each card.',
        },
        imageRequirements: {
          title: 'Image Requirements',
          description:
            'Images must be larger than 100KB and smaller than 4MB and be either a png, jpg, jpeg, or pdf file.',
        },
        matchingNames: {
          title: 'Matching Names',
          description: 'The name on your ID should match your shop owner name.',
        },
        mrzVisible: {
          title: 'MRZ Visible',
          description:
            'Must show the Machine Readable Zone (%{tagOpen}orange%{tagClose} section in illustration)',
        },
      },
    },
    uploadPercentage: '{{uploadPercentage}}%',
    errors: {
      fileMissing: 'Please choose a file to upload.',
      fileTooLarge: 'This file is larger than 4MB. Please select a smaller file.',
      fileTooSmall: 'This file is smaller than 100KB. Please select a larger file.',
      uploadFailed: 'Uploading the file failed. Please try again.',
      default: 'Something went wrong. Please try again',
    },
    informationOnFile: {
      title: "Here's what you entered previously:",
      nameOnAccount: 'Name on Account',
      accountNumber: 'Account Number',
      address: 'Address',
      shopOwnerLegalName: 'Shop Owner Legal Name',
      dateOfBirth: 'Date of Birth',
      idNumber: 'ID Number',
    },
    backToReview: 'Back to Review',
    chooseOptionToContinue: 'Choose an option to continue...',
    enteredWrong: 'Was something entered wrong?',
    reverifyText: "Click here to update your information and we'll re-verify it.",
    updateShopOwner: 'Update Shop Owner',
    unableToVerify:
      'If everything above looks correct, please upload a photo ID of the shop owner to verify this data.',
    contact: "Having issues? %{tagOpen}Contact us%{tagClose} and we'll give you a hand.",
    signatoryNeeded: "Your account is missing some information. Please choose the 'I need to fix some information above' option below, review all information on the next screen, and click Save to submit your details.",
  },
  informActVerifications: {
    title: 'INFORM Consumers Act Verification',
    description: 'The INFORM Consumers Act aims to create a safe buying experience for marketplace consumers. Reverb is required to collect and verify information from high-volume sellers. ',
    learnMore: 'Learn more',
    taxId: {
      addTitle: 'Add your Tax ID.',
      addedTitle: 'Tax ID added',
      needsAttention: 'Needs Attention',
      acceptedDescription: "You've provided your tax ID and contact information. If this has changed since you last updated, you will need to reverify.",
      needToProvideDescription: "You'll need to provide your tax ID and contact information.",
      addButton: 'Add your Tax ID',
      updateButton: 'Update your Tax ID',
      lastUpdated: 'You last updated on {{date}}.',
    },
    bankAccount: {
      addTitle: 'Add your bank account.',
      addedTitle: 'Bank account added',
      needsAttention: 'Needs Attention',
      acceptedDescription: "You've provided your bank account information. If this has changed since you last updated, you will need to reverify.",
      needToProvideDescription: "You'll need to verify your bank account.",
      addButton: 'Add your bank account',
      updateButton: 'Update your bank account',
      lastUpdated: 'You last updated on {{date}}.',
    },
  },
  coreOnboarding: {
    beingRedirectedFor3ds: 'You are being redirected to verify your identity with your card issuer',
    shopSetupEssentials: {
      title: 'Shop Setup Essentials',
      edit: 'Edit',
      returnPolicies,
      paymentMethods: {
        title: 'How can buyers pay you?',
        recommended: 'Recommended',
        reverbPayments: {
          title: 'Reverb Payments',
          subTitle: 'Accept all major forms of payment on your shop with Reverb Payments.',
          explanation:
            'Once you ship your first item with Reverb Payments you will be asked to connect your bank account. Connecting your bank account allows your earnings to be transferred to your bank automatically.',
          details: {
            lineOne: 'Accept all forms of payment available to Reverb buyers.',
            lineTwo: 'Regardless of how buyers pay, you get paid via direct deposit.',
            lineThree:
              'One payment processing fee of 2.7% + $0.25 for all orders. (3.7% for intl. payments)',
            lineThreeNew:
              'One payment processing fee of 3.19% + $0.49 for all orders. (4.19% for intl. payments)',
            lineFour: 'All orders covered by {{tagOpen}} Reverb Protection {{tagClose}}',
          },
        },
        paypal: {
          shopSettings: 'Shop Settings',
          details: {
            lineOne: 'Accept PayPal {{tagOpen}}only {{tagClose}} on your shop.',
            lineTwo: 'Buyers must have a PayPal account in order to purchase from your shop.',
            lineThree:
              'PayPal payment processing fee of 3.49% + 49¢ (up to 4.99% for intl. payments).',
            lineFour: "PayPal's return window and protection policies apply.",
          },
          form: {
            email: {
              label: 'PayPal Email',
              description: 'This is the email you use for PayPal payments',
            },
          },
          billingMethod: {
            title: 'PayPal Billing Method',
            description: 'A billing method is required to receive payments via PayPal.',
          },
        },
      },
    },
    backToDashboard: 'Back to Dashboard',
    validationErrors: {
      default: 'Sorry there was an error attempting this action. Please try again.',
      invalidVatId:
        'This VAT number appears to be incorrect. Incorrect VAT numbers will slow the approval process.',
    },
    shopBasics: {
      title: 'Shop Basics',
      subTitle: 'Your Details',
      saveButtonText: 'Save and Continue',
      description:
        'This information is required for Reverb Payments. It is only used for setup purposes and will never be shown publicly.',
    },
    sellerVerificationOnboarding: {
      title: 'Trust Verification',
      listingState: 'Draft Listing Saved',
      description: 'We just need a few more details from you before you can publish your listing',
      subTitle: 'Your Information',
      continue: 'Continue',
      verifying: 'We\'re verifying your information',
      verified: 'Your information was verified!',
      fewMinutes: 'This should only take a few minutes',
      countryOfOrigin: 'Select Country of Origin',
      country: 'Country',
      entityType: 'Are you an Individual or Business?',
      business: 'Business',
      individual: 'Individual',
      individualSublabel: 'Submit taxes as an Individual',
      businessSublabel: 'Submit taxes as a Business Owner',
      secondaryBusinessOwners: 'Do you have Secondary Business Owners?',
      secondaryBusinessTrue: 'Yes, I have a secondasry owner(s) with more than 25% stake in the business',
      secondaryBusinessFalse: 'No, I am the sole proprieter',
      whyProvide: 'Why do I need to provide this information?',
      next: 'Next: verifying your information',
      dashboard: 'Now we\'ll take you to complete a few more details in your dashboard.',
      detailedConsents: {
        title: 'Provide Consent to Process Your Data',
      },
      recommendedFields: {
        title: 'Add Your Tax Information',
        personInfo: 'Personal Info',
        location: 'Location',
        firstGivenName: 'First Name',
        middleName: 'Middle Name',
        firstSurName: 'Last Name',
        dayOfBirth: 'Day of Birth',
        monthOfBirth: 'Month of Birth',
        unitNumber: 'Unit Number',
        yearOfBirth: 'Year of Birth',
        streetName: 'Street Name',
        buildingNumber: 'Building Number',
        postalCode: 'Postal Code',
        stateProvinceCode: 'State Province Code',
        streetType: 'Street Type',
        city: 'City',
      },
    },
    truliooOnboarding: {
      title: 'Trust Verification',
      continueButtonText: 'Continue',
      backButtonText: 'Back',
      publishButtonText: 'Publish',
      dashboardSetupNextStep: 'Next: Finish setup in your dashboard.',
      moreInfoNextStep: 'Next: We need a few more pieces of information.',
      returnPolicyNextStep: 'Next: Set your shop return policy.',
      publishListingNextStep: 'Next: Publish your listing.',
      welcomePage: {
        title: 'Let\'s verify your identity',
        cardTitle: 'You\'re on track to publish your first listing',
        cardTitleInformAct: 'You\'re on track to continue selling on Reverb',
        cardSubtitle: 'We just need a few more details to verify your identity. This is a requirement for all marketplaces and helps keep our community safe.',
        cardListTitle: 'What\'s next:',
        moduleListOne: 'We\'ll verify your identity using the info you provide.',
        moduleListTwo: 'You\'ll add a few final details to your Reverb dashboard.',
        moduleListThree: 'That\'s it! Time to publish your listing.',
        nextText: 'Next: Are you an individual or business?',
        backButton: 'Back',
        continueButton: 'Start verification',
        draftListingSavedTag: 'Draft Listing Saved',
        beforeStart: 'Before you start:',
        whatYouNeed: 'You\'ll need a {{openTag}}valid government-issued ID{{closeTag}} handy, as well as access to a phone camera. (If you’re registering as a business, you’ll need your business’ tax documents instead.)',
        whyModal: {
          linkText: 'Why do I need to verify my identity?',
          paragraph1: 'Marketplaces like Reverb are required by law to verify sellers\' identities.',
          paragraph2: 'Once you\'re verified, millions of music makers looking for gear will be able to shop your listing. Plus, you\'ll get access to tools to help you sell easily and our team of gear experts for support.',
          secondHeading: 'How does Reverb keep my info safe?',
          paragraph3: 'To securely verify your info, we partner with Trulioo—a leading identity verification platform trusted by 700 million business entities.',
          paragraph4: 'All data is end-to-end encrypted using industry-standard security technologies. This information is collected to verify your identity and is handled securely.',
          paragraph5: 'This information is collected to verify your identity and may include checking with a credit or licensing agency. By proceeding, you agree to this check and to the terms of our',
          privacyPolicy: 'Privacy Policy',
          learn: 'Learn more about ',
          helpCenterLink: 'account safety and verification in our Help Center',

        },
        upgradeModal: {
          title: 'Update available',
          pleaseUpgrade: 'Please update to the latest version of the Reverb app to continue with verification.',
          confirmButtonText: 'Got it',
        },
      },
      pendingPage: {
        title: 'Identity verification pending',
        titlePsc: 'Identity verification needed',
        cardTitle: 'We’re reviewing your information',
        cardTitlePsc: 'We\'re waiting on all owners to verify',
        cardSubtitle: 'Looks like we need to take a closer look at something. We’ll get back to you within 48 hours.',
        cardSubtitlePsc: 'You\'ll need all of your owners to verify their personal information before being able to sell on Reverb.',
        modalLinkText: 'Why do I need to verify my identity?',
        modalLinkTextPsc: 'Why is my verification pending?',
      },
      navigationButtons: {
        backButton: 'Back',
        continueButton: 'Continue',
      },
      shopTypeInputs: {
        title: 'Are you an individual or business?',
        description: 'This should be based on how you file for taxes.',
        individual: 'Individual',
        individualSublabel: 'Casual seller or sole proprietor',
        individualNote: 'Note: {{openTag}}Your legal first name and last name{{closeTag}} must be on the bank account that you\’ll connect to Reverb for your earnings.',
        businessNote: 'Note: {{openTag}}Your legal business name{{closeTag}} must be on the bank account that you\'ll connect to Reverb for your earnings.',
        business: 'Business',
        businessSublabel: 'Legally registered as a business',
        nextText: 'Next: Provide your seller details.',
        footerNote: 'This information is collected to verify your identity and is handled securely. {{openTag}}Learn more in our Privacy Policy{{closeTag}}.',
        submitButtonText: 'Submit',
        individualModalTitle: 'Proceed as an individual',
        businessModalTitle: 'Proceed as a business',
        modalContent: 'Please note: Once you\'ve made this selection, you cannot change it.',
        modalDescription: 'You\'ll need to provide a government-issued business record or tax document with your business name and address.',
        modalButtonText: 'Save and continue',
        acceptableDocuments: 'See a list of acceptable documents in our Help Center.',
        unsureMessage: 'Not sure what to submit? ',
        beforeYouStart: 'Before you start: You\'ll need a valid government-issued ID handy, as well as access to a phone camera.',
      },
      identityVerification: {
        title: 'Identity Verification',
        complete: 'Complete',
        pending: 'Pending',
        pendingTitle: 'Your verification is in progress',
        pscPendingTitle: 'We’re waiting on all owners to verify',
        pendingDescription: 'Looks like we need to take a closer look at something. We\'ll get back to you within 48 hours.',
        pendingPscDescription: 'You\'ll need all of your owners to verify their personal information before being able to sell securely on Reverb.',
        successMessage: 'You\'ve been successfully verified as of {{date}}.',
        verified: 'You\'re verified',
        reverify: 'Re-verify identity',
        whyPending: 'Why is my verification pending?',
        reverifyDescription: 'If your legal first and last name, address, or tax ID have changed since you completed your verification, you will need to reverify.',
        pleaseReverify: 'Please re-verify your identity',
        reverifyColdStorageInvitation: 'It\'s been over a year since you last verified your information. Please take a moment to re-verify your details to ensure a seamless selling experience.',
        unVerifiedTitle: 'Get verified',
        unverifiedDescription: 'We need a few details to verify your identity.',
        continue: 'Continue',
        modalContent: 'You will need to verify your identity again.',
        modalTitle: 'Are you sure?',
        verify: 'Verify Identity',
        description: 'This is a requirement for all marketplaces and helps keep our community safe. ',
        learnMore: 'Learn more about seller verification',
        needsAttention: 'Needs Attention',
        startVerification: 'Start verification',
        whyVerify: 'Why do I need to verify my identity?',
        verifiedDate: 'You last verified your identity on {{date}}.',
      },
      reverbTruliooBanner: {
        description: 'To securely verify your info, we partner with Trulioo—a leading identity verification platform.',
      },
      truliooVerifiedMessage: {
        verified: 'You’re verified!',
        thanks: 'Thanks for submitting your information.',
      },
      truliooPendingMessage: {
        thanks: 'Thanks for submitting your information',
        closerLook: 'Looks like we need to take a closer look at something. We\'ll get back to you within 48 hours.',
        learnMore: 'Learn more in our Help Center',
      },
      ownersInfo: {
        notStarted: 'Not started',
        inProgress: 'In progress',
        pendingVerification: 'Pending verification',
        verified: 'Verified',
        failedVerification: 'Failed verification',
        unknown: 'Unknown',
      },
      resendPscEmailButton: {
        resendEmail: 'Resend Verification Email',
      },
    },
    shopRegistration: {
      title: 'Shop Registration',
    },
    shopBalanceRegistrationComplete: {
      title: 'Reverb Payments Enabled for Your Shop',
      subtitle: 'Your listings will now be available for purchase via Reverb Payments!',
      whatsNext: 'What\'s Next?',
      payoutDetails1: 'The link below will take you to Adyen, our provider who verifies your information to ensure you are paid for sales securely.',
      payoutDetails2: 'Please be sure to provide your bank information in order to receive funds for sales as fast as possible.',
      payoutDetails3: 'Once you have provided data, it may take a few moments for our system to recognize your onboarding is complete.',
      completeDetails: 'Continue Onboarding',
    },
    paypalShopRegistrationComplete: {
      title: 'PayPal Enabled for Your Shop',
      subtitle: 'Your listings will now be available for purchase via PayPal!',
      nextStep: 'What Next?',
      step1: 'Complete payout details',
      step2: 'Sell your first item with PayPal',
    },
    paypalSetupDetails: {
      title: 'PayPal Setup',
      subTitle: 'Your PayPal Details',
      emailInput: {
        label: 'PayPal Email',
      },
      initialSetup:
        'We need to collect a few key pieces of data in order to set your shop up to use PayPal.',
    },
    billingMethod: {
      title: 'Billing Method',
      subTitle: 'Credit Card',
      description:
        "Reverb requires a credit or debit card in order to collect bump, shipping label or selling fees that can't be deducted from your Reverb Payments payouts.",
    },
    shopSetup: 'My Reverb Shop Setup',
    initialSetup:
      'We need to collect a few key pieces of data in order to set your shop up to use Reverb Payments.',
    onboardingComplete: {
      title: 'Shop Setup Complete!',
      allSetUp: 'Your shop is now completely set up and ready to rock.',
      whereEarningsGoReverbPayments:
        'Moving forward, all earnings from sales will go directly to your bank account.',
      whereEarningsGoPaypal:
        'Moving forward, all earnings from sales will go directly to your PayPal account.',
      happySelling: 'Happy selling!',
      goToDashboard: 'Go to my Dashboard',
      imageCompleteIconAlt: 'Instruments with ribbon',
      publishMyListing: 'Publish My Listing',
    },
    yourDetails: 'Your Details',
    originCountry: {
      title: 'Origin Country',
      description:
        'This is the country your shop is legally based in. This must be accurate or your payouts could be postponed.',
    },
    shopType: {
      title: 'What type of shop do you run?',
      individual: {
        title: 'Individual seller / sole-proprietor',
        bankAccountName:
          "%{nameBoldTagOpen}%{name}%{nameBoldTagClose} must be on the bank account that you'll connect to Reverb for your earnings.",
        namePlaceholder: 'Your legal first name and last name',
        hint:
          "If you'd like to connect a business bank account, choose the option below instead. Most Reverb shops are for individuals or sole-proprietors.",
      },
      soleProp: {
        checkboxLabel: 'I am the owner of a sole proprietorship',
        legalNameLabel: 'Registered Sole Proprietorship Name',
        legalNamePlaceholder: 'Your sole proprietorship name',
      },
      business: {
        title: 'Registered business',
        bankAccountName:
          "%{businessNameBoldTagOpen}%{businessName}%{businessNameBoldTagClose} must be on the bank account that you'll connect to Reverb for your earnings.",
        businessNamePlaceholder: 'Your legal business name',
        businessNameLabel: 'Registered Business Name',
      },
    },
    billingAddressSameAsShop: 'My billing address for this account is the same as my Shop Address',
    cardCreateFailed:
      "Sorry, we couldn't accept your credit card. Please check for errors and try again.",
    addNewCard: 'Add a New Card',
    chooseExistingCard: 'Choose Existing Card',
    error: 'Something went wrong. Please try again',
    shopDetailsHeader: 'Shop Details',
    chooseListingCurrency: 'This is the currency you will list items in.',
    continue: 'Continue',
    save: 'Save',
    update: 'Update',
    processing: 'Processing...',
    finish: 'Finish',
    shopDetailsTab: 'Shop Details',
    paymentSettingsTab: 'Payment Settings',
    identityVerificationTab: 'Identity Verification',
    sellerLocation: 'Seller Location',
    sellerLocationDescription: 'This is the country that you do your banking in.',
    title: "Let's set up your shop!",
    subTitle: "(You'll only need to do this once)",
    shopName: 'Shop Name',
    shopNameDescription: 'When someone buys from you, they\'ll see your "shop" name.',
    currency: 'Shop Currency',
    shopAddressDescription:
      'This should be the physical location that you are selling from. If you are selling from home, use that address.',
    addressName: 'Full Name',
    shopAddress: 'Shop Address',
    city: 'City',
    region: 'State / Province / Region',
    country: 'Country',
    postalCode: 'Postal Code',
    select: 'Select...',
    shopLive: 'Congrats, your new shop is live!',
    linkToShop: 'You can review your shop at {{tagOpen}}reverb.com{{shopPath}}{{tagClose}}',
    paymentSettings: {
      billingCardHeader: 'Credit or Debit Card',
      myCards: 'My Cards',
      addACard: 'Add a Card',
      directCheckout: {
        heading: 'Bank Account Information',
        subheading: 'Stored securely & encrypted',
        switchToPaypal: 'Use Paypal (only) instead',
      },
      paypal: {
        heading: 'How do you want to get paid?',
        switchToDirectCheckout:
          'Save on fees, use {{tagOpen}}Reverb Direct Checkout{{tagClose}} instead',
      },
      billingCard: {
        heading: 'Add a Credit Card',
        subheading:
          'We need a card on file as a backup just in case something goes wrong with our normal payout methods.',
      },
      bankAddress: {
        header: 'Bank Account Address',
        description:
          'This is the address associated with your bank account. We use this information to verify ownership of the account. This is not the physical address of your bank.',
      },
    },
    paypal: {
      email: 'Paypal Email',
    },
    ownerInformation: {
      legalFirstName: 'Your Legal First Name',
      legalLastName: 'Your Legal Last Name',
      validIdTitle: 'Do you have an accepted form of ID?',
      validIdLabel: 'Yes, I have an accepted form of ID',
      validIdHelpText: 'You may be required to provide identification before receiving payout for a sale. %{linkOpen}Learn more about acceptable forms of ID%{linkClose}',
    },
    errors: {
      title: "Let's fix errors and try again",
      subtitle: 'We found the following things wrong...',
    },
  },
  shopOnboarding: {
    acceptPaymentTerms:
      'I accept the Reverb Bucks terms & conditions. {{linkStart}}Read Terms{{linkEnd}}',
    addressName: 'Name',
    bucksDescriptionOne:
      'Accept all popular payment methods on your shop and access your funds sooner than waiting for a bank transfer.',
    bucksDescriptionTwo:
      'Withdraw them at any time and get a 1% discount on purchases using Reverb Bucks! {{linkStart}}Learn More{{linkEnd}}',
    businessLocation: 'Your Country',
    businessLocationDescription:
      'Where do you live? This helps us show you available payment options.',
    city: 'City',
    continue: 'Continue',
    convertToBucks: 'Convert to Reverb Bucks for immediate use',
    country: 'Country',
    countryCodeConfirmation:
      'This info is legally accurate and I understand that this cannot be changed later',
    currency: 'Shop Currency',
    currencyExplanation: 'The currency you list items in.',
    currencyChangeCalloutOne:
      'If you save this change, we will update your listings and shipping profiles from {{openTag}}{{prevCurrency}}{{closeTag}} to {{openTag}}{{newCurrency}}{{closeTag}}. It may take up to 60 minutes for these changes to become visible on the site.',
    currencyChangeCalloutTwo:
      'All existing offers and counter-offers will remain in {{openTag}}{{prevCurrency}}{{closeTag}}. New offers made after the changes are complete will be in {{openTag}}{{newCurrency}}{{closeTag}}.',
    error: 'Something went wrong. Please try again',
    name: 'Shop Name',
    originCountry: 'Origin Country',
    originCountryExplanation:
      'We set up your shop based on your location. If you need to change it, {{linkStart}}contact us.{{linkEnd}}',
    originCountryWarning: 'WARNING - You cannot change this once you click "Continue" below.',
    payment: 'Shop Payment Settings',
    paymentDescription: 'Choose which payment options are available to your customers.',
    postalCode: 'Postal Code',
    region: 'State / Region',
    reverbBucksLabel: 'Reverb Credits',
    save: 'Save',
    select: 'Select...',
    shopAddress: 'Shop Address',
    shopName: 'Shop Name',
    shopNameDescription:
      'This is the name that Reverb LP shoppers will see when they view your listings.',
    title: "Let's set up your shop!",
    paymentChecklistDCForms: 'Accept all forms of payment available to Reverb buyers.',
    paymentChecklistDCProtection:
      'All orders covered by {{linkStart}}Reverb Protection{{linkEnd}}.',
    bucksFundsNote:
      'Funds will be converted to Reverb Bucks. You can use your Bucks immediately and cash out at any time.',
    paymentChecklistPaypalAccount:
      'Buyers must have a PayPal account in order to purchase from your shop.',
    paymentChecklistPaypalReturns: "PayPal's return window and protection policies apply.",
    paypalFundsNote: 'Funds will be deposited directly into your Paypal account.',
    paypalEmail: 'Paypal Email',
    paypalFirstName: 'Paypal First Name',
    paypalLastName: 'Paypal Last Name',
    paypalVerify: 'Verify',
    paypalVerified: 'Verified!',
  },
  lpGridCard: {
    price: {
      one: '{{price}}',
      other: 'from {{price}}',
      zero: 'None listed',
    },
  },
  currencies: {
    USD: '{{symbol}} United States Dollar',
    EUR: '{{symbol}} Euro',
    GBP: '{{symbol}} British Pound',
    JPY: '{{symbol}} Japanese Yen',
    AUD: '{{symbol}} Australian Dollar',
    CAD: '{{symbol}} Canadian Dollar',
    NZD: '{{symbol}} New Zealand Dollar',
    MXN: '{{symbol}} Mexican Peso',
    DKK: '{{symbol}} Danish Krone',
    SEK: '{{symbol}} Swedish Krona',
    CHF: '{{symbol}} Swiss Franc',
    BRL: '{{symbol}} Brazilian Real',
    HKD: '{{symbol}} Hong Kong Dollar',
    NOK: '{{symbol}} Norwegian Kroner',
    PHP: '{{symbol}} Philippine Peso',
    PLN: '{{symbol}} Polish Zloty',
    RUB: '{{symbol}} Russian Rouble',
  },
  watchBadge: {
    watching: 'You are watching this listing',
    notWatching: 'You are not watching this listing',
    watch: 'Add to Watch List',
    unwatch: 'Remove from Watch List',
    benefits: {
      signUp: 'Sign up to Watch This Listing',
      priceDrop: "We'll let you know if the price drops.",
      offers: "You'll get notified when anyone else makes an offer.",
    },
  },
  addresses: {
    addressBox: {
      edit: 'Edit',
    },
    addressForm: {
      placeholder: {
        name: 'First and Last name',
        streetAddress: 'Address',
        extendedAddress: 'Apt, Suite, etc. (optional)',
        state: 'Select one...',
        country: 'Country',
        city: 'City',
        zip_code: 'Zip/Postal Code',
        phone: 'Phone Number',
      },
      confirmDelete: 'Are you sure?',
      yes: 'Yes',
      no: 'No',
      editAddress: 'Edit Address',
      name: 'Name',
      address: 'Address',
      extendedAddress: 'Apt, Suite...',
      city: 'City',
      state: 'State/Region',
      zip_code: 'Zip/Postal Code',
      country: 'Country',
      phone: 'Phone',
      saveAddress: 'Save Address',
      saving: 'Saving...',
      cancel: 'Cancel',
      deleteAddress: 'Delete Address',
    },
    addressList: {
      addNewAddress: 'Add a New Address',
      noAddressesOnFile: 'No addresses on file.',
      addAddress: 'Add Address',
    },
  },
  dashboard: {
    refundRequests: {
      required: 'Required',
      optional: 'Optional',
      reason: 'Reason for refund',
      noteToBuyer: 'Note to the buyer',
      noteToSeller: 'Note to the seller',
      noteFromBuyer: 'Note from buyer',
      selectOne: 'Select one',
      returnAddressHint: 'Please remember to include your return shipping address',
      returnShippingLabelCost: 'Return Shipping Cost',
      amount: 'Refund Amount',
      exchangedAmount: 'Your refund request: {{amount}}{{spanOpen}}{{settlementCurrency}}{{spanClose}}',
      exchangedAmountHint: 'The equivalent value in {{displayCurrency}} may vary',
      exchangedAmountTooltip: "This order was made in {{settlementCurrency}}.{{lineBreak}}What you'll receive in {{displayCurrency}} may vary {{lineBreak}} slightly based on exchange rates.",
      explanation: {
        heading: "Here's how this refund will work:",
      },
      refundState: {
        title: 'When should the money be refunded?',
        response: 'Response',
        approved: 'Issue refund now',
        conditionally_approved: 'Approve after return of item',
        denied: 'Deny refund request',
      },
      seller: {
        reason: {
          label: 'Refund Reason',
        },
        reasons: {
          buyer_no_like: 'Buyer does not like',
          buyer_change_of_heart: 'Buyer wants to cancel',
          buyer_return: 'Buyer requested return',
          lost_shipment: 'Lost in shipment',
          shipping_damage: 'Damaged',
          sold_elsewhere: "Item wasn't in stock",
          item_defective: 'Item is defective',
          item_not_as_described: 'Item not as described',
          accidental_order: 'Accidental order',
          change_shipping_address: 'Change shipping address',
          shipping_adjustments: 'Shipping adjustments',
          fraud: 'Fraud',
          other: 'Other',
        },
        refundType: {
          title: 'How much are you refunding?',
          full: 'Full refund',
          partial: 'Partial refund',
          remaining: 'Refund of remaining %{amount}',
        },
        explanation: {
          paypal: 'The refund will be processed through PayPal.',
          fullRefund: {
            refundAmount: "%{strongOpen}%{amount}%{strongClose} will be refunded to the buyer's account.",
            taxRemittedByReverb: '%{strongOpen}%{amount}%{strongClose} of sales tax will be refunded to the buyer by Reverb.',
            sellingFees: 'Your seller fee of %{strongOpen}%{amount}%{strongClose} will be credited to your month-end statement.',
            sellingFeesCreditedToPayout: 'Reverb fees of %{strongOpen}%{amount}%{strongClose} will be credited to your payouts.',
            pendingPayout: {
              original: 'You were originally owed %{strongOpen}%{amount}%{strongClose} for this transaction.',
              refund: 'We will cancel out this payout with a negative line item on your %{linkOpen}Reverb Payments Payouts%{linkClose}.',
            },
            completedPayout: {
              original: 'We already paid out %{strongOpen}%{amount}%{strongClose} to your bank account.',
              refund: 'After this refund is complete, you will owe the refunded amount to Reverb (less any fees). This will be deducted from your %{linkOpen}Reverb Payments Payouts%{linkClose} and kept as a negative balance against future payouts.',
            },
          },
          partialRefund: {
            amount: "The amount you selected above will be refunded to the buyer's account.",
            payout: 'Your %{linkOpen}payouts%{linkClose} will be reduced by the selected amount.',
            sellingFees: 'You will receive a refund for your seller fee as a credit on your month-end statement. The credit will be proportional to amount you refund the buyer.',
            sellingFeesCreditedToPayout: 'You will receive a refund for your seller fee as a credit on your payouts. The credit will be proportional to amount you refund the buyer.',
          },
          conditionallyApproved: {
            refundComplete: 'You will need to complete the refund upon return of the item.',
            refundAdjustment: 'You will be able to adjust the amount of the refund at that time.',
          },
          update: {
            refundAmount: "The refund amount will be refunded to the buyer's account.",
            fullRefund: 'Reverb fees of %{strongOpen}%{amount}%{strongClose} will be credited to your month-end Reverb Bill.',
            fullRefundToPayout: 'Reverb fees of %{strongOpen}%{amount}%{strongClose} will be credited to your payouts.',
            partialRefund: 'Your seller fees will be refunded proportionally for partial refunds.',
          },
        },
      },
      buyer: {
        reasons: {
          buyer_no_like: 'I do not like the item',
          buyer_change_of_heart: 'No longer want item',
          buyer_return: 'No longer want item',
          lost_shipment: 'Never received item',
          shipping_damage: 'Damaged',
          sold_elsewhere: 'Seller no longer has item',
          item_defective: 'Item is defective',
          item_not_as_described: 'Item not as described',
          accidental_order: 'Accidental order',
          change_shipping_address: 'Change shipping address',
          other: 'Other',
        },
        refundType: {
          title: 'How much are you requesting?',
          full: 'Full refund',
          partial: 'Partial refund',
          remaining: 'Refund of remaining %{amount}',
          remainingPlusTax: 'Refund of remaining %{amount} + %{amountTax} tax',
        },
        explanation: {
          request: 'Your request for a refund will go to the seller.',
          response: 'Allow the seller at least 24 hours to respond.',
          protectionPlanDetails: 'Reverb will automatically refund the protection plan (value of %{amount}) after the item is refunded.',
          reasons: {
            buyer_return: 'Has your order shipped? If so, please message the seller to work out a solution. If the item has not shipped, this request will notify the seller of the situation.',
            lost_shipment: 'Please let the seller know to open a lost package claim waiver with the carrier.',
            shipping_damage: 'Please message the seller with a few photos showing the damage to the item and packaging.',
            item_not_as_described: 'Please message the seller with a few photos or a quick video to show the issues.',
            accidental_order: 'Please message the seller about the confusion and they can assist with your request.',
            change_shipping_address: 'Once refunded, Reverb will assist with cloning this order, and you will be required to re-submit payment with the correct address.',
            other: 'Please include a brief note describing the reason for the refund request.',
          },
        },
      },
      admin: {
        formFields: {
          title: 'Financial Responsibility',
          isReverbAccommodated: 'Reverb accommodation—DO NOT notify seller (buyer will be notified).',
          adyenPayoutsBlockedWarning: 'Adyen Payouts are blocked for this seller. Reverb cannot accommodate this refund.',
        },
        accommodationReason: {
          title: 'Accommodation Reason',
          displayNames: {
            customer_service_accommodation: 'Customer Service Credit',
            dispute_accommodation: 'Dispute Accommodation Credit',
            insurance_claim: 'Reverb Safe Shipping',
          },
        },
      },
    },
    selling: {
      shipOrderViews: {
        header: {
          selling: 'Selling',
          allOrders: 'All Orders',
          shippingDetails: 'Shipping Details',
          pageTitle: 'Ship Order #{{orderId}}',
          orderNumber: 'Order #{{orderId}}',
          buyerName: 'Buyer: {{name}}',
          orderedDate: 'Ordered {{date}}',
          visitShippingGuide:
            'Need some shipping tips? Visit our %{linkOpen}shipping guide.%{linkClose}',
        },
      },
      shippingLabels: {
        buyer: 'Buyer: %{name}',
        contactBuyer: 'Message Buyer',
        visitShippingGuide:
          'Need some shipping tips? Visit our %{linkOpen}shipping guide.%{linkClose}',
        toAddressVerificationError:
          "This address could not be verified. You may want to %{linkOpen}contact the buyer%{linkClose} to make sure it's correct.",
        changeToAddressInfo:
          "If you need to change the buyer's address, please contact %{linkOpen}Reverb Support%{linkClose}.",
        manualTrackingCalloutText: 'Already have a tracking number?',
        manualTrackingCalloutButton: 'Manually Enter Tracking',
        details: {
          shipmentDetailsTitle: 'Shipment Details',
          shipmentOptionsTitle: 'Shipment Options',
        },
        shippingProtection: {
          labelHtml: 'Yes! Protect this shipment for only %{boldTagOpen}%{insuranceCost}%{boldTagClose}',
          title: 'Reverb Safe Shipping',
          description:
            "Purchase Reverb Safe Shipping for a fraction of your gear's sale price. If anything is lost or damaged during shipping, our responsive and friendly support team will help resolve the issue quickly.",
          learnMore: 'Learn More',
          alerts: {
            uncheckedWarning:
              'Without Reverb Safe Shipping, your item will not be covered by Reverb if lost or damaged during shipping.',
            cannotInsureHighValuePackageHtml:
              'Reverb Safe Shipping is only available on items valued under $10,000. %{linkOpen}Learn More%{linkClose}',
            additionalLabelShippingProtectionHtml:
              'If you selected Reverb Safe Shipping on your first label for this order, additional labels are automatically protected. %{linkOpen}Learn More%{linkClose}',
            requiredInsuranceHtml:
              'Reverb Safe Shipping is required on orders over $1,500. %{linkOpen}Learn More%{linkClose}',
          },
          peaceOfMindTitle: 'Shipping peace of mind',
          worryFreeDescription: 'If your gear is lost or damaged during shipping, our support team will help resolve the issue quickly.',
          experiment: {
            peaceOfMindTitle: 'Get shipping peace of mind',
          },
        },
        signatureRequired: {
          signatureRequiredTitle: 'Signature Required',
          requiredExplanationHtml:
            'Additional rates apply (see next page). Required for Reverb Safe Shipping. Not applicable when shipping to a PO Box.',
          carrierCalcRequiredExplanationHtml:
            'Additional rates apply. Required for Reverb Safe Shipping. Not applicable when shipping to a PO Box.',
          carrierCalcRequiredExplanationHtmlWithCost:
            'Additional charge of %{signatureRequiredCost}. Required for Reverb Safe Shipping. Not applicable when shipping to a PO Box.',
          optionalExplanationHtml:
            'Additional rates apply (see next page). Not applicable when shipping to a PO Box.',
          carrierCalcOptionalExplanationHtml:
            'Additional rates apply. Not applicable when shipping to a PO Box.',
          carrierCalcOptionalExplanationHtmlWithCost:
            'Additional charge of %{signatureRequiredCost}. Not applicable when shipping to a PO Box.',
        },
        mediaMail: {
          title: 'Package qualifies for media mail rates',
          tooltipHtml:
            'Must be educational media, e.g. books, manuals, guides, printed music, vinyl, CDs, or DVDs. Read more %{linkOpen}here.%{linkClose}',
        },
        orderCustoms: {
          customsTitle: 'Customs for International Shipping',
          customsExplainerHtml:
            'Customs information will be printed right on your label. You may not ship restricted materials such as Brazilian Rosewood, Ivory, Tortoise Shell, etc. See a full list of restricted materials %{linkOpen}here%{linkClose}.',
          quantity: 'Quantity',
          tariffNumber: 'Tariff Number',
          contentType: 'Content Type',
          merchandise: 'Merchandise',
          certifyTooltipHtml:
            'Enter the first and last name of the individual certifying the information provided is accurate. This is known officially as the "Customs Signer."',
          certifyAccuracy: 'I certify the info provided is accurate',
          none: 'None',
          nonDeliveryOption: 'Nondelivery Option',
          nonDeliveryTooltipHtml: 'The shipment will be returned to you if it cannot be delivered.',
          returnToSender: 'Return to sender',
          firstAndLastName: 'Your First and Last Name',
          restrictionType: 'Restriction Type',
          description: 'Description',
          declaredValue: 'Declared Value',
          countryOfOrigin: 'Country of Origin',
          whereItWasMade: '(where this item was made)',
          aboveMaximumAllowedDeclaredValue:
            "The maximum declared value for Reverb labels is %{maximumAmount} (You've declared %{declaredAmount})",
          customsForOrder: 'Customs info for Order %{orderId}',
        },
        edit: 'Change',
        fromAddressHeading: 'From Address',
        package: {
          dimensionsRequest: "What are your package's dimensions?",
          dimensionsRequestReason: 'We will use this to calculate shipping cost for the buyer.',
          length: 'Length',
          width: 'Width',
          height: 'Height',
          weight: 'Weight',
          customBoxRadioLabel: 'Custom Box',
          flatRateRadioLabel: 'USPS Flat Rate',
          chooseFlatRate: 'Choose a Flat Rate Package',
          select: 'Select...',
          inaccurateDimensionsWarning:
            'Inaccurate dimensions may incur additional charges or result in a returned package.',
          inaccurateDimensionsWarningLink: 'More tips to avoid shipping charge corrections.',
          flatRateWeightExceeded: 'Heads up! The max weight for a {{carrier}} {{package_name} is {{weight_limit_value} {{weight_limit_unit}. If your item weighs more than that, you\'ll need to ship in a custom box.',
          useImperial: 'Use Inches/Pounds',
          useMetric: 'Use Centimeters/Kilograms',
          helpCard: {
            makeSure: 'Easy tips to get your package dimensions.',
            roundUpImperial: 'Round up to the nearest inch when measuring your package.',
            roundUpMetric: 'Round up to the nearest centimeter when measuring your package.',
            addImperial: 'Add 8 ounces to the weight of your item for packaging materials.',
            addMetric: 'Add 0.5 kg to the weight of your item for packaging materials.',
            shippingBoxImgAlt: 'Shipping Box',
            boxIllustrationImgAlt: 'Box Dimension Illustration',
          },
        },
        toAddressHeading: 'To Address',
        multiOrderSelect: {
          selectOrdersHeading: 'Orders in Shipment',
          weEmailBuyerAboutPackage:
            "Select the orders you'd like to ship together. We'll send the buyer tracking info and let them know the orders will arrive in the same package.",
          clearedForShipment: 'Cleared for Shipment',
          orderLink: 'Order %{orderId}',
          orderedOnDate: 'Ordered %{date}',
          buyerName: 'Buyer: %{name}',
          selectAll: 'Select All',
          deselectAll: 'Deselect All',
          expedited: 'Expedited Shipping',
          mixedShippingSpeedsInfo: 'Mixed shipping speeds selected',
          shippingSpeedsWarning:
            'Because one or more of the selected orders requires expedited shipping, only expedited rates can be purchased for this shipment.',
        },
        reverbShippingLabelsValueProp: {
          title: '{{linkOpen}}Reverb Shipping Labels{{linkClose}} are easy, safe, and more affordable.',
          worryFree: 'Peace of mind with Reverb Safe Shipping',
          worryFreeBody: 'This optional benefit is only available with Reverb labels. If your gear is lost or damaged during shipping, our support team will help resolve the issue quickly. {{tagOpen}}Learn more.{{tagClose}}',
          saveMoneyTime: 'Save time and money',
          saveMoneyTimeBody: 'Save up to 50% from carriers including USPS, UPS, and FedEx. Easily purchase and print the labels at home. We’ll automatically add tracking info and notify your buyer.',
          learnMore: 'Learn more about Reverb Shipping Labels',
          getAReverbShippingLabel: 'Get a Reverb Shipping Label',
        },
        notUsingReverbLabel: 'Not using a Reverb Shipping Label?',
        addTrackingNumber: 'Add a Tracking Number.',
        wantToProtectShipment: 'Would you like your shipment to be protected with Reverb Safe Shipping?',
        getShippingLabel: 'Get Reverb Shipping Label',
        whyUseReverbLabel: 'Why ship with a Reverb Shipping Label?',
        whereIsPackageGoing: 'Where is your package going?',
        whatPackageDims: 'What is the size and weight of your package?',
        additionalOptions: 'Would you like to add any additional shipment options?',
        shipmentOptions: 'Would you like to add any shipment options?',
        carrierCalculatedInventoryWarning: 'Heads up! Since your buyer purchased {{quantity}} quantities of this item, we charged them {{amount_shipping_display}}, which covers the cost of {{quantity}} labels for an item of your size. You can {{children}} to fit all your items in one package, or you can purchase your first label as-is now and add additional labels later.',
        updateYourShipment: 'update your shipment',
        orderDetailsCard: {
          orderDetails: 'Order Details',
          clearedForShipment: 'Cleared for shipment',
          buyerName: 'Buyer: <strong>{{name}}</strong>',
          orderedDate: 'Ordered: <strong>{{date}}</strong>',
          paidDate: 'Paid: <strong>{{date}}</strong>',
          carrierCalculatedShippingPrice: 'Label Price: <strong>{{price}}</strong>',
        },
        addMyOwn: 'Cancel and add my own tracking number',
        submitButton: {
          reviewRates: 'Review Rates',
          purchaseShippingLabelSubmit: 'Get Label',
        },
        carrierCalculatedDetails: {
          shippingFunds: {
            title: 'Shipping Funds',
            buyerPaid: '{{children}} collected from buyer for this order',
          },
          package: {
            title: 'Shipment Details',
            changeDimensions: 'Change dimensions',
          },
        },
        carrierCalculatedWarningModal: {
          header: 'Are you sure you want to change the package dimensions?',
          priceWillChange: 'The label price will change if you change the package dimensions. You will be responsible for the difference in price if the label cost is more than what the buyer paid.',
          afterUpdate: 'Once you update your shipping information, you\'ll be able to review options from a variety of carriers.',
          confirm: 'Change Dimensions',
        },
        shippingRates: {
          purchaseLabel: 'Purchase Shipping Label',
          shippingLabelStatementBilling: 'This shipping label will be billed on your monthly statement. Reverb will send the buyer tracking details.',
          shippingLabelPayoutDeduction: 'This shipping label will be deducted from your payout. Reverb will send the buyer tracking details.',
          labelDetails: 'Label Details',
          shippingFrom: 'Shipping From',
          shippingTo: 'Shipping To',
          editDetails: 'Edit Details',
          edit: 'Edit Shipping Details',
          options: 'Options',
          signatureRequired: 'Signature Required',
          buyerPurchasedExpeditedShipping: 'Note: The buyer of this item purchased 2-Day Shipping. Please ensure you select an appropriate service.',
          displayMeasurement: '{{value}} {{unit}}',
          package: {
            dimensions: 'Dimensions',
            height: 'Height',
            length: 'Length',
            width: 'Width',
            weight: 'Weight',
          },
          rate: 'Rate',
          signatureRequiredAmount: 'Signature Required',
          reverbShippingProtection: 'Reverb Safe Shipping',
          reverbShippingProtectionTooltip: 'Protects you if your package is lost or damaged during shipment',
          reverbDiscount: 'Reverb Shipping Label Savings',
          reverbDiscountDetails: 'Your savings buying a label directly through Reverb',
          statementBilling: 'This label will be added to your monthly statement, because the cost is more than your remaining payout balance.',
          upsSurepostLogoText: 'UPS SurePost®',
          carrierDisclaimer: {
            usps: {
              description: 'By continuing, I agree to follow USPS hazardous materials guidelines. Shipping improperly prepared, undeclared or prohibited HAZMAT can result in civil and criminal penalties under federal law.',
              linkText: 'Learn about USPS restrictions.',
            },
            dhl: {
              description: 'By continuing, I agree to DHL’s terms and conditions.',
              linkText: 'Learn more on the DHL Express site.',
            },
          },
          lengthWidthHeightSummary: '{{lengthSummary}} x {{widthSummary}} x {{heightSummary}}',
          shippingLabelOutageDefaultText: 'One or more of our carriers are currently experiencing disruptions. Please check back later if your preferred carrier/service is unavailable.',
        },
        shippingProtectionInfo: {
          simpleSafeFast: 'Simple, Safe, Fast',
          details: 'Using a Reverb Shipping Label gives you the option to purchase Reverb Safe Shipping. If anything is lost or damaged during shipping, our support team will help resolve the issue quickly.',
          documentAlt: 'Document Icon',
          fileReport: 'File a Report Directly with Reverb',
          damageForm: "Use Reverb's short and simple damage form.",
          chatBubbleAlt: 'Chat Bubble Icon',
          workWithMusician: 'Work with a Musician',
          speakDirectly: 'Speak directly to a musician who understands the value of your gear.',
          highFiveAlt: 'High Five Icon',
          fastResolution: 'Reach a Fast Resolution',
          receivePayment: 'Receive payment or help repairing gear quickly due to one-on-one support.',
        },
        protectionQuotes: {
          cooperT: {
            quote: '“Shipping instruments and equipment can be daunting, we’ve all been there! My team is full of musicians who have helped thousands of customers with their shipping woes in the past.”',
            name: 'Cooper T.',
            title: 'Guitarist and Resolutions Team Manager',
          },
          samK: {
            quote: '“Having a real person giving individual attention to process the return and claim is really incredible. It makes a bad situation a bit easier to navigate and eases frustrations of both the buyer and the seller.”',
            name: 'Sam K.',
            title: 'Bad Water Guitars',
          },
        },
        schedulePickup: {
          wantToScheduleAPickup: 'Want to schedule a %{carrier} pickup?',
          schedulingAPickupWith: 'Scheduling a Pickup with %{carrier}',
          bigFlagBody: 'View rates and availability on the %{carrier} site. Once you have created the label on Reverb, you will be able to finalize the pickup.',
          expand: 'Expand',
          collapse: 'Collapse',
          checkAvailability: 'Check Availability',
        },
        safeShippingModal: {
          header: 'Would you like to add protection?',
          protectsYourGear: '%{linkOpen}Reverb Safe Shipping%{linkClose} protects your gear.',
          shipmentLostOrDamaged: 'If your shipment is lost or damaged, our support team will quickly resolve the issue.',
          addSafeShipping: 'Add Reverb Safe Shipping',
          noSafeShipping: "No, I don't want shipping protection",
        },
      },
      shippingProfile: {
        promoteOnListingsWithCategory: 'Promote these listings for:',
        crossPromoteInOtherCategoriesTitle: 'Cross-promote in other categories',
        crossPromotionExplainer: 'Listings with this shipping profile will be promoted on your other listings for the following categories:',
      },
      shippingRates: {
        rateOptionsTitle: 'Rate Options',
        rateOptionsTitleShippingProfile: 'Shipping Costs',
        newBadgeText: 'NEW',
        regionalOptionsAreNew: 'Now you can set shipping rates by states/provinces',
        rateOptionsDescription: 'Enable options beyond standard shipping',
        shippingRatesTitle: 'Shipping cost options (required)',
        region: 'Region: ',
        manualFieldsTitle: 'Set shipping rate',
        manualFieldsSubtitle: 'Provide the rate that buyers in this region will see on the listing',
        optionsHeader: 'How do you want to handle shipping costs in this region?',
        optionsHeaderWithCountry: 'How do you want to cover the cost of shipping in {{country}}?',
        theContinentalUs: 'the Continental U.S.',
        shippingModeRadios: {
          custom: {
            title: 'Set my own shipping rate',
            helpText: 'Provide the rate that buyers in this region will see on the listing.',
            badgeText: 'Best for heavy items',
          },
          free: {
            title: 'Offer free shipping',
            titleRegional: 'Offer free shipping in all regions of {{country}}',
            titleRegionalWithExpedited: 'Offer free shipping or free 2-day shipping in all regions of {{country}}',
            helpText: 'You can adjust the listing price to make up for shipping cost.',
            helpTextRegional: 'Attract more buyers by offering to cover shipping across this entire country. For each listing with this profile applied, you can adjust the listing price to make up for the cost.',
            badgeText: 'Attractive to buyers',
            expedited: {
              title: 'Can you offer free 2-day shipping?',
              checkbox: 'Offer free 2-day shipping in {{country}}',
              helpText: 'You have a stellar quick shipping record. Offering free 2-day shipping gives sellers better exposure, and higher sell-through rates.',
              link: 'Learn more about 2-day Shipping',
            },
          },
          regional: {
            title: 'Set shipping rates for {{country}}',
            helpText: 'Set paid shipping rates or free shipping for specific {{areaType}} within this country.',
            helpTextWithExpedited: 'Set paid shipping rates, free shipping, or 2-day shipping for specific {{areaType}} within this country.',
            defaultInputLabel: 'Default rate for {{rateType}} shipping',
            states: 'States',
            provinces: 'Provinces',
            state: 'State',
            province: 'Province',
            defaultInputContinentalUsHelpText: 'Applies to all {{areaType}} in {{country}}, unless overridden by regional rates.',
            expeditedInfo: {
              title: 'Did you know Quicker Shippers get to offer 2-day Shipping?',
              helpText: 'You might have seen {{tagOpen}}{{free}} 2-day Shipping{{tagClose}} on some Reverb listings. Sellers with proven quick shipping track records get to showcase this distinction on their listings.',
              link: 'Learn more about 2-day Shipping',
              free: 'Free',
            },
            options: {
              badgeText: 'optional',
              rateType: {
                standard: 'Standard',
                expedited: '2-day',
              },
              title: 'Regional Rates for {{rateType}} Shipping',
              free: {
                standard: {
                  new: 'Where can you offer free standard shipping?',
                  selected: 'Free Standard Shipping',
                },
                expedited: {
                  new: 'Where can you offer free expedited shipping?',
                  selected: 'Free Expedited Shipping',
                },
                US_CON: {
                  button: 'Select States',
                  defaultIsFree: 'By default in all states',
                },
                CA: {
                  button: 'Select Provinces',
                  defaultIsFree: 'By default in all provinces',
                },
                defaultIsFreeButtonHelpText: 'Your default rate is free',
              },
              paid: {
                standard: {
                  new: 'Where can you offer different paid standard shipping rates?',
                  selected: 'Standard Shipping Rate: {{price}}',
                },
                expedited: {
                  new: 'Where can you offer different paid 2-day shipping rates?',
                  selected: '2-Day Shipping Rate: {{price}}',
                },
                US_CON: {
                  button: 'Select States and Set Rates',
                },
                CA: {
                  button: 'Select Provinces and Set Rates',
                },
              },
            },
            header: {
              standard: {
                title: 'Standard Shipping Rates',
                helpText: 'What will you charge by default for standard shipping? Regional rates will override the default rate.',
              },
              expedited: {
                title: '2-day Shipping Rates',
                recommended: 'Recommended for better exposure and higher sell-through rates.',
                learn: 'Learn more',
                default: 'You can set a default rate across all {{areaType}}. Regional rates will override the default rate.',
                badgeText: 'OPTIONAL',
              },
            },
            rateGroup: {
              edit: 'Edit',
              delete: 'Delete',
              add: 'Add Another Regional Rate',
            },
            modal: {
              save: 'Save',
              cancel: 'Cancel',
              header: {
                standard: {
                  free: 'Free Standard Shipping Regions',
                  paid: 'Regional Standard Shipping',
                },
                expedited: {
                  free: 'Free 2-day Shipping Regions',
                  paid: 'Regional 2-day Shipping',
                },
              },
              body: {
                standard: {
                  free: 'Where can you offer free standard shipping in {{country}}?',
                  paid: 'How much will you charge for standard shipping in these {{areaType}}?',
                  paidHelp: 'Paid standard rates must be lower than 2-day shipping.',
                },
                expedited: {
                  free: 'Where can you offer free 2-day shipping in {{country}}?',
                  paid: 'How much will you charge for 2-day shipping in these {{areaType}}?',
                  paidHelp: 'Paid 2-day rates must be higher than standard shipping.',
                },
              },
              secondaryBody: {
                standard: 'Where do you want to apply this paid standard shipping rate?',
                expedited: 'Where do you want to apply this paid 2-day shipping rate?',
              },
              priceLabel: {
                standard: 'Standard shipping rate',
                expedited: '2-day shipping rate',
              },
              error: {
                price: {
                  invalid: 'Invalid price.',
                  duplicate: 'This rate has already been applied.',
                },
                alert: {
                  plural: {
                    expedited: '{{tagOpen}}{{errorCount}}{{tagClose}} {{areaType}} have a higher standard shipping rate.',
                    standard: '{{tagOpen}}{{errorCount}}{{tagClose}} {{areaType}} have a lower 2-day shipping rate.',
                  },
                  singular: {
                    expedited: '{{tagOpen}}1{{tagClose}} {{areaType}} has a higher standard shipping rate.',
                    standard: '{{tagOpen}}1{{tagClose}} {{areaType}} has a lower 2-day shipping rate.',
                  },
                },
              },
              selectAllInRegion: 'Select all in this region',
            },
            regionalNotAllowed: 'Regional shipping rates can\'t be applied to items with combine & save rates.',
            combinedNotAllowed: 'Combine & save rates can\'t be applied to items with regional shipping rates.',
            freeNotAllowedInPaidRegionalExpedited: 'Must be greater than {{zeroAmount}}. Free 2-day shipping can be applied regionally',
          },
        },
        rateFields: {
          rate: 'Shipping Rate',
          incrementalRate: 'Combine & Save Rate',
          expeditedRate: '2-Day Rate',
          optional: 'Optional',
          removeRate: 'Remove',
          removeButtonLabel: 'Remove shipping for {{regionName}}',
          enableCombined: 'Offer a Combine & Save shipping rate',
          enableCombinedHelp: 'Apply a discounted rate for this item when it is ordered with other items in your shop.',
          expeditedLabel: {
            enabled: 'Enable 2-Day Shipping. By offering 2-Day Shipping, you agree to the requirements.',
            disabled: '2-day shipping gives sellers better exposure, and higher sell-through rates.',
          },
          expeditedLearn: {
            enabled: 'Learn more',
            disabled: 'Learn how you can offer 2-Day Shipping',
          },
          enableExpeditedLearnMore: 'Learn More',
          setFree: {
            rate: 'Offer free shipping (Attractive to buyers)',
            incrementalRate: 'Bundled items ship for free',
            expeditedRate: 'Offer free 2-day shipping',
          },
          standardShippingTooltip: 'Applied when this item ships alone or has the most expensive shipping of all items in an order',
          combinedShippingTooltip: 'Applied when this item is shipped in multiples or with an item that has a higher shipping rate.',
          validationErrors: {
            combinedRequired: 'Combine & Save rate required',
            standardRequired: 'Shipping rate required',
            combinedGreaterThanStandard: 'Combine & Save rate must be less than standard rate',
            greaterThanStandard: 'Cannot be greater than the standard rate.',
            expeditedLessThanStandard: 'Expedited rate cannot be less than standard rate',
            regionalExpeditedLessThanStandard: 'The default expedited rate can\'t be less than the standard rate. Set free and discounted 2-day rates regionally.',
          },
          defaultStandard: 'Default standard shipping rate',
        },
        shippingCostEstimator: 'Shipping label cost estimator',
        shippingRegionPlaceholder: 'Select a region...',
        addShippingLocationToShippingProfileHeader: 'Where else in the world will you ship items in this profile?',
        addShippingLocation: 'Add Shipping Locations',
        addShippingLocationExplainer: 'Adding multiple locations puts your listing in front of more eyes.',
        learnMore: 'Learn more',
        addRate: 'Add Region',
        cancelAddRate: 'I\'m done adding regions',
        everywhereElse: 'Rest of the World',
        everywhereElseTip: 'The rate for any location outside of the locations you\'ve specified in this list.',
        examples: {
          understandingCombinedShipping: 'Understanding Combine & Save shipping',
          hereAreTwoExamples: 'Combine & Save shipping can be used in different ways to fit your needs. Here are two common examples for an "Accessories" shipping profile (for items like cables, picks, etc.)...',
          guitarIcon: 'Guitar Icon',
          accessoriesIcon: 'Accessories Icon',
          guitar: 'Guitar',
          guitarCable: 'Guitar Cable',
          shippingCost: '%{price} shipping',
          combinedShippingCost: '%{price} Combine & Save shipping',
          exampleOneDifferentItems: 'Example 1 - Buying different items',
          exampleTwoSameItems: 'Example 2 - Buying the same items',
          checkoutTotal: 'Checkout Total: %{total} + %{shippingTotal} shipping',
          exampleOneBuyerPays: 'The Buyer pays the Standard Shipping rate for the guitar, and the %{combinedRate} Combine & Save rate for the cable',
          exampleTwoBuyerPays: 'The Buyer pays the Standard Shipping rate for the first cable, and %{combinedRate} Combine & Save rate for each remaining cable',
          hideExamples: 'Hide Examples',
          showExamples: 'Show Examples',
        },
      },
      shopCampaigns: {
        index: {
          pageTitle: 'My Shop Promotions',
          header: 'Thank You Coupons',
          subHeader: 'Create a coupon promotion and send a discount code to buyers to thank them for their purchase.',
          emptyHeader: 'Create a Thank You Coupon promotion',
          emptySubHeader: 'Drive repeat buyers and build a following for your shop by setting up a Thank You Coupon promotion.',
          emptyCreateLink: 'Get started',
          currentlyActiveTitle: 'Active promotion',
          currentlyActiveSubtitle: 'Coupons are currently being sent to buyers for this promotion.',
          changeSettingsLink: 'Change settings',
          deactivateButton: 'Deactivate',
          archivedTitle: 'Archived promotions',
          archivedSubtitle: 'These promotions are not sending new coupons to buyers, but any buyer who received a coupon while the promotion was active will still be able to redeem it until it expires.',
          deactivateModalTitle: 'Deactivate promotion',
          deactivateModalSave: 'Deactivate',
          deactivateModalExplain: 'Are you sure you want to deactivate this campaign? Any coupons that have been sent out for this promotion will still be valid until they expire.',
        },
        table: {
          headers: {
            code: 'Coupon Code',
            formattedDiscount: 'Discount',
            minPurchaseAmount: 'Min. Purchase',
            useRetroactiveSends: 'Incl. Past Buyers',
            excludedBrands: 'Excluded Brands',
            excludedCategories: 'Excluded Categories',
            excludedConditions: 'Excluded Conditions',
            sentCount: 'Sent',
            redeemedCount: 'Redeemed',
          },
          yes: 'Yes',
          no: 'No',
          blank: 'N/A',
        },
        create: {
          pageTitle: 'Create New Promotion',
          header: 'Create New Promotion',
          replaceActiveWarning: 'Heads up! Setting up a new promotion will replace the promotion you are currently running.',
          replaceActiveCurrentDetails: 'Current promotion details',
          setupTipsHeader: 'About Thank You Coupon promotions',
          setupTipsFirst: 'You may have only one Thank You Coupon promotion active at a time.',
          setupTipsSecond: 'Thank You Coupon discounts apply to one item at checkout.',
          setupTipsThird: 'Thank You Coupons are sent after a buyer makes a purchase from your shop. They expire after 30 days.',
          setupTipsFourth: 'Buyers can only redeem one coupon per promotion.',
          optional: 'Optional',
        },
        exclusions: {
          header: 'Exclusions',
          subheader: 'Exclude portions of your inventory from coupon eligibility. Avoid over-using exclusions to make your coupon more usable for buyers.',
        },
        setupShopBanner: {
          header: 'Get started with Thank You Coupons',
          subheader: 'Before you can setup a coupon promotion, you\'ll need to tell us about your shop.',
          setupShopCta: 'Setup my shop',
        },
        form: {
          discountTypeRadio: {
            label: 'Coupon Type',
            amountBased: 'Fixed amount off',
            percentBased: 'Percentage off',
          },
          discountAmountInput: {
            label: 'Discount amount',
          },
          discountPercent: {
            label: 'Discount percentage',
            invalid: 'Please set a discount between %{discountMin}% and %{discountMax}%.',
          },
          maxDiscountAmountInput: {
            label: 'Maximum discount',
            helpText: 'Set a maximum discount buyers can receive for percentage-based coupons.',
          },
          minPurchaseAmountInput: {
            label: 'Minimum order to qualify',
            helpText: 'Set a minimum amount for buyers to spend to receive this coupon.',
          },
          code: {
            label: 'Coupon code',
            helpText: 'Minimum 3, maximum 20 characters. No spaces or special characters. For example, “THANKYOU15”.',
            invalid: 'You have an existing promotion that uses this code. Please choose a different code.',
          },
          useRetroactiveSends: {
            label: 'Include recent buyers',
            helpText: 'Sends coupon to buyers who made a purchase from your shop within the last 30 days.',
          },
          brands: {
            label: 'Excluded brands',
            placeholder: 'Search brands',
            helpText: 'Limit of %{limit} brand exclusions',
          },
          categories: {
            label: 'Excluded categories',
            helpText: 'Limit of %{limit} category exclusions',
          },
          conditions: {
            label: 'Excluded conditions',
            usedConditionSummary: 'Items in Mint, Excellent, Very Good, Good, Fair, Poor and Non Functioning conditions',
          },
          submitButton: 'Create promotion',
        },
        sellerConfirmation: {
          modalHeader: 'Review Promotion Details',
          modalSaveButtonText: 'Create promotion',
          pleaseReview: 'Please review these details before launching this promotion. Once created, you will not be able to reuse this code. We will begin sending coupons to buyers, and you will not be able to make changes without ending this promotion.',
          couponSummaryHeader: 'Promotion summary',
          discountSummary: 'Discount amount:',
          maxDiscountAmountInput: 'Maximum discount:',
          minPurchaseAmountInput: 'Minimum purchase amount:',
          useRetroactiveSends: 'Include recent buyers:',
          excludedBrands: 'Excluded brands:',
          excludedCategories: 'Excluded categories:',
          excludedConditions: 'Excluded conditions:',
          retroactiveYes: 'Yes',
          retroactiveNo: 'No',
          code: 'Coupon code:',
          buyerSeesHeader: 'Buyer sees',
          couponPreviewHeader: '%{discountDisplay} off from %{shopName}',
          description: 'Take %{discountDisplay} off one item from %{shopName} with code %{code} at checkout. ',
          generalRestriction: 'Applies to the purchase of a single item from %{shopName}.',
          minPurchaseRestriction: 'Minimum purchase of %{minPurchaseDisplay} required to use coupon.',
          maxDiscountRestriction: 'Up to %{maxDiscountDisplay} discount.',
          usedRestriction: 'Does not apply to used items.',
          newRestriction: 'Does not apply to new items.',
          bStockRestriction: 'Does not apply to B-Stock items.',
          otherDiscountRestriction: 'Coupon cannot be used with items on sale, negotiated offers, or purchases made with financing.',
        },
        couponPreview: {
          brandsFinePrint: 'Excludes items from brands: %{brands}.',
          categoriesFinePrint: 'Excludes items from categories: %{categories}.',
          seeTerms: 'See terms',
        },
      },
      sameDayShipping: {
        formDescription: 'Days and cut-off times for same-day shipping',
        daysDescription: 'Days you offer same-day shipping',
        timesDescription: 'Order cut-off times per day',
        timeZone: 'Time Zone',
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        disabled: {
          label: 'Take a break from same-day shipping',
          helpText: 'Your previous selections will be saved when you\'re ready to offer same-day shipping again.',
        },
        categoryExemptionDescription: 'Which categories will you ship same-day?',
        allCategories: 'All categories',
      },
    },
    refundStats: {
      alert: {
        atRefundLimit: 'If you initiate any additional refunds this month, you\'ll be charged a processing fee for the order.',
        exceededRefundLimit: 'In accordance with our policies, your next statement will include a processing fee for refunds you\'ve initiated beyond the first {{max}} this month.',
      },
      count: '{{count}}',
      description: 'This number includes fully refunded orders, and excludes refunds initiated by buyers.',
      error: 'Something went wrong. Please reload the page.',
      learnMore: 'Learn about our policy',
      noRefunds: 'You haven\'t initiated any refunds.',
      recommended: 'We encourage sellers to initiate no more than {{max}} refunds in a month.',
      refundsCountLabel: 'Number of refunds you initiated:',
      title: 'Your seller-initiated cancellations and refunds',
      dateDisplay: {
        dateRange: 'From {{startDate}} - {{endDate}}',
        sinceStartDate: 'Since {{startDate}}',
      },
      datePicker: {
        currentMonth: 'Current month-to-date',
        lastMonth: 'Last month',
        placeholder: 'Select A Date Range',
        title: 'Date range for refund count',
      },
    },
  },
  bundledCheckout: {
    continueButton: {
      placeOrder: 'Place Order',
      continue: 'Continue',
      pleaseWait: 'Please wait...',
    },
    streetAddressFormatHint: {
      address: 'Address',
      hint:
        "You've entered a street address of only one word and without any numbers. Please ensure that is correct.",
    },
    deleteCreditCard: {
      areYouSureYouWantToDeleteYourCard: 'Are you sure you want to delete your card ending in {{cardLast4Digits}}?',
      deleteCard: 'Delete Card',
      deleteCreditCard: 'Delete Credit Card',
      failedToDeleteCardErrorMessage: 'We have failed to delete your credit card from your wallet. Please try again in a few moments. If issue persists, please contact customer support.',
    },
  },
  head: {
    defaultTitle: 'Buy & Sell New, Used, and Rare Music Gear',
    defaultDescription:
      'Reverb is a marketplace bringing together a wide-spanning community to buy, sell, and discuss all things music gear.',
    fullTitle: '{{title}} | {{postfix}}',
    postfix: 'Reverb',
    titleWithPage: '{{title}} - Page {{page}}',
    notFoundComponent: 'Not Found',
  },
  notFoundPage: {
    title: "Sorry, this page isn't available.",
    description: 'The link you followed may be broken or the page may have been deleted.',
  },
  pageEditLink: {
    button: 'Edit This Page',
  },
  modalDialog: {
    goBack: 'Go back',
    save: 'Save Changes',
    delete: 'Delete',
  },
  dynamicPage: {
    header: {
      searchPlaceholder: 'Search in this set',
      actionText: 'View All Listings',
    },
  },
  addressBook: {
    addNewBtn: 'Add a New Address',
    noAddresses: "You don't have any addresses on file.",
    loadMore: 'Load More Addresses',
    currentlyDefault: 'This is your default address',
    row: {
      defaultLabel: 'Default Address',
      actions: {
        makeDefault: 'Make Default',
        edit: 'Edit',
      },
    },
    addNewEuOdrAddressBtn: 'Add EU ODR Platform Address',
    form: {
      title: {
        create: 'Add a New Address',
        edit: 'Edit Address',
        euOdrSellerDetails: 'EU ODR Seller Details',
      },
      name: 'Name',
      country: 'Country',
      streetAddress: 'Street Address',
      locality: 'City',
      region: 'State / Region',
      postalCode: 'Zip / Postal Code',
      phone: 'Phone Number',
      makeDefault: 'Make Default Address',
      makeDefaultHint: 'Makes this address the default for any future orders',
      optional: 'Optional',
      deleteConfirmation: 'Are you sure you want to delete this address?',
      email: 'Email',
    },
    addressSummary: {
      incompleteAddress: 'This address has missing information',
    },
  },
  giftCards: {
    deliveryDate: {
      title: 'Delivery Date',
      sendNow: 'Send Now',
      chooseDate: 'Choose a Date...',
    },
  },
  cartNudge: {
    predictedToSellSoon: 'Predicted to Sell Soon',
    onlyXAvailable: 'Only {{count}} available and',
    peopleHaveInCarts: {
      one: '1 other person has this in their cart',
      other: '{{count}} other people have this in their carts',
    },
  },
  photos: {
    editor: {
      cancel: 'Cancel',
      done: 'Done',
      save: 'Save',
      cropToSquare: 'Crop to a Square',
      imageAlt: 'Image Preview',
    },
    smallImageWarningHeading: 'Some of your images are a bit small',
    smallImageWarning: 'The best listing images are at least 620 pixels on a side.',
    smallImageErrorHeading: 'An image was removed because it was too small',
    smallImageError:
      'Listings look best with images that are at least 620 pixels on a side. Please upload a larger image.',
    uploadFailureHeading: 'There was a problem uploading your image',
    uploadFailure: 'Please make sure your image is a .jpg or .png format and try again.',
    guitarsWarningHeading: 'Sell faster by showing the guitar body in your first image',
    guitarsWarning:
      'Our data shows that displaying the front of the guitar body in your first image will help your listing sell more quickly. Click and drag any image to move it to the first spot.',
    stockPhotosWarningHeading: 'Display your own images on used listings.',
    stockPhotosWarning:
      "It looks like you've uploaded a stock image with this used item.  Reverb asks for original images on used listings so buyers can see the unique details of what you're selling.",
    drop_files: 'Drop files to start upload.',
    tip: {
      uploadOrDrop: 'Click below or drag photos of your gear onto the page to upload them.',
      reorder:
        'You can drag your images to reorder them - the upper left image will be the primary photo.',
    },
    thumb: {
      edit: 'Edit',
    },
    uploader: {
      uploadPhotos: 'Upload Photos',
      progress: '{{percent}}%',
    },
  },
  productSearchForm: {
    label: 'Find Add-on Item',
    placeholder: 'Search your listings...',
    productDetails: {
      sku: 'SKU: {{sku}}',
      condition: '{{condition}} Condition',
      sellerCost: 'Your cost {{cost}}',
    },
  },
  messages: {
    attach_images: 'Attach images',
    attach_listings: 'Attach listings',
    body: {
      placeholder: 'Write your message...',
    },
    messageForm: {
      includeOffer: 'Include an offer',
      offerModalHeader: 'Send message & offer',
    },
  },
  categories: {
    'electric-guitars': 'Electric Guitars',
    'acoustic-guitars': 'Acoustic Guitars',
    'bass-guitars': 'Bass Guitars',
    amps: 'Amps',
    'effects-and-pedals': 'Effects and Pedals',
    'drums-and-percussion': 'Drums and Percussion',
    'pro-audio': 'Pro Audio',
    'keyboards-and-synths': 'Keyboards and Synths',
    'dj-and-lighting-gear': 'DJ and Lighting Gear',
    'pro-audio/microphones': 'Microphones',
    'folk-instruments': 'Folk Instruments',
    'band-and-orchestra': 'Band and Orchestra',
    software: 'Software & Plugins',
    'recorded-music': 'Recorded Music',
    'home-audio': 'Home Audio',
    accessories: 'Accessories',
    parts: 'Parts',
    viewMore: 'More',
  },
  navLinks: {
    explore: 'Explore',
    reverbNews: 'Reverb News',
    priceGuide: 'Price Guide',
    reverbGives: 'Reverb Gives',
    shops: 'Shops',
    news: 'News',
    helpCenter: 'Help Center',
    findADeal: 'Find a Deal',
  },
  feedbackForm: {
    feedbackLabel: 'Feedback',
    rateTransaction: 'Rate this transaction',
    sendFeedback: 'Send Feedback',
    feedbackPublicHint: 'Feedback comments are public. Be honest and polite.',
    problemsWithYourOrder: 'Problems with your order?',
    buyerSuggestion: 'You can usually resolve issues by %{msgLinkOpen}messaging the seller%{msgLinkClose} or %{refundLinkOpen}requesting a refund%{refundLinkClose}.',
    sellerSuggestion: 'You can usually resolve issues by %{linkOpen}messaging the buyer%{linkClose}.',
    shippingLabel: 'Was your item safely packed for shipment?',
    conditionLabel: 'Was your item\'s condition described accurately in the listing?',
    shippingSatisfactory: 'Yes',
    shippingNotSatisfactory: 'No',
    conditionSatisfactory: 'Yes',
    conditionNotSatisfactory: 'No',
  },
  feedbackOrderInfo: {
    youBought: 'You bought:',
    youSold: 'You sold:',
    orderNumberHtml: 'Order #: %{linkOpen}%{orderNumber}%{linkClose}',
    status: {
      orderedAt: 'Ordered: %{date}',
      waitingForPayment: 'Waiting for payment',
      presumedReceived: 'Presumed received',
      shipped: 'Shipped: %{date}',
      pickedUp: 'Picked Up: %{date}',
      awaitingShipment: 'Awaiting Shipment',
    },
  },
  feedbackShopInfo: {
    leavingFeedbackFor: 'Leaving Feedback for:',
  },
  feedbackContainer: {
    feedbackSuccess: 'Thanks for the feedback!',
    declineSuccess: 'Feedback dismissed.',
  },
  flagListing: {
    formDescription: 'Flag listings that contain objectionable content to bring them to the attention of our editors.',
    reportReason: 'Why are you reporting this content?',
    adminReason: 'Reason',
    incorrectProductInfo: 'Contains incorrect product information',
    fraudulent: 'May be fraudulent',
    violatesTrademark: 'Violates a trademark',
    violatesListingGuidelines: 'Violates Reverb\'s %{linkOpen}Listing Guidelines%{linkClose}',
    descriptionHeader: 'Describe why this violates our policies',
    descriptionPlaceholder: 'Please provide specific details',
    descriptionMaxLength: 'Maximum 160 characters',
    reportItem: 'Report Listing to Reverb',
    submitReport: 'Report Listing',
    collapseForm: 'Nevermind',
  },
  adminButtons: {
    adminActions: 'Admin Actions',
    reportMismatchedProduct: 'Report Mismatched Product',
    openInEchidna: 'Open in Echidna',
    viewFlags: 'View Flags',
  },
  declineFeedback: {
    tooltip: 'Dismiss and don\'t leave feedback.',
  },
  newsletterPopover: {
    title: 'Want to save on gear?',
    subtitle: 'Get access to exclusive sales and more.',
  },
  shopGrid: {
    vacation: {
      title: '{{name}} is currently away',
      subtitle: 'When they get back, you\'ll be able to browse & buy their listings.',
      action: 'In the meantime, you can follow them to see their new listings in your Feed:',
    },
  },
  item2Layout: {
    reviews: 'Reviews of the {{title}}',
    findMore: 'Find More on Reverb',
    video: 'Watch a Video',
    sellerDetails: 'Seller Details',
    tabTitle: '{{title}} | {{shopName}}',
    bestSellerBadge: {
      bestSeller: 'Top Product',
      tooltipText: 'Calculated based on sales during the past 90 days.',
      linkText: '#{{rank}} in {{category}}',
    },
    imageAlt: '{{title}} image {{index}}',
    productReviews: 'Product Reviews',
    sellerReviews: 'Seller Reviews',
    nonRefundableCallout: {
      title: 'Software items cannot be refunded.',
      subtitle: 'Check compatibility with your computer’s operating system before purchasing.',
    },
    supportedFormats: 'Supported Formats',
  },
  item2ListingState: {
    states: {
      draft: 'Draft Listing',
      ended: 'Listing Ended',
      expired: 'Expired',
      live: 'Live',
      ordered: 'Listing Sold',
      seller_unavailable: 'Seller is currently away',
      sold: 'Listing Sold',
      sold_out: 'Sold Out',
      suspended: 'Listing Suspended',
      upcoming: 'Available Soon',
    },
    prompts: {
      watch: 'If you watch the listing, we\'ll notify you if it becomes available again.',
      vacation: 'This listing is temporarily unavailable while the seller is away.',
    },
  },
  item2Image: {
    statusIndicatorBannerEnded: 'ENDED | View similar gear',
    statusIndicatorBannerSold: 'SOLD | View similar gear',
    statusIndicatorBannerUnavailable: 'UNAVAILABLE | View similar gear',
  },
  item2Shop: {
    shippedFrom: 'Shipped From',
    localPickupOnly: 'Local Pickup Only',
    messageSeller: 'Message Seller',
    viewPolicies: 'Payment & Returns',
    viewUser: 'View User',
    sales: 'Sales',
    joined: 'Joined Reverb',
    soldBy: 'Sold By',
    salesSince: '{{count}} sales since {{foundingYear}}',
  },
  item2ShopModule: {
    aboutTheSeller: 'About the Seller',
    viewShop: 'View more from this shop',
  },
  item2AboutThisSeller: {
    viewShop: "Seller's other items",
    itemsSold: 'Items Sold',
  },
  item2ShopPoliciesModal: {
    acceptedPaymentMethods: 'Payment Options',
    shopPolicies: 'Payment & Returns',
    taxNotes: 'Taxes are charged in the following regions either by the seller, per their tax policy, or by Reverb as a Marketplace Facilitator:',
    shippingOptions: 'Shipping Options',
  },
  item2ProductModule: {
    productOverview: 'Product Overview',
    compareListings: 'Compare ({{count}}) available listings from {{price}}',
    lowestFrom: 'Browse {{count}} options from {{price}}',
    productFamily: 'Explore {{name}}',
  },
  item2Description: {
    about: 'About This Listing',
    productDescription: 'Product Description',
    specs: 'Product Specs',
    asDescribed: {
      title: 'This item is sold As-Described',
      buyerProtectionTitle: 'Reverb Buyer Protection',
      body: 'This item is sold As-Described and cannot be returned unless it arrives in a condition different from how it was described or photographed. Items must be returned in original, as-shipped condition with all original packaging.',
      buyerProtectionMessage: "Reverb has your back if your item is lost, damaged, or doesn't match its description. Simply report any issues within 7 days and we'll help you get a full refund.",
      linkText: 'Learn More.',
      learnMoreText: 'Learn More',
      buyerProtectionLinkText: 'Learn more about Reverb Buyer Protection.',
    },
    sellerDetails: 'Seller Details',
    prop65: {
      californiaResidents: 'California Residents:',
      warning: '{{tagOpen}}WARNING:{{tagClose}} {{warningText}}. For more information, go to {{linkOpen}}www.P65Warnings.ca.gov{{linkClose}}.',
    },
    shopReviews: 'Reviews of this Shop',
    positiveRating: '{{percent}}% positive',
    listed: 'Listed',
    views: 'Views',
    watchers: 'Watchers',
    offers: 'Offers',
    downloads: 'Downloads',
    overAMonthAgo: 'over a month ago',
    manufacturersSafetyLink: 'Product safety information may be available {{linkOpen}}here{{linkClose}}.',
  },
  item2ReverbProtection: {
    title: 'Buy with Confidence – Reverb Protection has you covered',
    liveSupport: 'Live support from an expert team of musicians',
    returns: 'Simple and straightforward returns',
    safeCommunity: 'A safe community for finding the gear you want',
    altText: 'Reverb Protection',
    buyWithConfidence: 'Buy with Confidence',
    hasYouCovered: 'Reverb Protection has you covered',
  },
  item2RecentlyViewedCsps: {
    title: 'Recently Viewed Products',
  },
  item2ShippingWarning: {
    contact: '{{linkOpen}}contact{{linkClose}}',
    message: 'This seller has not set a shipping cost for <strong>{{shippingCountry}}</strong>. Please {{contact}} them to ask about shipping.',
    changeShippingRegion: 'Change shipping region',
  },
  item2AffirmCallout: {
    asLowAsWithAffirm: 'As low as {{tagOpen}}{{price}}/month{{tagClose}} with Affirm monthly payments.',
    zeroFinancing: {
      nudgeTitle: 'Buy now, pay later – from 0% APR',
    },
    splitPay: 'or 4 interest-free payments of {{price}} with',
  },
  item2Pricing: {
    viewActiveOffer: 'View Active Offer',
    viewOfferHistory: '{{linkOpen}}View Offer History{{linkClose}}',
    gearIsGone: 'It looks like that piece of gear is gone.',
    watchThis: 'Watch this gear and we\'ll notify you if it becomes available again.',
    statusIndicatorSold: 'This piece of gear has sold',
    statusIndicatorEnded: 'This listing has ended',
    statusIndicatorUnavailable: 'This seller is currently away',
    statusIndicatorViewSimilar: 'View similar gear from other sellers on Reverb',
  },
  item2PricingNudge: {
    tooltip: 'Based on recent sales and listing prices for items matching "{{productName}}" in {{conditionName}} condition',
    good: {
      title: 'Good Value',
      description: 'Near average price for this condition',
    },
    great: {
      title: 'Great Value',
      description: 'Below average price for this condition',
    },
  },
  metadata: {
    titlePostfix: {
      au: 'Reverb Australia',
      ca: 'Reverb Canada',
      de: 'Reverb Deutschland',
      'en-at': 'Reverb Austria',
      'en-be': 'Reverb Belgium',
      'en-bg': 'Reverb Bulgaria',
      'en-br': 'Reverb Brazil',
      'en-cy': 'Reverb Cyprus',
      'en-cz': 'Reverb Czechia',
      'en-dk': 'Reverb Denmark',
      'en-ee': 'Reverb Estonia',
      'en-fi': 'Reverb Finland',
      'en-fr': 'Reverb France',
      'en-gr': 'Reverb Greece',
      'en-hr': 'Reverb Croatia',
      'en-hu': 'Reverb Hungary',
      'en-lt': 'Reverb Lithuania',
      'en-lu': 'Reverb Luxembourg',
      'en-lv': 'Reverb Latvia',
      'en-nl': 'Reverb The Netherlands',
      'en-no': 'Reverb Norway',
      'en-pl': 'Reverb Poland',
      'en-pt': 'Reverb Portugal',
      'en-ro': 'Reverb Romania',
      'en-se': 'Reverb Sweden',
      'en-si': 'Reverb Slovenia',
      'en-sk': 'Reverb Slovakia',
      es: 'Reverb España',
      fr: 'Reverb France',
      ie: 'Reverb Ireland',
      it: 'Reverb Italia',
      jp: 'Reverb Japan',
      mt: 'Reverb Malta',
      uk: 'Reverb UK',
    },
  },
  shipmentPackingHelpLink: {
    packingGuides: {
      guitars: 'Read our Guitar Packing Guide',
      generalGear: 'Read our Listings Packing Guide',
      keyboards: 'Read our Keyboard Packing Guide',
      effectsAndPedals: 'Read our Effects and Pedals Packing Guide',
      drumKits: 'Read our Drum Kit Packing Guide',
      amps: 'Read our Amps Packing Guide',
      proAudioGear: 'Read our Pro Audio Gear Packing Guide',
      modular: 'Read our Modular Gear Packing Guide',
      stereoReceivers: 'Read our Stereo Receivers Packing Guide',
    },
  },
  item2ShippingPrompt: {
    title: 'Congrats, your listing is live!',
    getReadyToPackYourItem: 'Get ready to pack your item.',
    needShippingMaterials: 'Need shipping materials?',
    viewReverbShippingBoxes: 'View Shipping Boxes',
    generalGear: 'listings',
    guitars: 'guitars',
    keyboards: 'keyboards',
    effectsAndPedals: 'effects and pedals',
    drumKits: 'drum kits',
    amps: 'amps',
    proAudioGear: 'pro audio gear',
    modular: 'modular gear',
    stereoReceivers: 'stereo receivers',
    shippingGuideDescription: "You'll have to ship your item within 3 days once it sells, so now's the time to prepare. Get your box from Reverb and <a class='text-link weight-bold' href='{{href}}' target='_blank'>visit our packing guide for {{items}}</a> so you're ready to go.",
    shippingBox: 'Shipping Box',
    shippingBoxImage: {
      alt: 'Shipping Boxes',
    },
  },
  featuredListingModule: {
    viewListing: 'View Full Listing',
  },
  euOdrDetails: {
    name: 'Name',
    address: 'Address',
    email: 'Email',
    phone: 'Phone',
    vatId: 'VAT ID',
    requiredInfo: 'For extrajudicial settlements of consumer disputes, the European Union has launched an online platform ("ODR platform")',
  },
  removeFromCart: {
    confirmation: 'Are you sure?',
    no: 'Cancel',
    yes: 'Yes, Remove',
    moveToWatchList: 'Move to Watch List',
    remove: 'Remove',
  },
  item2OrderDetails: {
    datePurchased: 'You purchased this item on {{date}}',
    orderLink: 'View Order #{{orderNumber}}',
  },
  selling: {
    bulkSale: {
      modalTitle: 'Add To Sales',
      listingsSelected: {
        one: 'You have 1 selected listing.',
        other: 'You have {{formattedCount}} selected listings.',
      },
      noneFound: 'No sales found matching those filters',
      facets: {
        saleType: 'Sale Type',
        promoted: 'Reverb Promoted Sales',
        mySales: 'My Sales',
        saleStatus: 'Sale Status',
        active: 'Active',
        upcoming: 'Upcoming',
        filterSales: 'Filter Sales',
        reverbAndLp: 'Reverb and LP',
        reverbOnly: 'Reverb Only',
        lpOnly: 'LP Only',
        searchSales: 'Search Sales',
        allSales: 'All Sales',
        sortBy: 'Sort By',
        alpha: 'A-Z',
        alphaDesc: 'Z-A',
        endingSoonest: 'Ending Soonest',
      },
      displayStatus: {
        live: 'Active',
        upcoming: 'Upcoming',
        ended: 'Ended',
      },
      loading: 'Loading Sales...',
      fetchError: 'Something went wrong. Please try again.',
      sales: 'Sales',
      addToSales: 'Add to Sales',
      noSales: 'It looks like there are no upcoming sales.',
      createASale: 'Create a new sale',
      header: 'Add Listings to a Sale',
      addDisabled: 'Add Listings',
      error: 'There was an error. Please try again.',
      usedOnlyHint: 'Brand New Listings Not Included',
      usedOnlyTooltip: 'Only {{linkStart}}Preferred Sellers{{linkEnd}} can add Brand New listings to Promoted Sales',
      addingListings: 'Adding Listings',
      noDiscount: 'N/A',
      datesActive: 'Dates Active',
      noDatesActive: 'N/A',
      dateSummary: '{{startsAt}} - {{endsAt}}',
      discount: 'Discount',
      status: 'Status',
      viewSale: '(view sale page)',
      remove: '(remove)',
      addListings: {
        one: 'Add 1 Listing',
        other: 'Add {{formattedCount}} Listings',
      },
      addUsedListings: {
        one: 'Add 1 Used Listing',
        other: 'Add {{formattedCount}} Used Listings',
      },
      removeListings: {
        one: 'Remove 1 Listing',
        other: 'Remove {{formattedCount}} Listings',
      },
      listingsAdded: {
        one: '1 listing added',
        other: '{{formattedCount}} listings added',
      },
      listingsRemoved: {
        one: '1 listing removed',
        other: '{{formattedCount}} listings removed',
      },
      listingsAlreadyAdded: {
        one: '1 listing already added',
        other: '{{formattedCount}} listings already added',
      },
      pleaseWaitAdding: "Please allow a minute for your listings to be added. Ineligible listings won't apply.",
      pleaseWaitRemoving: 'Please allow a minute for your listings to be removed.',
      featuredSale: 'Featured Sale',
    },
  },
  listingPaymentPolicies: {
    accepts: 'This seller accepts the following payment methods:',
    direct_checkout: 'Reverb Payments (Credit Cards & Reverb Gift Cards)',
    affirm: 'Affirm Financing',
    dc_via_paypal: 'PayPal',
    paypal: 'PayPal',
    paypal_pay_later: 'PayPal Pay Later',
    sofort: 'Sofort',
    klarna_account: 'Klarna',
  },
  listingShippingPolicies: {
    allRates: 'View All Shipping Rates',
    shipsFrom: 'You can choose your shipping method during checkout. This item ships from {{location}} via:',
    region: 'Region',
    rate: 'Rate',
    localPickup: 'Local Pickup',
    standardShipping: '{{rate}} Standard Shipping',
    combinedShipping: '{{rate}} Combined Shipping',
    expeditedShipping: '{{rate}} Expedited 2-Day Shipping',
    standardAndExpeditedRate: '{{rate}} / {{expeditedRate}} for 2-Day',
    calculatedAtCheckout: 'Calculated at checkout',
    estimatedStandardShipping: 'Standard Shipping',
  },
  listingReturnPolicies: {
    refundsHeader: 'Refunds',
    refundsAffirmHeader: 'Affirm Return Policy',
    returnWindowDescription: 'This product can be returned within {{days}} days of receipt.',
    returnPolicyNotSet: 'This shop has not set a return policy.',
    contactSeller: 'Message this seller to ask about their Return Policy.',
    affirmReturnPolicy: 'When paying with {{linkStart}}Affirm{{linkEnd}}, following return policies apply in addition to the seller\'s return policies: returns are accepted within 7 days of receiving new items and 3 days of receiving used items. Read more on the {{linkStart}}Affirm FAQ{{linkEnd}}.',
    viewAll: 'Full Return Policy',
    digitalReturnPolicy: 'This item is sold As-Described and cannot be returned. If you have any issues accessing your item, please reach out to our Support team. For issues involving downloading non-Reverb owned software, please contact the manufacturer directly.',
  },
  paymentOptions: {
    accepts: 'Accepted Payment Methods',
  },
  item2EndedListingSearchButton: {
    searchForItem: 'Search for gear like this',
    similarListingsCta: 'View similar gear',
  },
  similarListingsMessage: {
    title: 'Similar gear on Reverb',
  },
  shippingRegions: {
    AD: 'Andorra',
    AE: 'United Arab Emirates',
    AF: 'Afghanistan',
    AG: 'Antigua and Barbuda',
    AI: 'Anguilla',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AR: 'Argentina',
    AS: 'American Samoa',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AX: '\u00c5land Islands',
    AZ: 'Azerbaijan',
    BA: 'Bosnia and Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladesh',
    BE: 'Belgium',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Bahrain',
    BI: 'Burundi',
    BJ: 'Benin',
    BL: 'Saint Barth\u00e9lemy',
    BM: 'Bermuda',
    BN: 'Brunei Darussalam',
    BO: 'Bolivia, Plurinational State of',
    BQ: 'Bonaire, Sint Eustatius and Saba',
    BR: 'Brazil',
    BS: 'Bahamas',
    BT: 'Bhutan',
    BV: 'Bouvet Island',
    BW: 'Botswana',
    BY: 'Belarus',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Cocos (Keeling) Islands',
    CD: 'Congo, The Democratic Republic of the',
    CF: 'Central African Republic',
    CG: 'Congo',
    CH: 'Switzerland',
    CI: "C\u00f4te d'Ivoire",
    CK: 'Cook Islands',
    CL: 'Chile',
    CM: 'Cameroon',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cape Verde',
    CW: 'Cura\u00e7ao',
    CX: 'Christmas Island',
    CY: 'Cyprus',
    CZ: 'Czechia',
    DE: 'Germany',
    DJ: 'Djibouti',
    DK: 'Denmark',
    DM: 'Dominica',
    DO: 'Dominican Republic',
    DZ: 'Algeria',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egypt',
    EH: 'Western Sahara',
    ER: 'Eritrea',
    ES: 'Spain',
    ET: 'Ethiopia',
    EUR_EU: 'European Union',
    FI: 'Finland',
    FJ: 'Fiji',
    FK: 'Falkland Islands (Malvinas)',
    FM: 'Micronesia, Federated States of',
    FO: 'Faroe Islands',
    FR: 'France',
    GA: 'Gabon',
    GB: 'United Kingdom',
    GD: 'Grenada',
    GE: 'Georgia',
    GF: 'French Guiana',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Greenland',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadeloupe',
    GQ: 'Equatorial Guinea',
    GR: 'Greece',
    GS: 'South Georgia and the South Sandwich Islands',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HM: 'Heard Island and McDonald Islands',
    HN: 'Honduras',
    HR: 'Croatia',
    HT: 'Haiti',
    HU: 'Hungary',
    ID: 'Indonesia',
    IE: 'Ireland',
    IL: 'Israel',
    IM: 'Isle of Man',
    IN: 'India',
    IO: 'British Indian Ocean Territory',
    IQ: 'Iraq',
    IR: 'Iran, Islamic Republic of',
    IS: 'Iceland',
    IT: 'Italy',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordan',
    JP: 'Japan',
    KE: 'Kenya',
    KG: 'Kyrgyzstan',
    KH: 'Cambodia',
    KI: 'Kiribati',
    KM: 'Comoros',
    KN: 'Saint Kitts and Nevis',
    KP: "Korea, Democratic People's Republic of",
    KR: 'Korea, Republic of',
    KW: 'Kuwait',
    KY: 'Cayman Islands',
    KZ: 'Kazakhstan',
    LA: "Lao People's Democratic Republic",
    LB: 'Lebanon',
    LC: 'Saint Lucia',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesotho',
    LT: 'Lithuania',
    LU: 'Luxembourg',
    LV: 'Latvia',
    LY: 'Libya',
    MA: 'Morocco',
    MC: 'Monaco',
    MD: 'Moldova, Republic of',
    ME: 'Montenegro',
    MF: 'Saint Martin (French part)',
    MG: 'Madagascar',
    MH: 'Marshall Islands',
    MK: 'Macedonia, Republic of',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Northern Mariana Islands',
    MQ: 'Martinique',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauritius',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexico',
    MY: 'Malaysia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'New Caledonia',
    NE: 'Niger',
    NF: 'Norfolk Island',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Netherlands',
    NO: 'Norway',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'New Zealand',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Peru',
    PF: 'French Polynesia',
    PG: 'Papua New Guinea',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Poland',
    PM: 'Saint Pierre and Miquelon',
    PN: 'Pitcairn',
    PS: 'Palestine, State of',
    PT: 'Portugal',
    PW: 'Palau',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'R\u00e9union',
    RO: 'Romania',
    RS: 'Serbia',
    RU: 'Russian Federation',
    RW: 'Rwanda',
    SA: 'Saudi Arabia',
    SB: 'Solomon Islands',
    SC: 'Seychelles',
    SD: 'Sudan',
    SE: 'Sweden',
    SG: 'Singapore',
    SH: 'Saint Helena, Ascension and Tristan da Cunha',
    SI: 'Slovenia',
    SJ: 'Svalbard and Jan Mayen',
    SK: 'Slovakia',
    SL: 'Sierra Leone',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Suriname',
    SS: 'South Sudan',
    ST: 'Sao Tome and Principe',
    SV: 'El Salvador',
    SX: 'Sint Maarten (Dutch part)',
    SY: 'Syrian Arab Republic',
    SZ: 'Swaziland',
    TC: 'Turks and Caicos Islands',
    TD: 'Chad',
    TF: 'French Southern Territories',
    TG: 'Togo',
    TH: 'Thailand',
    TJ: 'Tajikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkmenistan',
    TN: 'Tunisia',
    TO: 'Tonga',
    TR: 'Turkey',
    TT: 'Trinidad and Tobago',
    TV: 'Tuvalu',
    TW: 'Taiwan',
    TZ: 'Tanzania, United Republic of',
    UA: 'Ukraine',
    UG: 'Uganda',
    UM: 'United States Minor Outlying Islands',
    US: 'United States',
    US_AK: 'Alaska',
    US_CON: 'Continental U.S.',
    US_HI: 'Hawaii',
    US_OTHER: 'Other U.S. Territories',
    US_PR: 'Puerto Rico',
    UY: 'Uruguay',
    UZ: 'Uzbekistan',
    VA: 'Holy See (Vatican City State)',
    VC: 'Saint Vincent and the Grenadines',
    VE: 'Venezuela, Bolivarian Republic of',
    VG: 'Virgin Islands, British',
    VI: 'Virgin Islands, U.S.',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis and Futuna',
    WS: 'Samoa',
    XX: 'Everywhere else',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'South Africa',
    ZM: 'Zambia',
    ZW: 'Zimbabwe',
    AFRICA: 'Africa',
    ASIA: 'Asia',
    EUR_NON_EU: 'Europe (Non-EU)',
    NORTH_AMERICA: 'North America',
    OCEANIA: 'Oceania',
    SOUTH_AMERICA: 'South America',
  },
  item2FeedbackList: {
    viewAll: 'View all reviews',
  },
  item2FeedbackItem: {
    byline: '{{name}} – {{date}}',
  },
  item2Feedback: {
    filters: {
      all: 'All ({{count}})',
      seller: 'Seller ({{count}})',
      buyer: 'Buyer ({{count}})',
    },
  },
  bump: {
    reverbBump: 'Reverb Bump',
    bumped: 'Bumped',
    noBid: 'None',
    recommendedBid: 'A {{tagOpen}}{{recAmount}}\% Bump{{tagClose}} or higher is recommended based on current competitive similar listings.',
    recommendedBidMax: 'A {{tagOpen}}{{recAmount}}\% Bump{{tagClose}} is recommended based on current competitive similar listings.',
    successListingRow: 'Bumped %{percent}%',
    successBid: 'Bump Saved at %{percent}%',
    successBidDelete: 'Bump Removed',
    errorBid: 'Sorry, there was an error. Please try again.',
    deleteBidBumpV2: 'This item is no longer Bumped. You will not be charged Bump fees on it.',
    bidFetchError: 'There was an issue displaying your Bump tier. Please wait a minute and refresh the page.',
    viewError: 'Bump error',
    adminBump: 'You\'ve been Bumped by Reverb! Your %{percentage}% Bump credit will last through %{expirationDate}.',
    hideSlider: 'Hide Bump Tiers',
    setup: {
      bumpHelpsSell: 'Bump Helps Your Item Sell',
      bumpHelpsSellSubheading: 'It\'s simple, transparent, and risk-free. %{linkOpen}Learn more%{linkClose}.',
      listingOnBiggerStage: 'Put Your Listing on a Bigger Stage',
      premiumPlacement: 'Bump gives your listing premium placement across Reverb.',
      learnMore: 'Learn more',
      explanation: {
        bumpIcon: 'Bump Icon',
        one: '1)',
        stepOne: 'Select a Bump tier based on your price. Higher tiers get more views.',
        two: '2)',
        stepTwo: 'Get seen more often on our most viewed pages, as well as in our emails, on Google Shopping, and on Facebook.',
        three: '3)',
        stepThree: 'Pay only if it sells. Unbump at any time and pay nothing.',
        getViewedMore: 'Get viewed more often on:',
        addToCartSlot: 'Reverb\'s Comparison Shopping Pages',
        reverbSiteEmail: 'Reverb\'s site and emails',
        facebookAds: 'Facebook Product Ads',
        googleShopping: 'Google Shopping',
        body: 'Reverb Bump pushes your Brand New listings to the top of search results and gets you prime placement on Comparison Shopping Pages.%{lineBreak}%{lineBreak}You\'re only charged if someone clicks on your listing in a Bump slot and then buys it within 7 days. %{linkOpen}Learn more%{linkClose}.',
        valueProp: 'Comparison Shopping Pages convert twice as well as listing pages. The top position on those pages converts even better.',
        onlyPayUnbumpAnytime: 'You only pay if your item sells. Unbump at any time. %{linkOpen}Learn more%{linkClose}.',
        onlyPaySells: 'You only pay if your item sells. Unbump at any time and pay nothing.',
      },
      bumpChart: 'Bump more to get more views graphic',
      lessViews: 'Less Views',
      moreViews: 'Bump Views',
      mostPopular: 'Most Popular Tiers',
      recommendedTiers: 'Recommended',
      manyMoreViews: '3x Bump Views',
      manyMoreExperiment: 'More Views',
      selectBumpTier: 'Select a Bump Tier',
      pickBumpTier: 'Select a Bump Tier, get more views',
      example: 'How much will I pay for Bump?',
      bumpChargesExplainedNoShipping: 'If you Bumped your item at %{exampleBumpPercentString}% and then it sold for %{listingPriceString}, you\'d pay a Bump fee of %{exampleSpendString} (on top of our standard %{sellingFee}% selling fee).',
      bumpChargesExplainedWithShipping: 'If you Bumped your item at %{exampleBumpPercentString}% and then it sold for %{listingPriceString} plus %{shippingPriceString} shipping, you\'d pay a Bump fee of %{exampleSpendString} (on top of our standard %{sellingFee}% selling fee).',
    },
    change: {
      currentBid: 'Current Bump Tier: %{bidPercent}%',
      performance: 'Bump Performance',
      currentRate: 'Current Max Bump Tier',
      adjustRate: 'Adjust Bump Tier',
      currentPercent: '%{percent}%',
      numberSold: 'Sold',
      spent: 'Spent',
      sales: 'Sales',
      impressions: 'Bumped Views',
    },
    modal: {
      title: 'Want to Bump This Listing?',
    },
  },
  bumpModal: {
    title: 'Bump your listing',
    cancel: 'Cancel',
    save: 'Save & Apply',
    reverbBump: 'Reverb Bump',
    overview: '{{children}} boosts the exposure of your listing, putting it in front of more buyers, increasing the likelihood of a sale.',
    setRate: 'Set a Bump Rate*',
    setRateHelp: 'A higher rate can help you reach more views',
    recommended: 'Recommended',
    recommendedRate: '{{children}} (including shipping)',
    recommendedRateAmount: '{{percent}}% of final price',
    recommendedRateDescription: 'Our calculations show that this gets you the best chance for more views.',
    customRate: 'Set a custom rate',
    howItWorks: 'How it works',
    bumpDescription: 'Promoting your listing with Reverb Bump helps your gear appear more frequently in high visibility spots across the site and app, such as search results, item pages, and product pages.',
    bumpStep1: 'Set your Bump as a percentage of your item\'s final sale price. Read more about {{children}}.',
    bumpStep1Link: 'how to bump your listing',
    bumpStep2: 'Reverb will push your listing into optimal placements based on the competitiveness of your bump rate and the quality of your listing. Placements include:',
    bumpExample1: 'the first page of search results',
    bumpExample2: 'featured listing on product pages',
    bumpExample3: 'offsite advertising opportunities',
    bumpExample4: 'similar listing rows on item pages',
    bumpStep3: 'Pay the Bump fee when it sells, which is deducted from the product amount + shipping total (not including tax).',
    readMore: 'Read more about Bump by visiting our Seller Help Center',
    footnote: '* Reverb Bump is a promoted listing advertising service. Reverb Bump fees are only billed when an item sells, are based on the item\'s final sales price, and are in addition to Reverb\'s 5% selling fee.',
  },
  bumpModalV2: {
    buttonBump: 'Bump Your Listing',
    buttonEdit: 'Edit Bump Amount',
    buttonListingRow: 'Bump',
    buttonBumpSmall: 'Bump',
    bumpAmount: 'This listing is currently bumped at %{tagOpen}%{amount}%{tagClose}',
    bumpAmountListingRow: 'Bumped %{amount}%',
    enableBumpCheckbox: 'Bump This Item',
    buttonAriaLabel: 'Edit bump settings',
    modal: {
      title: 'Bump Your Listing',
      save: 'Save & Apply',
      customBumpWarning: 'Bump rates must be between {{minBump}}%-{{maxBump}}% and in {{bumpIncrement}}% increments',
      recommended: 'Recommended',
      customRate: 'Custom Bump Rate',
      doNotBump: 'Don\'t Bump this item',
      description: {
        promoteInPremiumSpots: 'Bumping this listing %{tagOpen}promotes it in premium slots%{tagClose} across search results and on our homepage.',
        promoteInPremiumSpotswCP: 'Bumping this listing %{tagOpen}promotes it in premium slots%{tagClose} across search results, on our homepage, and on our product page for the %{linkOpen}%{cspTitle}%{linkClose}.',
        bumpModalBody: 'Our recommended Bump rate gives your listing %{tagOpen}the highest likelihood of exposure.%{tagClose}',
        payIfSells: 'You only pay for Bump when your item sells. %{linkOpen}Learn More%{linkClose}',
      },
    },
    copyV2: {
      title: 'Promote Your Listing with Reverb Bump',
      howItWorks: 'How it works',
      descriptionParagraph1: 'By promoting your listing with Reverb Bump, your item may appear more frequently in high-visibility spots across the site and app.',
      descriptionParagraph2: 'For an additional, opt-in fee, your listing may get more placements:',
      bumpLocationBullet1: 'In search results',
      bumpLocationBullet2: 'On our homepage',
      bumpLocationBullet3: 'At the top of product pages',
      payIfSells: 'With a higher Reverb Bump Rate, your item may appear more frequently than listings with a lower rate. You only pay for Bump when your item sells. %{linkOpen}Learn More%{linkClose}',
      setYourBumpRate: 'Set your Bump Rate*:',
      recommendedRate: '{{percent}}% of final selling price (including shipping)',
      recommendedRateDescription: 'This gives your listing the best chance for more views based on the Reverb Bump rates of similar listings.',
      customRate: 'Custom Bump Rate',
      optOut: 'I do not want additional exposure for this item and opt out of Reverb Bump.',
      save: 'Save & Apply',
      cancel: 'Cancel',
      footnote: '*Reverb Bump is a promoted listing advertising service. Reverb Bump fees are only billed when an item sells, are based on the item\'s final sales price, and are in addition to Reverb\'s 5% selling fee.',
    },
    buttonLabel: 'Choose your Bump amount:',
    percentileRecommendation: 'We recommend a Bump rate of %{tagOpen}%{bumpPercent}%%{tagClose}, which will promote your listing ahead of %{tagOpen}%{percentile}% of similarly bumped items.%{tagClose}',
    buyBoxRecommendation: 'We recommend a Bump rate of %{tagOpen}%{bumpPercent}%%{tagClose}, which gives this listing the %{tagOpen}highest likelihood of exposure%{tagClose} based on the Bump rate of similar listings.',
    customButton: 'Custom',
  },
  bumpExplainer: {
    heading: 'Bump Helps Your Item Sell',
    subheading: 'It\'s simple and transparent. %{linkOpen}Learn more%{linkClose}',
    bullet1: 'Set your Bump rate as a percentage of your item\'s final sale price—higher rates get more views.',
    bullet2: 'Bump promotes your listing in premium slots across Reverb.',
    bullet3: 'You only pay when your item sells. Remove Bump at any time and pay nothing.',
  },
  rollupCard: {
    moreColors: 'Multiple colors available',
    brandNew: 'Brand New',
  },
  anyoneCanSellOnReverbCTA: {
    imageAlt: 'Ended listing banner',
    title: 'Anyone can sell on Reverb',
    copy: 'List your item today to get it in front of thousands of eyes, quickly and easily. %{linkOpen}Learn more on the Seller Hub.%{linkClose}',
    button: 'Sell Yours Now',
  },
  sellYoursCTA: {
    imageAlt: 'Sell Yours Now Background',
    title: 'Want to Sell Yours?',
    copy: 'Anyone can sell on Reverb. List your %{cspTitle} today to get it in front of thousands of eyes, quickly and easily. %{linkOpen}Learn more on the Seller Hub.%{linkClose}',
    button: 'Sell Yours Now',
  },
  sellYoursV4: {
    title: 'Own one like this?',
    copy: 'Make room for new gear in minutes.',
    button: 'Sell Yours',
    altIconText: 'Money icon',
  },
  siteFooter: {
    about: 'About Reverb',
    affiliate: 'Affiliate Program',
    brands: 'Brands',
    buyingGuide: 'Buying Guides',
    careers: 'Careers',
    categories: 'Categories',
    company: 'Company',
    contactSupport: 'Contact Support',
    deals: 'Deals and Steals',
    financing: '0% Financing',
    giftCards: 'Gift Cards',
    help: 'Help & Tools',
    helpCenter: 'Help Center',
    integrations: 'Integrations & API',
    mobileApps: 'Mobile Apps',
    new: 'New and Popular',
    news: 'Reverb News',
    newsletterHeader: 'Get the Best of Reverb in Your Inbox',
    press: 'Press',
    priceDrops: 'Price Drops',
    priceGuide: 'Price Guide',
    resources: 'Resources',
    reverbGives: 'Reverb Gives',
    reverbPayments: 'Reverb Payments FAQ',
    reverbProtection: 'Reverb Protection',
    sell: 'Sell on Reverb',
    sellerHub: 'Seller Hub',
    shopGear: 'Shop Gear',
    shops: 'Shops',
    trust: 'Trust & Safety',
  },
  siteCallouts: {
    givesTitle: 'Reverb Gives',
    givesDescription: 'Your purchases help youth music programs get the gear they need to make music.',
    carbonTitle: 'Carbon-Offset Shipping',
    carbonDescription: 'Your purchases also help protect forests, including trees traditionally used to make instruments.',
  },
  siteSubFooter: {
    copyright: '© {{year}} Reverb.com LLC',
    privacy: 'Privacy Policy',
    terms: 'Terms & Policies',
    qrCode: 'App Install QR Code',
    getTheApp: 'Scan with your phone to get the Reverb app:',
    fineprint: 'The Apple logo and the App Store are trademarks of Apple Inc. Google Play and the Google Play logo are trademarks of Google LLC.',
  },
  localePicker: {
    localeSettings: 'Locale Settings',
    shippingRegionLabel: 'Choose your shipping region:',
    languageLabel: 'Choose your language:',
    currencyLabel: 'Choose your currency:',
    save: 'Save',
  },
  acceptLocaleSettings: {
    title: "Based on your location, we've changed your settings",
    shippingRegionLabel: 'Shipping region:',
    currencyLabel: 'Currency:',
    updateButtonText: 'Update locale settings',
    acceptButtonText: 'Accept',
  },
  internationalSettingsChanged: {
    cancel: 'Do not update',
  },
  privacySettingsModal: {
    privacySettings: {
      title: 'Privacy Settings',
      listIntro: 'Reverb uses cookies and similar technologies to give you a better experience, enabling things like:',
      listOutro: 'Detailed information can be found in Reverb\'s {{cookieLinkOpen}}Cookies & Similar Technologies Policy{{cookieLinkClose}} and our {{privacyLinkOpen}}Privacy Policy{{privacyLinkClose}}.',
      listItem0: 'Basic site functions',
      listItem1: 'Secure, safe transactions',
      listItem2: 'Secure account login',
      listItem3: 'Remembering account, browser, and regional preferences',
      listItem4: 'Remembering privacy and security settings',
      listItem5: 'Analyzing site traffic and usage',
      listItem6: 'Personalized search, content, and recommendations',
      listItem7: 'Showing relevant, targeted ads on and off Reverb',
    },
    requiredCookies: {
      title: 'Required Cookies & Technologies',
      alwaysOn: 'Always On',
      text: 'Some of the technologies we use are necessary for critical functions like security and site integrity, account authentication, security and privacy preferences, internal site usage and maintenance data, and to make the site work correctly for browsing and transactions.',
    },
    personalizedAds: {
      title: 'Personalized Advertising',
      text: 'We use marketing and advertising partners (who may have their own information they\'ve collected) to provide you with personalized content and recommendations based on your activity on Reverb. Users may opt out of personalized partner advertising to prevent data being sent to these partners. Users opting out may continue to see offsite Reverb ads, but it may make them less relevant or more repetitive.',
      checkboxLabel: 'Personalized Partner Advertising',
    },
    thirdPartyData: {
      title: 'Manage Cookies',
      text: 'We use marketing and advertising partners (who may have their own information they\'ve collected) to provide you with personalized content and recommendations based on your activity on Reverb. Users may opt out of personalized partner advertising to prevent data being sent to these partners. Users opting out may continue to see offsite Reverb ads, but it may make them less relevant or more repetitive.',
      label: {
        adData: 'Partner Advertising',
        adStorage: 'Performance',
        adPersonalization: 'Personalized Advertising',
        analyticsStorage: 'Analytics',
      },
      tooltip: {
        adData: 'Allow Reverb to share my data related to advertising with Google.',
        adStorage: 'Allow Reverb to store my advertising related data.',
        adPersonalization: 'Allow Reverb to personalize what ads I see. This will help us show you ads for gear that is relevant and most interesting to you.',
        analyticsStorage: 'Allow Reverb to store my data for analytics purposes.',
      },
    },
  },
  userPrivacyConsentModal: {
    consentBlurb: 'To give you the best experience, we use cookies and similar technologies for performance, analytics, personalization, advertising, and to help our site function. Want to know more? Read our {{cookiePolicyLinkOpen}}Cookie Policy{{linkClose}}. You can change your preferences any time in your Privacy Settings.',
    updateSettings: 'Update Privacy Settings',
    accept: 'Accept',
    title: 'A quick note about cookies',
  },
  compareOtherSellersLink: {
    linkText: 'Compare Prices from Other Sellers',
  },
  statBlock: {
    previous: 'Previous Period',
  },
  categoryTopProducts: {
    pageTitle: 'Best Sellers',
    pageSubtitle: 'Top products on Reverb over the past 90 days',
    listHeading: 'Best Sellers in {{rootCategoryName}}',
    listHeadingSubcategory: 'Best Sellers in {{rootCategoryName}} - {{subcategoryName}}',
  },
  subscriptions: {
    title: 'Emails',
    generalDescription: 'Toggle the email preferences below on or off to control which communications you\'d like to receive from Reverb.',
    globalUnsubscribe: 'Unsubscribe from All Emails',
    globalSubscribe: 'Subscribe to Receive Emails',
    globalOptIn: 'Re-send Opt-In Email',
    unsubscribedCallout: 'You are currently Unsubscribed from receiving any marketing emails.',
    optedOutCallout: 'It looks like you have not opted in to receive marketing emails from Reverb. You must click the confirmation link in the opt-in email to receive marketing emails.',
    categories: {
      reverbUpdates: 'Reverb Updates',
      yourGear: 'Your Gear',
    },
    emails: {
      FEED: 'Your Daily Feed',
      NEWSLETTER: 'Gear News & Reviews',
      OFFERS_AND_PROMOTIONS: 'Offers & Promotions',
      SELLER_NEWSLETTER: 'Using Reverb',
      WATCH: 'Your Watch List',
    },
    descriptions: {
      FEED: 'Looking for something special? Get notified when gear you\'re interested in gets listed on Reverb. Add your saved searches, favorite brands, and shops to your Daily Feed to receive updates.',
      NEWSLETTER: 'Updates on the latest releases, gear history, demos, interviews, and content created with trusted partners.',
      OFFERS_AND_PROMOTIONS: 'Exclusive offers, sales, artist shop announcements, and more.',
      SELLER_NEWSLETTER: 'Tips on getting started, buying and selling gear, and new features. Plus, you\'ll have the opportunity to provide feedback on how we can improve your Reverb experience.',
      WATCH: 'Looking to score a deal? Get real-time updates on the gear you\'re watching, including price drop notifications and re-stock alerts.',
    },
    success: 'Your email preferences have been updated.',
    errorOnLoad: 'Something went wrong, please try reloading.',
    errorOnUpdate: 'Something went wrong in updating your email preferences. Reload and try again.',
    optInEmailTriggerSuccess: 'Opt-In Email has been resent to your email address.',
    errorOnOptInEmailTrigger: 'Something went wrong. Reload and try again.',
  },
  recommendedListings: {
    title: 'Recommended for You',
    cta: 'See More',
    loggedOutTitle: 'Explore Gear Just for You',
    cpRecommendationsTitle: 'Gear We Think You\'ll Like',
  },
  item2ReturnPolicy: {
    returnWindow: '{{returnWindow}}-Day Return Policy',
    subtitle: 'Enjoy peace of mind with your new gear',
  },
  conditions: {
    'brand-new': 'Brand New',
    mint: 'Mint',
    'mint-inventory': 'Mint',
    'b-stock': 'B-Stock',
    excellent: 'Excellent',
    'very-good': 'Very Good',
    good: 'Good',
    fair: 'Fair',
    poor: 'Poor',
    'non-functioning': 'Non Functioning',
    used: 'Used',
  },
  appInstallCallout: {
    csp: {
      title: 'Buying is easier in the app',
      subtitle: 'Compare prices, conditions, and more with ease.',
    },
    feed: {
      title: 'Get a better Feed experience',
      subtitle: 'Discover worlds of gear at the click of a button.',
    },
    item: {
      title: 'Buying is easier in the app',
      subtitle: 'Watch listings, get instant price updates and more.',
    },
    homepage: {
      title: 'Get a better mobile experience',
      subtitle: 'Easily discover unique gear at the click of a button.',
    },
    sell: {
      title: 'Selling is better in the Reverb app',
      subtitle: 'Snap a photo, publish your listing, and put cash in your pocket.',
    },
    watchlist: {
      title: 'Get Instant Gear Updates',
      subtitle: 'Track price drops, Watchlist updates, and more.',
    },
    cta: 'Get the App',
    close: 'Close',
  },
  productFamily: {
    bestSellers: 'Best Sellers',
    dealsAndSteals: 'Deals & Steals',
    metaDescription: 'Shop for {{productFamilyName}} deals on Reverb, with inventory spanning new, used, vintage, and more',
    gridTitle: 'Shop All Listings',
    noResults: 'Sorry, we couldn\'t find any results matching your selected filters.',
    ...productFamilyQuicklinks,
  },
  productFamilyCTA: {
    deals: 'View our full range of buying options, all in one place',
    seeMore: 'Shop Now',
  },
  contentSponsorship: {
    ad: 'Ad',
    partnership: 'In Partnership With {{partner}}',
  },
  siteBanner: {
    sale: {
      getAppAnd: 'Get App & {{call_to_action}}',
      viewSale: 'View Sale',
    },
  },
  addressFormFields: {
    country: 'Country',
  },
  plaid: {
    connect: 'Connect My Bank with Plaid',
    connectADifferentAccountWithPlaidHtml: '%{icon} Connect a different account with Plaid',
    disclaimer: 'With Plaid, you can link your bank account instantly, using your online banking login credentials. This secure verification ensures your payouts will go to the bank account of your choice.',
    errorMsg: 'An error occurred, please try again. Please contact customer support if the error persists.',
    loading: 'Loading Plaid...',
    pendingAutomaticVerificationHtml: '%{icon} Your new bank account is pending verification by Plaid. Until the verification is complete, you will still be paid out to your bank account on file if you have previously entered one.',
    pendingManualVerificationHtml: 'Your new bank account is pending verification by Plaid. After Plaid has made a small deposit into your account, please select Verify Deposit below.  Until the verification is complete, you will still be paid out to your bank account on file if you have previously entered one.',
    verifyBankDeposits: 'Verify Deposit',
    depositsNotFound: 'Deposit Not Found',
  },
  shippingEstimator: {
    domestic: 'Domestic',
    international: 'International',
    getEstimate: 'Get estimate',
    formLabel: {
      postalCode: 'Your zip/postal code',
      toCountryCode: 'Shipping to',
      weightPounds: 'Total package weight',
      packageDimensions: 'Package dimensions',
      poundsSuffix: 'lb',
      inchesSuffix: 'in',
    },
    examples: {
      header: 'Example measurements',
      subheader: 'Click item to use its measurements',
      name: {
        cable: 'Cable',
        pedal: 'Pedal',
        snare: 'Snare',
        guitar: 'Guitar',
        keyboard: 'Keyboard',
        amp: 'Amp',
      },
      measurementSummary: '{{weight}} lb - {{length}} x {{width}} x {{height}} in',
      useMeasurements: 'Use measurements',
    },
    resultsDisclaimer: 'Estimates are for labels purchased on Reverb and don’t include the cost of packaging, packing services, Reverb Safe Shipping or Signature Required. Outside prices may vary.',
    buyReverb: 'Buy shipping labels on Reverb to save money and get our shipping protection.',
    error: 'We were unable to estimate costs rates for the information provided. Please try again.',
  },
  collectionPromoBanner: {
    title: 'Build your Collection',
    collection: 'Your Collection',
    description: ' on Reverb helps you keep track of everything you love about your gear. Click “Add to My Collection” above to add your new gear.',
  },
  sellerInitiatedRefundFee: {
    feeMessage: {
      underLimit: 'If you initiate more than {{max}} in a month, you will be charged a processing fee for each additional refund you initiate. ',
      atLimit: 'If you initiate any more refunds this month, you will be charged a processing fee for each additional refund you initiate. ',
      exceededLimit: 'In accordance with our policies, your next statement will include a processing fee for each refunded order you\'ve initiated beyond the first {{max}} this month. ',
    },
    helpCenterPolicy: 'Visit the Help Center to review this policy',
    recommended: 'We encourage sellers to initiate refunds on {{max}} or fewer orders per month. ',
    title: {
      underLimit: 'Seller-initiated refunds',
      atOrExceededLimit: 'You\'ve initiated {{count}} refunds this month',
    },
    visitDashboard: 'Visit the dashboard',
  },
  locales: {
    en: 'English',
    de: 'German',
    es: 'Spanish',
    fr: 'French',
    nl: 'Dutch',
    ja: 'Japanese',
    it: 'Italian',
  },
  watchlist: {
    anyoneCanViewLink: 'Anyone with this URL can view your Watch List',
    notWatchingAnything: 'Your Watch List is empty',
    notWatchingEndedListings: "You're not watching any ended listings",
    addItemsToWatchlist: 'Add gear here by tapping the heart on a listing to get updates when the prices drop.',
    endedWatchlistInfo: 'If the gear you want sells or is taken off the market you can still view it here.',
    addFromRecentlyViewed: 'Add from Recently Viewed',
    share: 'Share Your Watch List',
    yours: 'Your Watch List',
    liveListingsTab: 'Live Listings (%{total})',
    endedListingsTab: 'Ended Listings (%{total})',
    userWatchlist: "{{firstName}}'s Watch List",
    copyURL: 'Copy URL',
    yourUniqueURL: 'Your Unique Watchlist URL',
    linkCopiedToClipboard: 'Link copied to clipboard',
  },
  regionalShippingAreas: {
    US_CON: {
      AL: 'Alabama',
      AK: 'Alaska',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DC: 'District of Columbia',
      DE: 'Delaware',
      FL: 'Florida',
      GA: 'Georgia',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PA: 'Pennsylvania',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
    },
    US_CON_MIDWEST: 'Midwest',
    US_CON_NORTHEAST: 'Northeast',
    US_CON_WEST: 'West',
    US_CON_SOUTH: 'South',
    CA: {
      AB: 'Alberta',
      BC: 'British Columbia',
      MB: 'Manitoba',
      NB: 'New Brunswick',
      NL: 'Newfoundland and Labrador',
      NT: 'Northwest Territories',
      NS: 'Nova Scotia',
      NU: 'Nunavut',
      ON: 'Ontario',
      PE: 'Prince Edward Island',
      QC: 'Quebec',
      SK: 'Saskatchewan',
      YT: 'Yukon',
    },
  },
  item2WatchBadge: {
    howToWatchCallout: {
      title: 'Never miss a deal',
      content: 'Watch this item to get notified if the price drops.',
    },
    subsequentListingViewCallout: {
      title: 'Watch for deals',
      content: 'Add to your Favorites for price drop notifications.',
    },
    watchHiatusCallout: {
      title: 'Watch for deals',
      content: 'Add to your Favorites for price drop notifications.',
    },
  },
  giftCardsUnderConstruction: {
    loginPromptTitle: 'Log in to purchase Reverb Gift Card',
    loginPromptHtml: 'You must be logged in to purchase a Reverb Gift Card. Please {{children}}.',
    loginLinkText: 'log in or sign up for an account',
    unavailableTitle: 'Reverb Gift Cards are currently unavailable',
    unavailableMessageHtml: 'Reverb Gift Cards are not currently available for purchase. Shopping for a musician? Check out our {{children}}.',
    giftIdeasLinkText: 'list of last-minute gifts musicians will actually love',
    redeemTitle: 'Purchased or received a Reverb Gift Card?',
    redeemHtml: 'Not to worry. If you’ve purchased or received a Reverb Gift Card, you can still use them as you normally would. Simply {{children}} and your Reverb Credits will be applied to your account.',
    redeemLinkText: 'enter the code here',
  },
  addressAutocomplete: {
    label: 'Street Address',
    placeholder: 'Search for address...',
    manualEntry: 'Enter address manually',
    continueTyping: 'Continue typing to find your address...',
  },
  offersPreferences: {
    title: 'Offers Preferences',
    directOffersFromSellers: 'Direct Offers From Sellers',
    directOffersFromSellersDescription: 'Sellers never have access to your Watch List. Reverb identifies similar gear and sends Direct Offers on their behalf. Learn more about {{offerLinkOpen}}Offers{{linkClose}} and {{directOfferLinkOpen}}Direct Offers{{linkClose}} in our Help Center.',
    receiveOffersForSimilarItemsLabel: 'Receive Direct Offers for similar items',
    receiveOffersForSimilarItemsSublabel: 'Opting out means you\'ll only receive Direct Offers for gear in your Watch List.',
  },
  feedbackPreferences: {
    title: 'Feedback Preferences',
    automateFeedbackLabel: 'Enable automated buyer feedback',
    automateFeedbackSublabel: 'Enabling this feature automatically leaves 5 star reviews for buyers who make a purchase from your shop',
  },
  recommendedProducts: 'Recommended Products',
  browsePage: {
    results: {
      one: '{{formattedCount}} Result',
      other: '{{formattedCount}} Results',
    },
    emptyState: {
      description: 'We don’t have any listings for this product right now.',
    },
  },
  outletCallout: {
    title: 'Reverb Outlet',
    subtitle: 'Qualified outlet items are 20% savings or more.',
    linkText: 'Learn more',
  },
  outletPage: {
    breadcrumb: 'The Reverb Outlet',
    results: {
      one: '{{formattedCount}} result',
      other: '{{formattedCount}} results',
    },
    header: {
      title: 'The Reverb Outlet',
      subtitle: 'Save big on your favorite brands',
      searchPlaceholder: 'Search top-tier gear at outlet prices',
    },
    emptyState: {
      description: 'We don’t have any listings for this product right now.',
    },
    iconNudges: {
      sales: 'Save 20% or more every day',
      explore: 'Find new and like-new gear for less',
      trust: 'Shop from authorized retailers and brands',
      shipping: 'Get free shipping and easy returns',
    },
    footer: {
      title: 'What will you find in The Reverb Outlet?',
      brand: {
        title: 'Brand new',
        text: 'New gear in the The Reverb Outlet is typically overstock or clearance music gear that pro sellers are looking to clear out. This gear is brand new—the only difference is the price!',
      },
      mint: {
        title: 'Mint',
        text: 'Mint gear is completely blemish-free and hasn’t been modified in any way—it simply might have been opened & returned or played in a demo video, so it’s not considered brand new.',
      },
      stock: {
        title: 'B-stock',
        text: 'B-Stock gear plays perfectly and may have a minor issue, like a cosmetic blemish or a non-original box. Because it’s not “factory-fresh,” you get it at a great value.',
      },
    },
  },
  certifiedPreOwned: {
    learnMore: 'Learn more',
  },
  paypalOnboarding: {
    connect: 'Connect Your PayPal Account',
    changeEmail: 'Connect a Different PayPal Account',
  },
  dismissibleTrustBanner: {
    message: 'For your safety, keep all communication on Reverb and never share personal info via messages.',
    learnMore: 'Learn more',
    buttonText: 'Dismiss',
  },
  onwardWarning: {
    header: "You're leaving Reverb",
    goingTo: 'You are being redirected to:',
    warningText: 'We cannot verify this is a safe site, so please be careful. Keep your personal information safe, and never enter Reverb passwords or payment information on non-Reverb websites.',
    buttonText: 'Continue',
  },
  tradeIn: {
    manager: {
      title: 'Pending trade-ins',
      accepted: {
        title: 'Trade-in accepted',
        text: 'Seller will receive an email with next steps to initiate payout.',
      },
      declined: {
        title: 'Trade-in declined',
        text: 'Thanks for letting us know. We’ll follow up with the seller.',
      },
    },
    show: {
      sellerTrading: '{{sellerName}} trading in',
      backButton: 'Back',
      continueButton: 'Continue',
      declineButton: 'Decline trade-in',
      confirmCondition: 'Confirm its condition',
      appraisalPricesChanged: 'Prices have changed since seller initiated trade-in.',
      appraisalDescription: {
        mint: 'Gear is completely blemish-free and has not been modified in any way.',
        excellent: 'Gear has been used, but only has minor cosmetic defects.',
        'very-good': 'Gear with more cosmetic imperfections than Excellent, but is still in great condition overall.',
      },
      acceptModal: {
        title: 'Accept trade-In',
        text: 'Have you and the seller agreed on the condition? Once you accept, we’ll initiate the seller’s payment.',
        button: 'Accept',
      },
      rejectModal: {
        title: 'Decline trade-in',
        reasons: {
          label: 'Why are you declining this trade-in?',
          conditionNotMet: 'Gear is not Very Good condition or better',
          listingIneligible: 'Gear was misidentified by the seller and is not a product eligible for trade-in',
          sellerRejected: 'Seller declined to complete the trade-in',
        },
        message: {
          label: 'Anything you’d like to add?',
          placeholder: 'Provide relevant details about condition, price, etc.',
          error: 'Message too long',
        },
      },
    },
  },
};
