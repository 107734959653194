// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import React from 'react';
import I18n from 'i18n-js';
import classNames from 'classnames';

import { connect } from '@reverbdotcom/commons/src/connect';
import { RCAlertBox, buttonClassName } from '@reverbdotcom/cadence/components';

import SignupSigninModal from '../components/signup_signin_modal';
import { useExpEnabled, useUser } from '../user_hooks';
import { isOwner } from '../user_context_helpers';
import CreateOfferModal from './create_offer_modal';
import { MParticleEventName, trackEvent } from '../elog/mparticle_tracker';
import experiments from '../experiments';
import { LISTING_STATES } from '../constants';
import { mParticleListingFields } from '../components/with_mparticle_listings';
import { withRouter, WithRouterProps } from 'react-router';
import { resolveCurrentView } from '../event_tracking/favorites';
import { withCreateUserActionMutation, CoreUserActionsCreateMutationFn } from '../user_actions/create_action_mutation';
import { SellerOpenToOffersPopover } from './seller_open_to_offers_popover';
import {
  ListingCreateOfferButtonDataFragment,
  CreateUserActionMeDataFragment,
  Item2AvailableActionsDataFragment,
  core_apimessages_UserActionName,
  core_apimessages_UserActionStatus,
  BumpKeyFragment,
} from '../gql/graphql';
import { isItemPage } from '../url_helpers';

/**
 * Bump tracking is conditionally provided by the parent component
 * separately from the required listing fragment.
 *
 */
interface IExternalProps {
  listing: ListingCreateOfferButtonDataFragment & BumpKeyFragment;
  isSeller?: boolean;
  disabled?: boolean;
  offerCount?: number;
  position?: number;
  parentComponentName?: string;
  className?: string;
  availableActions?: Item2AvailableActionsDataFragment[];
  me?: CreateUserActionMeDataFragment;
}

interface MutationProps {
  createUserAction: CoreUserActionsCreateMutationFn;
}

type IProps = IExternalProps & MutationProps & WithRouterProps;

export const ListingCreateOfferButtonFragment = gql`
  fragment ListingCreateOfferButtonData on Listing {
    id
    _id
    state
    offersEnabled
    listingType
    sellerId
    isBuyerOfferEligible
    ...mParticleListingFields
  }
  ${mParticleListingFields}
`;

export const Item2LoggedInOffersCountFragment = gql`
  fragment Item2LoggedInOffersCountData on rql_Me {
    _id
    id
    profile {
      totalBuyerOffersCreated
    }
  }
`;

export function canAcceptOffers(listing: ListingCreateOfferButtonDataFragment) {
  return listing.state === LISTING_STATES.LIVE &&
    listing.offersEnabled &&
    listing.isBuyerOfferEligible;
}

export function availableOfferButtonAction(actions: Item2AvailableActionsDataFragment[]) {
  return actions?.find(action => {
    return action.name === core_apimessages_UserActionName.SELLER_OPEN_TO_OFFERS_CALLOUT
      && action.status === core_apimessages_UserActionStatus.AVAILABLE;
  });
}

function Button({ disabled, toggleModal, applyLoudSecondaryCTA }) {
  return (
    <button
      className={applyLoudSecondaryCTA ? classNames(buttonClassName({ theme: 'loud', variant: 'muted' }), 'offer-action') : 'offer-action'}
      data-disabled={disabled || null}
      onClick={toggleModal}
      type="button"
    >
      {I18n.t('commons.offers.action.create')}
    </button>
  );
}

function OfferSignupCallout() {
  return (
    <div className="mb-4">
      <RCAlertBox type="success">
        <h4 className="size-110 weight-bold mb-2">
          {I18n.t('commons.offers.benefits.title')}
        </h4>
        <ul className="fa-ul">
          <li>
            <span className="fa fa-check fa-li color-green" />
            {I18n.t('commons.offers.benefits.saveMoney')}
          </li>
          <li>
            <span className="fa fa-check fa-li color-green" />
            {I18n.t('commons.offers.benefits.quickResponse')}
          </li>
        </ul>
      </RCAlertBox>
    </div>
  );
}

function SignupSigninModalOrOfferModal({ user, openDialog, handleClose, listing, isSeller }) {
  if (!user || user.loggedOut) {
    return (
      <SignupSigninModal
        active={openDialog}
        onRequestClose={handleClose}
        trackSource="Offer"
        messageComponent={<OfferSignupCallout />}
      />
    );
  }

  return (
    <CreateOfferModal
      listingId={listing.id}
      isSeller={isSeller}
      onCancel={handleClose}
      isDisplayed={openDialog}
      bumpKey={listing.bumpKey}
    />
  );
}

export function CreateOfferButton(props: IProps) {
  const user = useUser();
  const [openDialog, setOpenDialog] = React.useState(false);
  const applyLoudSecondaryCTA = useExpEnabled(experiments.ORANGE_SECONDARY_CTAS_ON_ITEM_PAGE) && isItemPage(props.location);

  React.useEffect(() => {
    if (openDialog) {
      trackEvent({
        eventName: MParticleEventName.ClickedOfferButton,
        listingId: props.listing.id,
        listing: props.listing,
        position: props.position,
        componentName: props.parentComponentName,
        currentView: resolveCurrentView(props.location),
        role: props.isSeller ? 'seller' : 'buyer',
      });
    }
  }, [openDialog]);

  function toggleModal(e) {
    // When handle closing the modal, it will blow up because there is no event being passed in
    // so we have to guard
    e.preventDefault();
    e.stopPropagation();
    setOpenDialog(!openDialog);
  }

  if (!canAcceptOffers(props.listing)) {
    return null;
  }

  return (
    <div className={props.className}>
      <SellerOpenToOffersPopover
        availableActions={props.availableActions}
        createUserAction={props.createUserAction}
        me={props.me}
        listing={props.listing}
        currentView={resolveCurrentView(props.location)}
        anchor={(
          <div>
            <Button
              disabled={props.disabled || isOwner(user, { sellerId: props.listing.sellerId })}
              toggleModal={toggleModal}
              applyLoudSecondaryCTA={applyLoudSecondaryCTA}
            />
          </div>
        )}
      />
      {!!props.offerCount &&
        <div
          data-offer-count
          className="size-80 opacity-70 mt-1 align-center"
        >
          {I18n.t('commons.offers.offerCount', { count: props.offerCount })}
        </div>
      }
      <SignupSigninModalOrOfferModal
        user={user}
        openDialog={openDialog}
        handleClose={() => { setOpenDialog(false); }}
        listing={props.listing}
        isSeller={props.isSeller}
      />
    </div>
  );
}

export default connect<IExternalProps>([
  withCreateUserActionMutation,
  withRouter,
])(CreateOfferButton);
