import { ChildProps, withApollo, WithApolloClient, DataValue } from '@apollo/client/react/hoc';
import React from 'react';
import I18n from 'i18n-js';
import { get, differenceBy, merge, flatten, uniq, isEqual, isEmpty } from 'lodash';
import { browserHistory, withRouter, WithRouterProps } from 'react-router';
import { Location } from 'history';
import { BumpDSACompliancePopover } from '@reverbdotcom/commons/src/components/bump_dsa_compliance_popover';

import {
  combinedMarketplaceSearchQuery,
  mapParams,
  PageSettings,
  includePromotedRows,
  onlyQuery,
  usingSignalSystemMarketplace,
  getCombinedMarketplaceInputs,
  listingsCountQuery,
} from './map_params';
import { getPage, getTotalPages } from '../../pagination_helpers';
import {
  Core_Marketplace_CombinedMarketplaceSearchQuery,
  Core_Marketplace_CombinedMarketplaceSearchQueryVariables,
  reverb_search_ListingsSearchRequest_Sort,
  reverb_signals_Signal_Group,
  core_apimessages_CollectionHeader_CollectionType,
  CoreMarketplaceMarketplaceListingsCount,
  core_apimessages_Ad,
  reverb_personalization_SearchQuery,
} from '@reverbdotcom/commons/src/gql/graphql';
import { MarketplacePathContext, Paths } from '../shared/url_helpers';
import CurationModal from '../discovery/curation/modal';
import HeaderWithMetadata from './header_with_metadata';
import ListingGrid, { ListingProps } from '../listing_grid';
import { applyAutodirect, autoselectedFilters, updateMarketplaceUrl } from './marketplace_autodirects';
import { pageViewTrackedExperiments } from './page_view_tracking_data';
import { listingsFilters, selectedFilters } from '../listings_filters';
import MarketplaceFallbackListings from './marketplace_fallback_listings';
import LowResultsAlert from '../low_results_alert';
import NoResultsAlert from '../no_results_alert';
import { SubqueryRows } from '../subquery_rows';

import CombinedBumpListingGrid from './combined_bump_listing_grid';
import RQLSearchGrid from '@reverbdotcom/commons/src/components/rql_search_grid';
import ScrollTarget from '@reverbdotcom/commons/src/components/scroll_target';
import { withUserContext, IUserContext } from '@reverbdotcom/commons/src/components/user_context_provider';
import { isExperimentEnabled } from '@reverbdotcom/commons/src/user_context_helpers';
import { MParticlePageName, MParticleEventName, trackPageView, trackEvent } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { adBannerMparticleData } from '@reverbdotcom/commons/src/components/ads/ad_mparticle_tracking_helpers';
import { formatExperimentsForEventAttributes } from '@reverbdotcom/commons/src/elog/mparticle';
import { addOnExitTriggerForEmailPopUp, EmailPopUpModal } from '@reverbdotcom/commons/src/email_pop_up_helpers';
import { isBrandPage, isShopPage, isUsingProximityFeatures, isOutletHub } from '@reverbdotcom/commons/src/url_helpers';
import { BRAND_NEW } from '@reverbdotcom/commons/src/condition_helpers';
import * as elog from '@reverbdotcom/commons/src/elog';
import { AdBanner } from '@reverbdotcom/commons/src/components/ads/ad_banner';
import { trackCriteoProductList } from '@reverbdotcom/commons/src/criteo';
import bind from '@reverbdotcom/commons/src/bind';
import { setItem, getItem } from '@reverbdotcom/commons/src/local_storage_utils';
import { isGreatValue } from '@reverbdotcom/commons/src/listing_great_value';
import { withGraphql } from '@reverbdotcom/commons/src/with_graphql';
import experiments from '@reverbdotcom/commons/src/experiments';
import { connect } from '@reverbdotcom/commons/src/connect';
import { FavoriteButtonContainer } from '../favorites/favorite_button_container';
import { MarketplaceProviders } from './marketplace_providers';
import { EVERYWHERE_CODE } from '@reverbdotcom/commons/src/constants';
import { getAllSelectedOptions, getFiltersForSidebar } from '@reverbdotcom/commons/src/grid_helpers';
import { searchResultsSprigEvent } from '@reverbdotcom/commons/src/elog/sprig_events';
import MarketplaceRelatedSearches from '../discovery/related_searches';
import withQuerySuggestions from '../with_query_suggestions';

interface RouterLocationProps {
  location: Location;
  params: MarketplacePathContext;
}

interface AdWrapperProps {
  ad?: core_apimessages_Ad;
  adLoading?: boolean;
}

interface IExternalProps extends RouterLocationProps, AdWrapperProps {
  pageName: MParticlePageName;
  emptyGrid?: JSX.Element;
  showSold?: boolean;
  loading?: boolean;
  listingsTotal?: number;
  showFlags?: boolean;
  includeItemListMetadata?: boolean;
  displayListingLocationText?: boolean;
}

interface QuerySuggestionsProps {
  querySuggestions?: reverb_personalization_SearchQuery[];
  suggestionsLoading?: boolean;
}

interface PropsForQuery extends IUserContext, RouterLocationProps, AdWrapperProps {
  pageName?: MParticlePageName;
  showSold?: boolean;
}

interface IState {
  listViewEnabled: boolean;
  openModal: boolean;
  exposedSidebarOpen: boolean;
}

type ICombinedProps = IUserContext & WithRouterProps & WithApolloClient<IExternalProps> & QuerySuggestionsProps;

export type MPProps = ChildProps<ICombinedProps, Core_Marketplace_CombinedMarketplaceSearchQuery, Core_Marketplace_CombinedMarketplaceSearchQueryVariables>;
type MPQueryProps = React.PropsWithChildren<ChildProps<PropsForQuery, Core_Marketplace_CombinedMarketplaceSearchQuery, Core_Marketplace_CombinedMarketplaceSearchQueryVariables>>;

const LOW_LISTINGS_THRESHOLD = 10;
export const LIST_VIEW_STATE = 'LIST_VIEW_STATE';
export const LAST_SEARCH_URL = 'last_search_url';

interface PageConfigs { [k: string]: PageSettings }

const generatePageSettings = (params: MarketplacePathContext, showSold: boolean): PageConfigs => ({
  [MParticlePageName.Marketplace]: {
    pageName: MParticlePageName.Marketplace,
    useExperimentalRecall: true,
  },
  [MParticlePageName.Brand]: {
    pageName: MParticlePageName.Brand,
    useExperimentalRecall: false,
    renderCollectionHeaderDescription: true,
    collectionSlug: params.brand_slug,
    collectionType: core_apimessages_CollectionHeader_CollectionType.Brand,
  },
  [MParticlePageName.Shop]: {
    pageName: MParticlePageName.Shop,
    useExperimentalRecall: false,
    showSold,
    pinTextQuery: true,
  },
  [MParticlePageName.Holiday]: {
    pageName: MParticlePageName.Holiday,
    useExperimentalRecall: false,
    hideFollowButtons: true,
    holidaySale: true,
    skipHeader: true,
  },
  [MParticlePageName.Sale]: {
    pageName: MParticlePageName.Sale,
    useExperimentalRecall: false,
    hideFollowButtons: true,
    collectionSlug: params.sale_slug,
    collectionType: core_apimessages_CollectionHeader_CollectionType.CuratedSet,
    skipHeader: true,
  },
  [MParticlePageName.Handpicked]: {
    pageName: MParticlePageName.Handpicked,
    useExperimentalRecall: false,
    renderCollectionHeaderDescription: true,
    collectionSlug: params.curated_set_slug,
    collectionType: core_apimessages_CollectionHeader_CollectionType.CuratedSet,
  },
});

export class MarketplacePage extends React.Component<MPProps, IState> {
  state: IState = {
    listViewEnabled: getItem(LIST_VIEW_STATE),
    openModal: false,
    exposedSidebarOpen: false,
  };

  scrollTarget: ScrollTarget;

  @bind
  setListViewState(enabled: boolean) {
    setItem(LIST_VIEW_STATE, enabled);
    this.setState(
      { listViewEnabled: enabled },
      () => this.data.refetch({
        usingListView: enabled,
        shouldntLoadBumps: false,
        shouldntLoadSuggestions: false,
        useSignalSystem: usingSignalSystemMarketplace(),
        signalGroups: getSignalGroups(this.props.location, enabled),
      }),
    );
  }

  @bind
  setOpenModal(isOpen: boolean) {
    this.setState({ openModal: isOpen });
  }

  componentDidMount() {
    if (this.isMarketplacePage()) {
      this.trackAdExperimentQualifyingEvent();
    }

    this.addEmailPopUpTriggerIfAllowedPage();
  }

  componentDidUpdate(prevProps: MPProps) {
    if (prevProps.location.query !== this.props.location.query) {
      if (this.useExposedSidebar()) { this.scrollTarget.scrollToTop(); }
    }

    if (prevProps.location.query?.page !== this.props.location.query?.page) {
      this.scrollTarget.scrollToTop();
    }

    const isSearchLoaded = !this.data.loading;
    const isSearchUpdated = !isEqual(prevProps.data.listingsSearch, this.data.listingsSearch);

    const shouldTrackPageView = isSearchLoaded && isSearchUpdated;
    if (shouldTrackPageView) {
      this.trackPageView();

      const { user } = this.props;
      if (user && !user.loggedOut && isExperimentEnabled(user, experiments.WEB_CSAT)) {
        searchResultsSprigEvent(this.data.listingsSearch);
      }
    }

    const shouldTrackAdExperimentQualifyingEvent = !this.isAdOrSearchLoading() && isSearchUpdated && this.isMarketplacePage();
    if (shouldTrackAdExperimentQualifyingEvent) {
      this.trackAdExperimentQualifyingEvent();
    }

    this.setLastSearch(prevProps);

    applyAutodirect({ prevProps, props: this.props });

    // Have brand and brand model redirects been fully replaced by auto directs? Can these be removed?
    const shouldStripBrandModelRedirectParam = isSearchLoaded && this.isBrandModelRedirected();
    if (shouldStripBrandModelRedirectParam) {
      this.stripRedirectParam('brand_model_redirected');
    }

    const shouldStripBrandRedirectParam = isSearchLoaded && this.isBrandRedirected();
    if (shouldStripBrandRedirectParam) {
      this.stripRedirectParam('brand_redirected');
    }
  }

  setLastSearch(prevProps: MPProps) {
    if (this.isMarketplacePage()) {
      if (this.getQuery(prevProps) === this.getQuery(this.props)) {
        return;
      }

      setItem(LAST_SEARCH_URL, Paths.marketplace.expand({ query: this.props.location.query.query }));
    } else {
      setItem(LAST_SEARCH_URL, this.props.location.pathname);
    }
  }

  stripRedirectParam(param) {
    const { location } = this.props;
    delete location.query[param];
    browserHistory.push(location);
  }

  isMarketplacePage() {
    return this.props.pageName === MParticlePageName.Marketplace;
  }

  pageSettings(): PageSettings {
    const { params, showSold, pageName } = this.props;
    const pageConfigs = generatePageSettings(params, showSold);

    return pageConfigs[pageName];
  }

  isBrandRedirected() {
    return this.props.location.query.brand_redirected === 'true';
  }

  isBrandModelRedirected() {
    return this.props.location.query.brand_model_redirected === 'true';
  }

  /**
   * `this.data` returns a data object where listingsSearch, aggsSearch, and bumpedSearch results are always
   * available, even if stale. while loading new results, we display old results in the grid and filters with loading
   * ui via css, so this method ensures that previous results are present for that loading state.
   */
  get data(): DataValue<Core_Marketplace_CombinedMarketplaceSearchQuery, Core_Marketplace_CombinedMarketplaceSearchQueryVariables> {
    const safeData = this.props.data as any || {};
    const safePreviousData = safeData.previousData || {};

    return {
      ...safePreviousData,
      ...safeData,
    };
  }

  /**
   * `isAdOrSearchLoading()` was previously named `loading()`, and was renamed for clarity.
   *
   * Ad data and listings search data are always fetched in different queries,
   * and are not guaranteed to resolve their loading states during the same render cycle.
   *
   * `this.data` is resolved from the `Core_Marketplace_CombinedMarketplaceSearch` query and the `MarketplacePage` component.
   * `this.props.adLoading` and `this.props.ad` are resolved from the `Core_Ad_Banner_Wrapper` query and the `MarketplacePageWrapper` component.
   */
  isAdOrSearchLoading() {
    return this.props.adLoading || isEmpty(this.data) || this.data.loading;
  }

  searchResult(): Core_Marketplace_CombinedMarketplaceSearchQuery['listingsSearch'] {
    return this.data?.listingsSearch;
  }

  bumpSearchResult(): Core_Marketplace_CombinedMarketplaceSearchQuery['listingsSearch'] {
    return this.data?.bumpedSearch;
  }

  filters() {
    const oldOrNewFilters = this.data?.aggsSearch?.filters;
    const filters = oldOrNewFilters || [];
    return listingsFilters(filters, this.props.location, this.props.user);
  }

  selectedFilters() {
    const oldOrNewFilters = this.data?.aggsSearch?.filters;
    const filters = oldOrNewFilters || [];
    return selectedFilters(filters);
  }

  getQuery(props: MPProps) {
    return props.data?.aggsSearch?.filters.find(f => f.key === 'TEXT_QUERY')?.options[0]?.optionValue;
  }

  getClearFilterUrl() {
    const { pathname, query } = this.props.location;
    const clearFilterUrl = pathname?.includes('/marketplace') ? Paths.marketplace.expand({ query: query?.query }) : pathname;
    return clearFilterUrl;
  }

  getTotalText() {
    const count = this.props.listingsTotal || 0;

    return I18n.t(
      'discovery.resultsCount.countListings',
      {
        count,
        num: I18n.toNumber(count, { precision: 0 }),
      },
    );
  }

  getListings() {
    return this.searchResult()?.listings || [];
  }

  getBumpedListings() {
    return this.bumpSearchResult()?.listings || [];
  }

  defaultSort() {
    let defaultSort;
    const filters = this.filters();
    const sortFilter = filters.find(({ key }) => key === 'SORT_BY');
    if (sortFilter) {
      defaultSort = sortFilter.options.find(({ selected }) => !!selected)?.trackingValue;
    }
    return defaultSort || reverb_search_ListingsSearchRequest_Sort.NONE;
  }

  getFallbackListings() {
    const fallback = this.searchResult()?.fallbackListings || [];
    const listings = this.getListings();

    if (listings.length === 0) return fallback;

    return differenceBy(fallback, listings, 'id');
  }

  getSuggestedQuery() {
    const queries = this.searchResult()?.suggestedQueries || [];

    return queries?.[0];
  }

  shouldShowFallbackListings() {
    return this.getFallbackListings().length > 0;
  }

  shouldDisplayRelatedSearches() {
    return this.isMarketplacePage() && !!this.props.location?.query?.query;
  }

  renderRelatedSearchRow() {
    if (!this.shouldDisplayRelatedSearches()) {
      return null;
    }

    return (
      <div className="mt-6">
        <MarketplaceRelatedSearches
          querySuggestions={this.props.querySuggestions}
          loading={this.props.suggestionsLoading}
          relatedSearchLocation="top"
          displayPills
        />
      </div>
    );
  }

  renderSecondaryRelatedSearchRow() {
    if (!this.shouldDisplayRelatedSearches() || this.data.error) {
      return null;
    }

    return (
      <MarketplaceRelatedSearches
        querySuggestions={this.props.querySuggestions}
        loading={this.props.suggestionsLoading}
        relatedSearchLocation="bottom"
      />
    );
  }

  renderFollowBanner() {
    if (this.pageSettings().hideFollowButtons) { return null; }
    if (this.isAdOrSearchLoading()) { return null; }
    if (!this.searchResult()) { return null; }
    if (this.shouldShowFallbackListings()) { return null; }

    return (
      <FavoriteButtonContainer
        displayStyle="banner"
      />
    );
  }

  renderAdBanner() {
    const hasData = (!this.props.adLoading && !!this.props.ad?.imageAdCreative);
    if (!hasData) return null;
    // do not show ad banner if Shop Page
    if (this.pageSettings().pageName === MParticlePageName.Shop) return null;
    // do not show on Marketplace Page if user is in MARKETPLACE_GRID_ADS exp
    if (isExperimentEnabled(this.props.user, experiments.MARKETPLACE_GRID_ADS) && this.isMarketplacePage()) return null;

    return (
      <div className="mt-6 mb-4 site-wrapper">
        <AdBanner
          ad={this.props.ad}
        />
      </div>
    );
  }

  shouldShowPromotedListings() {
    if (this.shouldShowFallbackListings()) return null;
    if (this.getListings().length === 0) return null;
    if (this.hasSort()) return null;

    return includePromotedRows(this.props.params, this.props.location?.query);
  }

  didYouMeanSuggestion() {
    return this.searchResult()?.suggestions?.[0];
  }

  useExperimentalRecall(): boolean {
    return this.pageSettings().useExperimentalRecall || false;
  }

  emptyGridExperimentOverride() {
    if (this.useExperimentalRecall() && onlyQuery(this.props.location.query)) {
      return (
        <NoResultsAlert
          query={this.props.location?.query.query}
          suggestion={this.didYouMeanSuggestion()}
        />
      );
    }

    // overrides default empty grid if either experiment is active
    if (this.shouldShowFallbackListings()) return <div />;

    // uses default
    return this.props.emptyGrid;
  }

  hasLowResults() {
    return !this.data?.loading &&
      this.getListings().length <= LOW_LISTINGS_THRESHOLD;
  }

  hasNoResults() {
    return !this.data?.loading &&
      this.getListings().length === 0;
  }

  hasSort() {
    return !!this.props.location?.query?.sort;
  }

  wasReferredByTrendingSearches() {
    return this.props.location.query.referrer === 'trending_search';
  }

  lowResultsAlert() {
    if (this.wasReferredByTrendingSearches()) { return null; }
    if (onlyQuery(this.props.location.query) &&
      this.hasLowResults() &&
      !this.hasNoResults() &&
      this.useExperimentalRecall()) {
      return (
        <LowResultsAlert
          suggestion={this.didYouMeanSuggestion()}
          query={this.props.location?.query.query}
        />
      );
    }

    return null;
  }

  gridAd(): core_apimessages_Ad {
    if (isExperimentEnabled(this.props.user, experiments.MARKETPLACE_GRID_ADS) && this.isMarketplacePage()) {
      return this.props.ad;
    }
    return null;
  }

  useExposedSidebar() {
    return isExperimentEnabled(this.props.user, experiments.SRP_EXPOSED_FILTERS_V2_DESKTOP) && this.isMarketplacePage();
  }

  @bind
  toggleExposedSidebarOpen() {
    this.setState((prevState) => ({ exposedSidebarOpen: !prevState.exposedSidebarOpen }));
  }

  hasSelectedFilterOptions() {
    const filtersForSidebar = getFiltersForSidebar(this.filters());

    return !!getAllSelectedOptions(filtersForSidebar).length;
  }

  @bind
  clearFiltersForMobileSidebar() {
    this.props.router.push(this.getClearFilterUrl());
  }

  renderCards() {
    const trackingSort = this.props.location?.query?.sort || this.defaultSort();
    const results = this.searchResult();
    const trackingPage = results != undefined ? getPage(results.offset, results.limit) : 0;
    const isShowingPromotedListings = this.shouldShowPromotedListings();
    const listingProps = {
      isListView: this.state.listViewEnabled,
      loading: this.isAdOrSearchLoading(),
      listings: this.getListings(),
      trackingQuery: this.trackingQuery(),
      trackingFilter: this.selectedFilters(),
      trackingSort: trackingSort,
      trackingPage: trackingPage,
      pageSettings: this.pageSettings(),
      filters: this.filters(),
      exposedSidebarOpen: this.state.exposedSidebarOpen,
      clearFiltersDisabled: this.hasSelectedFilterOptions(),
      toggleExposedSidebarOpen: this.toggleExposedSidebarOpen,
      listingSearchTotal: this.props.listingsTotal,
      clearFilters: this.clearFiltersForMobileSidebar,
      emptyGrid: this.emptyGridExperimentOverride(),
      showFlags: this.props.showFlags,
      displayListingLocationText: this.props.displayListingLocationText,
    } as ListingProps;

    if (isShowingPromotedListings) {
      return (
        <CombinedBumpListingGrid
          {...listingProps} // eslint-disable-line react/jsx-props-no-spreading
          bumpedListings={this.getBumpedListings()}
          ad={this.gridAd()}
          error={this.data.error}
          includeItemListMetadata={this.props.includeItemListMetadata}
        />
      );
    }

    return (
      <ListingGrid
        {...listingProps} // eslint-disable-line react/jsx-props-no-spreading
        error={!!this.data.error}
        showingSold={this.props.location.query.show_only_sold}
        includeItemListMetadata={this.props.includeItemListMetadata}
      />
    );
  }

  renderFallbackCards() {
    return (
      <MarketplaceFallbackListings
        fallbackListings={this.getFallbackListings()}
        query={this.props.location.query.query}
        hasPrimaryListings={this.getListings().length > 0}
        showingSold={this.props.location.query.show_only_sold}
        displayAsRow={this.useExperimentalRecall()}
      />
    );
  }

  renderSubqueryRows() {
    if (this.wasReferredByTrendingSearches()) { return null; }
    if (!this.useExperimentalRecall() || !this.hasLowResults()) return null;

    const { query } = this.props.location.query;

    return (
      <SubqueryRows
        query={query}
        location={this.props.location}
        params={this.props.params}
        listingsSearchCount={this.getListings().length}
      />
    );
  }

  trackingQuery() {
    return this.isBrandRedirected() ? this.props.location.query.make : this.props.location.query.query;
  }

  freeShippingListingIds() {
    const listings = this.getListings();
    return listings.filter(listing => listing.shipping?.shippingPrices?.[0]?.rate?.amountCents === 0).map(l => l.id);
  }

  greatValueListingIds() {
    const listings = this.getListings();
    return listings.filter(listing => isGreatValue(listing, this.props.user)).map(l => l.id);
  }

  returnPolicyListingIds() {
    const listings = this.getListings();
    return listings.filter(listing => {
      if (listing.soldAsIs) { return; }
      const returnPolicyDays = listing.condition.conditionSlug === BRAND_NEW ? listing.shop?.returnPolicy?.newReturnWindowDays : listing.shop?.returnPolicy?.usedReturnWindowDays;
      return !!returnPolicyDays && returnPolicyDays >= 14;
    }).map(l => l.id);
  }

  get signals() {
    const listings = this.getListings();
    const signals = listings?.map(
      listing => listing?.signals?.map(signal => signal.name),
    );

    const formattedSignals = uniq(flatten(signals)).join('|');

    return formattedSignals;
  }

  sharedTrackingData() {
    const { listViewEnabled } = this.state;
    const { user, location } = this.props;
    const queryParams = location?.query || {};
    const results = this.searchResult();
    const trackedExperiments = pageViewTrackedExperiments({
      user,
    });

    const trackingData = {
      pageName: this.pageSettings().pageName || MParticlePageName.Marketplace,
      experiments: formatExperimentsForEventAttributes(this.props.user, trackedExperiments),
      query: this.trackingQuery(),
      autodirected: autoselectedFilters(this.props).length !== 0,
      brandRedirected: this.isBrandRedirected(),
      brandModelRedirected: this.isBrandModelRedirected(),
      sort: queryParams.sort || this.defaultSort(),
      filter: this.selectedFilters(),
      listingIds: results.listings?.map(l => l.id),
      esScores: results.listings?.map(l => l.esScore),
      querySuggestion: this.getSuggestedQuery(),
      page: getPage(results.offset, results.limit),
      totalPages: getTotalPages(results.total, results.limit),
      listingsCount: results.total,
      fallbackListingsCount: this.getFallbackListings().length,
      productType: queryParams.product_type || '',
      make: queryParams.make || '',
      category: queryParams.category || '',
      shopSlug: this.props.params.shop || '',
      brand: this.props.params.brand_slug || '',
      curatedSetSlug: this.props.params.curated_set_slug || '',
      postalCode: queryParams.postal_code,
      distance: queryParams.distance,
      view: listViewEnabled ? 'List' : 'Grid',
      freeShippingListingIds: this.freeShippingListingIds(),
      returnPolicyListingIds: this.returnPolicyListingIds(),
      refererSlug: queryParams.referer,
      signals: this.signals,
      relatedSearchCount: this.props.querySuggestions?.length || 0,
    };

    if (this.props.user.shippingRegionCode === 'GB' && this.greatValueListingIds().length > 0) {
      merge(trackingData, { greatValueListingIds: this.greatValueListingIds() });
    }

    if (this.shouldShowPromotedListings()) {
      merge(trackingData, { bumpedListingIds: this.getBumpedListings()?.map(l => l.id) });
    }

    return trackingData;
  }

  trackPageView() {
    trackPageView({
      ...this.sharedTrackingData(),
    });

    const results = this.searchResult();

    trackCriteoProductList({
      listingIds: results.listings.map(l => l.id),
      keywords: this.trackingQuery(),
      page_number: getPage(results.offset, results.limit),
    }, this.props.user);
  }

  trackAdExperimentQualifyingEvent() {
    trackEvent({
      ...adBannerMparticleData(this.props.ad),
      ...{
        componentName: MParticlePageName.Marketplace,
        eventName: MParticleEventName.MarketplaceGridAdQE,
        experiments: formatExperimentsForEventAttributes(this.props.user, [experiments.MARKETPLACE_GRID_ADS]),
      },
    });
  }

  @bind
  addEmailPopUpTriggerIfAllowedPage() {
    addOnExitTriggerForEmailPopUp(this.props.user, this.state.openModal, this.setOpenModal, this.props.location);
  }

  renderSellerLocationFilter(): boolean {
    return !isShopPage(this.props.location);
  }

  renderGridLevelFollowButton() {
    if (isShopPage(this.props.location)) {
      return null;
    }

    if (this.pageSettings().hideFollowButtons) {
      return null;
    }

    return (
      <FavoriteButtonContainer
        showCallout
        hideLoggedOutModal
      />
    );
  }

  render() {
    const searchResult = this.searchResult();
    const hideClearFiltersButton = isEmpty(this.props.location?.query);

    if (this.data.error) {
      elog.error(
        'marketplace-render-failure',
        {
          networkErrorStack: this.data.error.networkError?.stack?.substring(0, 500),
        },
        this.data.error,
      );
    }

    return (
      <MarketplaceProviders
        updateUrl={updateMarketplaceUrl}
        keySuffix={String(this.state.listViewEnabled)}
      >
        {!this.pageSettings().skipHeader &&
          <HeaderWithMetadata
            params={this.props.params}
            query={get(this.props, 'location.query', {})}
            pageSettings={this.pageSettings()}
            renderCollectionHeaderDescription={this.pageSettings().renderCollectionHeaderDescription}
            isBrandPage={isBrandPage(this.props.location)}
          />
        }
        <ScrollTarget onlyScrollUp ref={r => this.scrollTarget = r} />
        <RQLSearchGrid
          error={!!this.data.error}
          loading={this.isAdOrSearchLoading()}
          search={searchResult}
          filters={this.filters()}
          location={this.props.location}
          aboveGridBanner={this.renderAdBanner()}
          relatedSearchRow={this.renderRelatedSearchRow()}
          secondaryRelatedSearchRow={this.renderSecondaryRelatedSearchRow()}
          totalText={this.getTotalText()}
          followButton={this.renderGridLevelFollowButton()}
          cards={this.renderCards()}
          fallbackCards={this.renderFallbackCards()}
          subqueryRows={this.renderSubqueryRows()}
          hasPagination
          hasSearchOverview
          followBanner={this.renderFollowBanner()}
          trackingQuery={this.trackingQuery()}
          lowResultsAlert={this.lowResultsAlert()}
          setListViewState={this.setListViewState}
          showListViewToggle
          useH1Title={this.isMarketplacePage()}
          resultsTotal={this.props.listingsTotal}
          query={this.props.location?.query?.query}
          pinTextQuery={!!this.pageSettings().pinTextQuery}
          toggleExposedSidebarOpen={this.toggleExposedSidebarOpen}
          useExposedSidebar={this.useExposedSidebar()}
          showNewMobileSidebar
          hideClearFiltersButton={hideClearFiltersButton}
          clearFiltersUrl={this.getClearFilterUrl()}
          tipText={<BumpDSACompliancePopover />}
        />
        <CurationModal />
        <EmailPopUpModal
          openModal={this.state.openModal}
          setOpenModal={this.setOpenModal}
          location={this.props.location}
        />
      </MarketplaceProviders>
    );
  }
}

function getSignalGroups(location, usingListView) {
  const useProximitySignals = isUsingProximityFeatures(location);
  const signalGroups = [];
  if (usingListView) {
    signalGroups.push(useProximitySignals ? reverb_signals_Signal_Group.MP_LIST_PROX_CARD : reverb_signals_Signal_Group.MP_LIST_CARD);
  } else {
    signalGroups.push(useProximitySignals ? reverb_signals_Signal_Group.MP_GRID_PROX_CARD : reverb_signals_Signal_Group.MP_GRID_CARD);
  }

  return signalGroups;
}

export function mapVarsForMarketplaceQuery(
  props: MPQueryProps,
  bumpLimitOverride?: number,
  listingsLimitOverride?: number,
  queryOverride?: string,
  boostByBumpRateOverride?: boolean,
): Core_Marketplace_CombinedMarketplaceSearchQueryVariables {
  const { params, user, ad, location, showSold, pageName } = props;

  const pageSettings = generatePageSettings(params, showSold)[pageName] || {};
  const pathParams = params;
  const queryParams = location?.query || {};
  const usingListView = getItem(LIST_VIEW_STATE) || false;
  const canShowAds = isExperimentEnabled(user, experiments.MARKETPLACE_GRID_ADS) && pageName === MParticlePageName.Marketplace;
  const adTilesCount = !!ad && canShowAds ? 1 : 0;

  const mapped = mapParams({
    pathParams,
    location,
    user,
    pageSettings: {
      holidaySale: pageSettings.holidaySale,
      showSold: pageSettings.showSold,
      useExperimentalRecall: pageSettings.useExperimentalRecall,
      pageName,
    },
    adTilesCount,
    listingsLimitOverride,
    queryOverride,
    boostByBumpRateOverride,
    isOutlet: isOutletHub(location),
  });

  const shouldntLoadBumps = [
    pathParams.shop,
    queryParams.show_only_sold,
    queryParams.showsold,
    pageSettings.showSold,
    pageSettings.holidaySale,
    queryOverride,
  ].some(Boolean);

  return {
    // Variables for inputs
    ...getCombinedMarketplaceInputs(
      mapped,
      user,
      location.query,
      bumpLimitOverride,
    ),
    // Variables for directives (https://graphql.org/learn/queries/#directives)
    shouldntLoadBumps,
    shouldntLoadSuggestions: false,
    usingListView,
    signalGroups: getSignalGroups(location, usingListView),
    useSignalSystem: usingSignalSystemMarketplace(),
  };
}

const withSsrListingsCountQuery = withGraphql<
  ICombinedProps,
  CoreMarketplaceMarketplaceListingsCount.Query,
  CoreMarketplaceMarketplaceListingsCount.Variables,
  MPProps
>(listingsCountQuery, {
  options: (props) => {
    const { inputListings } = mapVarsForMarketplaceQuery(props);
    return {
      context: {
        headers: {
          ...(props.params.shop && { 'X-Item-Region': EVERYWHERE_CODE }),
        },
      },
      ssr: true,
      variables: {
        inputListings: {
          ...inputListings,
          // Setting the limit to 0 uniquely identifies this listing count query's arguments and its response in Apollo's cache,
          // as distinct from the `listingsSearch(input: $inputListings)` field of the `CoreMarketplaceCombinedMarketplaceSearch` query.
          // This avoids a collision in Apollo's cache, which prevents the `CoreMarketplaceCombinedMarketplaceSearch` query and
          // `CoreMarketplaceMarketplaceListingsCount` from each being executed twice.
          limit: 0,
        },
      },
    };
  },
  props: ({ data, ownProps }) => {
    const safeData = data as any || {};
    const safePreviousData = safeData.previousData || {};

    const dataToUse = {
      ...safePreviousData,
      ...safeData,
    };

    const { listingsSearch } = dataToUse;
    const listingsTotal = listingsSearch?.total;
    const props = {
      ...ownProps,
      listingsTotal,
    };
    return props;
  },
});

const withMarketplaceSearchQuery = withGraphql<
  ICombinedProps,
  Core_Marketplace_CombinedMarketplaceSearchQuery,
  Core_Marketplace_CombinedMarketplaceSearchQueryVariables
>(combinedMarketplaceSearchQuery, {
  options: (props) => {
    return {
      context: {
        headers: {
          ...(props.params.shop && { 'X-Item-Region': EVERYWHERE_CODE }),
        },
      },
      ssr: false,
      variables: mapVarsForMarketplaceQuery(props),
    };
  },
});

export default connect<IExternalProps>([
  withUserContext,
  withApollo,
  withRouter,
  withSsrListingsCountQuery,
  withMarketplaceSearchQuery,
  withQuerySuggestions,
])(MarketplacePage);
