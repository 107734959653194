// TODO update this to import from commons/src/gql
// eslint-disable-next-line no-restricted-imports
import { gql } from '@apollo/client';
import I18n from 'i18n-js';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { ITEM_WITH_SLUG_ROUTE, listingItemPagePath, isOutletHub } from '@reverbdotcom/commons/src/url_helpers';

import { RCListingCard } from '@reverbdotcom/cadence/components';
import { EyebrowTextProps } from '@reverbdotcom/cadence/components/RCText/RCText';

import { WEB } from '../constants';
import { formatExperimentsForEventAttributes } from '../elog/mparticle';
import { buildListingClickEvent } from '../elog/mparticle_tracker';
import { WatchParentComponentName } from '../event_tracking/watchlist';
import experiments from '../experiments';
import CreateOfferButton, { ListingCreateOfferButtonFragment } from '../offers/create_offer_button';
import {
  BumpKeyFragment,
  FinancingFieldsFragment,
  InOtherCartsCardDataFragment,
  ListingCardFieldsFragment,
} from '../gql/graphql';
import { useUser } from '../user_hooks';
import AddToCartButton from './add_to_cart_button';
import CoreLink from './core_link';
import ImageCarousel from './image_carousel';
import { DisplayStyle, WatchBadge, WatchBadgeFragment } from './watch_badge';
import { ListingCardMetadata } from './listing_card_metadata';
import { ListingCardReviews } from './reviews_metadata';

import {
  buildListingForBuyer,
  buildPrice,
  getEyebrow,
  getRCListingCardNudges,
  isListingDomesticToBuyer,
  ListingForBuyerFragment,
  showProximityContent,
} from '../listing_helpers';
import ListingCardTag from './listing_card_tag';
import { ListingTitleHtmlTags } from '@reverbdotcom/cadence/components/types';
const DISPLAY_STYLE = 'squareCard';

type Listing = ListingCardFieldsFragment & FinancingFieldsFragment & InOtherCartsCardDataFragment & BumpKeyFragment;

interface IExternalProps {
  // This type is likely _more_ than what this component needs.
  // Cutting a fragment for this component would help better communicate
  // the contract here.
  listing?: Listing;
  position?: number;
  soldListing?: boolean;
  footerContent?: React.ReactNode;
  hideMetadataAsOffer?: boolean;
  trackingName?: string;
  displayAddToCart?: boolean;
  displayMakeAnOffer?: boolean;
  listingsCount?: number;
  combinedShippingDiscount?: boolean;
  trackingQuery?: string;
  trackingFilter?: string;
  trackingSort?: string;
  trackingPage?: number;
  showFlag?: boolean;
  /** Please use a WatchBadgeParentComponent value when possible instead of a plain string! */
  watchBadgeParentComponentName?: WatchParentComponentName | string;
  displayGreatValue?: boolean;
  handleCreateWatchResult?: () => void;
  minimalNudges?: boolean;
  showShippingDisplay?: boolean;
  hideActionsOnMobile?: boolean;
  reviews?: ListingCardReviews;
  includeItemListMetadata?: boolean;
  titleHtmlTag?: keyof typeof ListingTitleHtmlTags;
  cmsComponentId?: string;
  contextHeader?: React.ReactNode;
  eyebrow?: {
    text: string;
    color?: EyebrowTextProps['color'];
  };
  displayListingLocationText?: boolean;
}

type Props = IExternalProps & WithRouterProps;

export function ListingCard({
  listing = undefined,
  location,
  position = undefined,
  soldListing = false,
  footerContent = undefined,
  hideMetadataAsOffer = false,
  trackingName = undefined,
  displayAddToCart = false,
  displayMakeAnOffer = false,
  listingsCount = undefined,
  combinedShippingDiscount = false,
  trackingQuery = undefined,
  trackingFilter = undefined,
  trackingSort = undefined,
  trackingPage = undefined,
  showFlag = false,
  watchBadgeParentComponentName = undefined,
  displayGreatValue = true,
  handleCreateWatchResult = undefined,
  minimalNudges = false,
  showShippingDisplay = false,
  hideActionsOnMobile = false,
  reviews = undefined,
  includeItemListMetadata = false,
  titleHtmlTag = 'div',
  cmsComponentId = undefined,
  eyebrow = undefined,
  displayListingLocationText = false,
}: Props) {
  const user = useUser();
  if (!listing) {
    return <RCListingCard.Skeleton />;
  }
  const decoratedListing = buildListingForBuyer(
    listing,
    { combinedDiscountActive: combinedShippingDiscount },
  );

  function listingUrl() {
    if (soldListing) {
      return (
        ITEM_WITH_SLUG_ROUTE.expand({
          slug: listing.slug,
          id: listing.id,
          show_sold: true,
        })
      );
    }

    return (
      listingItemPagePath(listing)
    );
  }

  function renderMetadata() {
    return (
      <ListingCardMetadata
        listing={listing}
        reviews={reviews}
        hideMetadataAsOffer={hideMetadataAsOffer}
        includeItemListMetadata={includeItemListMetadata}
      />
    );
  }

  function clickEvent() {
    const sellerCountryCode = listing.shop.address?.country?.countryCode;

    const trackedExperiments = [
      experiments.LISTING_CARD_LOCATION,
      experiments.DUMMY,
    ];

    return {
      experiments: formatExperimentsForEventAttributes(
        user,
        trackedExperiments,
      ),
      query: trackingQuery,
      filter: trackingFilter,
      sort: trackingSort,
      page: trackingPage,
      ...buildListingClickEvent({
        listing: listing,
        componentName: trackingName,
        cmsComponentId,
        displayStyle: DISPLAY_STYLE,
        position,
        listingsCount,
        city: listing.shop.address?.locality,
        region: listing.shop.address?.region,
        countryCode: sellerCountryCode,
        isListingDomesticToBuyer: isListingDomesticToBuyer(sellerCountryCode, user?.countryCode),
      }),
    };
  }

  function shopLocation() {
    const shopAddress = listing.shop?.address;
    if (!shopAddress) return null;

    const shopAndUserInUs = shopAddress.country?.countryCode === 'US' && user.countryCode === 'US';
    const userInUsShopNotUs = user.countryCode === 'US' && shopAddress.country?.countryCode !== 'US';
    if (showProximityContent(shopAddress, user, location) || displayListingLocationText) {
      if (shopAndUserInUs && shopAddress?.locality && shopAddress.region) {
        return I18n.t('commons.listingCard.location.sameCountryUs', {
          city: shopAddress.locality, state: shopAddress.region,
        });
      }

      if (displayListingLocationText && userInUsShopNotUs && shopAddress?.locality && shopAddress.country) {
        return I18n.t('commons.listingCard.location.otherCountry', {
          city: shopAddress.locality, country: shopAddress.country.name,
        });
      }
    }

    return null;
  }

  const openNewTab = user && user.deviceName === WEB;
  const images = listing.images.map(i => (i.source));
  const nudgeConfig = {
    listing,
    decoratedListing,
    shop: listing.shop,
    displayGreatValue,
    user,
    showFlag,
    location,
    minimalNudges,
  };

  return (
    <div
      itemProp={includeItemListMetadata ? 'itemListElement' : null}
      itemScope={includeItemListMetadata}
      itemType={includeItemListMetadata ? 'http://schema.org/Product' : null}
      className="height-100 width-100"
    >
      <RCListingCard
        linkElement={(children) => (
          <CoreLink
            to={listingUrl()}
            target={openNewTab ? '_blank' : undefined}
            clickEvent={clickEvent()}
          >
            {children}
          </CoreLink>
        )}
        metaElement={renderMetadata()}
        thumbnailElement={(<ImageCarousel images={images} />)}
        title={listing.title}
        titleHtmlTag={titleHtmlTag}
        conditionLabel={decoratedListing.conditionLabel}
        shopLocation={shopLocation()}
        price={buildPrice(decoratedListing, combinedShippingDiscount, showShippingDisplay)}
        nudges={getRCListingCardNudges(nudgeConfig)}
        fullHeight
        eyebrow={eyebrow || getEyebrow(listing)}
        favoriteButtonElement={(
          <WatchBadge
            listing={listing}
            displayStyle={DisplayStyle.RC_FAVORITE_BUTTON}
            parentComponentName={watchBadgeParentComponentName || trackingName}
            handleCreateWatchResult={handleCreateWatchResult}
            useToast
          />
        )}
        violator={!isOutletHub(location) && <ListingCardTag listing={listing} />}
        hideActionsOnMobile={hideActionsOnMobile}
        primaryAction={displayAddToCart &&
          <AddToCartButton
            listing={listing}
            position={position}
            parentComponentName={trackingName}
          />
        }
        secondaryAction={displayMakeAnOffer &&
          <CreateOfferButton
            listing={listing}
            parentComponentName={trackingName}
          />
        }
        adminControls={footerContent}
      />
    </div>
  );
}

export const ListingCardFragment = gql`
  fragment ListingCardFields on Listing {
    _id
    ...ListingForBuyerFields
    ...WatchBadgeData
    ...ListingCreateOfferButtonData
  }
  ${ListingForBuyerFragment}
  ${ListingCreateOfferButtonFragment}
  ${WatchBadgeFragment}
`;

export default withRouter(ListingCard);
