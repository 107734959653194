import React from 'react';
import I18n from 'i18n-js';
import track from 'react-tracking';
import ListingCard from '@reverbdotcom/commons/src/components/listing_card';
import { LISTING_TYPE, MParticlePageName } from '@reverbdotcom/commons/src/elog/mparticle_tracker';
import { Listing } from '@reverbdotcom/commons/src/gql/graphql';
import ErrorDisplay from './discovery/error_display';
import { PageSettings } from './marketplace/map_params';
import NotFoundAnimation from './not_found_animation';
import TileCurationView from './discovery/curation/tile_curation_view';
import ListViewRowCard from './list_view_row_card';
import { RCListingGrid } from '@reverbdotcom/cadence/components/RCListingGrid/RCListingGrid';
import { times } from 'lodash';
import { PlaceholderListingRowCard } from '@reverbdotcom/commons/src/components/listing_row_card';
import { RCButton, RCListingCard, RCSiteWrapper, useMediaQuery } from '@reverbdotcom/cadence/components';
import { Filter } from '@reverbdotcom/commons/src/components/search_filters';
import { useExpEnabled, useUser } from '@reverbdotcom/commons/src/user_hooks';
import { I18N } from '@reverbdotcom/commons/src/components/translate';
import RQLFacets from '@reverbdotcom/commons/src/components/rql_facets';
import experiments from '@reverbdotcom/commons/src/experiments';
import { ListingCardReviews } from '@reverbdotcom/commons/src/components/reviews_metadata';

interface IExternalProps {
  loading: boolean;
  listings: Listing[];
  trackingName?: string;
  emptyGrid?: JSX.Element;
  trackingQuery?: string;
  trackingFilter?: string;
  trackingSort?: string;
  trackingPage?: number;
  pageSettings?: PageSettings;
  isListView?: boolean;
  exposedSidebarOpen?: boolean;
  filters?: Filter[];
  clearFiltersDisabled?: boolean;
  toggleExposedSidebarOpen?: () => void;
  listingSearchTotal?: number;
  clearFilters?: () => void;
  itemsToDisplay?: number,
  gridItems?: JSX.Element;
  showFlags?: boolean;
  includeItemListMetadata?: boolean;
  displayListingLocationText?: boolean;
}

interface IListingCard {
  listing: Listing,
  idx: number,
  isAdmin: boolean,
  isListView: boolean,
  loading: boolean,
  trackingName?: string,
  trackingQuery?: string,
  trackingFilter?: string,
  trackingSort?: string,
  trackingPage?: number,
  pageSettings?: PageSettings,
  showingSold?: boolean,
  showFlag?: boolean;
  includeItemListMetadata?: boolean;
  displayListingLocationText?: boolean;
}

export type ListingProps = IExternalProps;

type ListingGridProps = ListingProps & { error?: boolean; showingSold?: boolean; };

export const BRAND_NEW_CONDITION_UUID = '7c3f45de-2ae0-4c81-8400-fdb6b1d74890';
const COMPONENT_NAME = 'GridView';
const ITEMS_TO_DISPLAY = 14;

function GridEmptyState() {
  return (
    <div className="not-found">
      <h3 className="not-found__message">
        {I18n.t('discovery.noResults.nothingHere')}
      </h3>
      <NotFoundAnimation />
    </div>
  );
}

export function GridListingCard({
  listing,
  idx,
  isAdmin,
  isListView,
  loading,
  showingSold = false,
  trackingName = '',
  trackingQuery = '',
  trackingFilter = '',
  trackingSort = '',
  trackingPage = 0,
  pageSettings = null,
  showFlag = false,
  includeItemListMetadata = false,
  displayListingLocationText = false,
}: IListingCard) {
  const reviews = listing?.csp && {
    totalReviews: listing?.csp?.reviewsCount,
    averageRating: listing?.csp?.averageReviewRating,
  } as ListingCardReviews;

  if (isListView) {
    return (
      <ListViewRowCard
        key={listing.id}
        listing={listing}
        loading={loading}
        position={idx}
        trackingQuery={trackingQuery}
        showingSold={showingSold}
        showFlag={showFlag}
        includeItemListMetadata={includeItemListMetadata}
        titleHtmlTag={'h2'}
        reviews={reviews}
      />
    );
  }

  return (
    <ListingCard
      key={listing.id}
      listing={listing}
      position={idx}
      soldListing={showingSold}
      trackingName={trackingName}
      trackingQuery={trackingQuery}
      trackingFilter={trackingFilter}
      trackingSort={trackingSort}
      trackingPage={trackingPage}
      footerContent={isAdmin && (
        <TileCurationView
          listingId={listing.id}
          pageSettings={pageSettings}
          bumped={listing.bumped}
        />
      )}
      showFlag={showFlag}
      displayListingLocationText={displayListingLocationText}
      includeItemListMetadata={includeItemListMetadata}
      titleHtmlTag={'h2'}
      reviews={reviews}
    />
  );
}

export function ListingGrid({
  loading,
  listings,
  showingSold = false,
  error = false,
  isListView = false,
  filters = null,
  exposedSidebarOpen = false,
  toggleExposedSidebarOpen = undefined,
  listingSearchTotal = 0,
  clearFiltersDisabled = false,
  clearFilters = undefined,
  itemsToDisplay = ITEMS_TO_DISPLAY,
  emptyGrid = null,
  trackingName = '',
  trackingQuery = '',
  trackingFilter = '',
  trackingSort = '',
  trackingPage = 0,
  pageSettings = null,
  gridItems = null,
  showFlags = false,
  includeItemListMetadata = false,
  displayListingLocationText = false,
}: ListingGridProps) {
  const inExposedFiltersExp = useExpEnabled(experiments.SRP_EXPOSED_FILTERS_V2_DESKTOP);

  const user = useUser();
  const isMobile = useMediaQuery('mobile');
  const listingGridItems = listings?.map((listing, idx) => {
    return (
      <RCListingGrid.Item key={listing.id} colSpan={isListView ? 'all' : 'one'}>
        <GridListingCard
          listing={listing}
          idx={idx}
          isAdmin={user?.isAdmin}
          isListView={isListView}
          loading={loading}
          showingSold={showingSold}
          trackingName={trackingName}
          trackingQuery={trackingQuery}
          trackingFilter={trackingFilter}
          trackingSort={trackingSort}
          trackingPage={trackingPage}
          pageSettings={pageSettings}
          showFlag={showFlags}
          displayListingLocationText={displayListingLocationText}
          includeItemListMetadata={includeItemListMetadata}
        />
      </RCListingGrid.Item>
    );
  });

  if (error) {
    return <ErrorDisplay />;
  }

  function renderGridItems() {
    const noListings = !listings || listings?.length === 0;
    const isLoadingWithNoListings = loading && noListings;

    if (isLoadingWithNoListings) {
      const itemsToDisplayInGrid = itemsToDisplay ?? ITEMS_TO_DISPLAY;
      return (
        <>
          {times(itemsToDisplayInGrid, index => (
            <RCListingGrid.Item key={index} colSpan={isListView ? 'all' : 'one'}>
              {isListView
                ? <PlaceholderListingRowCard />
                : <RCListingCard.Skeleton />
              }
            </RCListingGrid.Item>
          ))}
        </>
      );
    }

    if (noListings) {
      return <RCListingGrid.Item colSpan="all">
        {emptyGrid || <GridEmptyState />}
      </RCListingGrid.Item>;
    }

    return gridItems ?? listingGridItems;
  }

  // This exposes the filters sidebar next to the listings grid on the mweb Marketplace only
  // Desktop / tablet marketplace, and other non-marketplace grids are currently using MarketplaceFilterSidebar
  function shouldRenderSidebar() {
    if (inExposedFiltersExp && pageSettings?.pageName === MParticlePageName.Marketplace && listings.length !== 0) return true;
    if (isMobile) return true;
    return false;
  }

  const sidebarButtonText = !listingSearchTotal ?
    I18n.t('commons.marketplaceFilters.viewResults') :
    I18n.t('commons.marketplaceFilters.showResultsCount',
      { count: I18n.toNumber(listingSearchTotal, { precision: 0 }) },
    );

  const sidebar = !shouldRenderSidebar() ? null : (
    <RCSiteWrapper.Sidebar
      title={I18n.t('commons.marketplaceFilters.filterAndSort')}
      isOpen={exposedSidebarOpen}
      onOpenChange={toggleExposedSidebarOpen}
      actions={{
        primary: {
          buttonText: sidebarButtonText,
          fullWidth: true,
        },
      }}
      sticky
    >
      {clearFiltersDisabled &&
        <div className="bdb-1 bd-color-primary pb-4">
          <RCButton
            size="small"
            fullWidth
            onClick={clearFilters}
          >
            <I18N text="commons.marketplaceFilters.clearFilters" />
          </RCButton>
        </div>
      }
      <RQLFacets
        filters={filters}
        loading={loading}
        trackingQuery={trackingQuery}
        inRCSiteWrapper
      />
    </RCSiteWrapper.Sidebar>
  );

  return (
    <RCSiteWrapper
      insideWrapper
      loading={loading}
      sidebar={sidebar}
    >
      <div
        itemScope={includeItemListMetadata}
        itemType={includeItemListMetadata ? 'http://schema.org/ItemList' : null}
      >
        <RCListingGrid
          withSidebar={inExposedFiltersExp}
        >
          {renderGridItems()}
        </RCListingGrid>
      </div>
    </RCSiteWrapper>
  );
}

export default track({
  componentName: COMPONENT_NAME,
  type: LISTING_TYPE,
})(ListingGrid);
