import React, { useState } from 'react';
import { parseAmountCents } from '@reverbdotcom/commons/src/money';
import {
  core_apimessages_Tax1099kReportingThresholdSearchResult,
  Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest,
} from '@reverbdotcom/commons/src/gql/graphql';
import { RCButton, RCTextWithIcon } from '@reverbdotcom/cadence/components';
import { PencilIcon, TrashOIcon } from '@reverbdotcom/cadence/icons/react';
import Tax1099kReportingThresholdEditRow from './tax_1099k_reporting_threshold_edit_row';

interface RowProps {
  deleteThreshold: (thresholdId: string) => void;
  threshold: core_apimessages_Tax1099kReportingThresholdSearchResult;
  updateThreshold: (threshold: Input_core_apimessages_AdminTax1099kReportingThresholdUpdateRequest) => void;
}

export default function Tax1099kReportingThresholdTableRow({
  deleteThreshold,
  threshold,
  updateThreshold,
}: RowProps) {
  const [ isEditing, setIsEditing ] = useState(false);

  const deleteRow = () => {
    deleteThreshold(threshold.id);
  };

  return isEditing ? (
    <Tax1099kReportingThresholdEditRow
      threshold={threshold}
      cancelEdit={() => setIsEditing(false)}
      saveThreshold={updateThreshold}
    />
  ) : (
    <tr>
      <td>
        {threshold.year}
      </td>
      <td>
        {threshold.region || 'Federal'}
      </td>
      <td className="align-right">
        {parseAmountCents(parseInt(threshold.salesAmountCents, 10), 'USD').display}
      </td>
      <td className="align-right">
        {threshold.transactionCount}
      </td>
      <td className="align-right">
        <div className="d-flex fx-justify-end">
          <div className="mr-2">
            <RCButton
              id={`edit-${threshold.id}`}
              size="mini"
              onClick={() => setIsEditing(true)}
              variant="outlined"
            >
              <RCTextWithIcon
                svgComponent={PencilIcon}
                children="Edit"
                title="Edit"
                placement="left"
              />
            </RCButton>
          </div>
          <RCButton
            id={`delete-${threshold.id}`}
            size="mini"
            onClick={deleteRow}
          >
            <RCTextWithIcon
              svgComponent={TrashOIcon}
              children="Delete"
              title="Delete"
              placement="left"
            />
          </RCButton>
        </div>
      </td>
    </tr>
  );
}
